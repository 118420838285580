import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramManagementService } from '../../services/program-management.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../../services/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-copy-program-modal',
  templateUrl: './copy-program-modal.component.html',
  styleUrls: ['./copy-program-modal.component.scss']
})
export class CopyProgramModalComponent implements OnInit {
  /** Form group for programForm */
  programForm: UntypedFormGroup;
  /** Store original program name */
  originalProgramName: string;
  /** list of all clients */
  allClients: AdminClient[] = [];
  /** stores value of the client Id selected in dropdown */
  selectedClientId: string;
  /** List of clients */
  clientList: AdminClient[] = [];

  /**
   * @param toastrService Totastr service
   * @param programService Program service
   * @param fb Form builder
   * @param spinner spinner
   * @param dialogRef dialogRef
   * @param clientService client service
   * @param data data
   */
  constructor(
    public toastrService: ToastrService,
    public readonly programService: ProgramManagementService,
    public fb: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CopyProgramModalComponent>,
    public readonly clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.originalProgramName = data.programName;
    this.generateProgramForm(data);
  }

  ngOnInit() {
    this.clientService.getClients({}).subscribe((response: { clients: AdminClient[] }) => {
      this.allClients = (response.clients || []);
      this.clientList = this.allClients;
    });

    this.programForm.get('clientNewProgram').valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      const clientName = value.split('(')[0];
      if (clientName.length >= 3) {
        this.getListOfClients(clientName);
      } else {
        this.clientList = this.allClients;
      }
    });
  }

  /**
   * To generate program form
   * @param originalProgramDetails details of program to be copied
   */
  generateProgramForm(originalProgramDetails) {
    const clientOriginalProgram = originalProgramDetails.clientName + '(' + originalProgramDetails.clientNumber + ')';
    this.programForm = this.fb.group({
      programName: new UntypedFormControl(originalProgramDetails.programName),
      newProgramName: new UntypedFormControl(originalProgramDetails.programName + ' Copy 2', [Validators.required, Validators.maxLength(49)]),
      clientNewProgram: new UntypedFormControl(clientOriginalProgram, [Validators.required, Validators.minLength(3)])
    });
  }

  /**
   * To validate selected client in dropdown
   */
  validateSelectedClient() {
    const selectedValue = this.clientList.filter(
      (alias) => alias.clientEntityName === this.programForm.get('clientNewProgram').value.split('(')[0]);
    if (!selectedValue || selectedValue.length === 0) {
      this.programForm.get('clientNewProgram').setValue('');
    }
  }

  /**
   * To submit copied program details
   */

  submitProgramForm() {
    const programFormValue = this.programForm.value;
    const filteredClientList = (this.clientList || []).filter(val => val.clientEntityName === (programFormValue?.clientNewProgram || '').split('(')[0]);
    let clientId: string;

    if (filteredClientList && filteredClientList[0]) {
      clientId = filteredClientList[0].clientId;
    }

    this.programService.checkDuplicatePrograms(clientId, programFormValue.newProgramName).subscribe({
      next: data => {
        if (data && !this.data.program) {
          if (data.programs && Array.isArray(data.programs) && data.programs.length === 0 &&
            programFormValue.newProgramName !== programFormValue.programName) {
            this.dialogRef.close({
              programName: programFormValue.newProgramName,
              clientId: clientId
            });
          } else {
            this.toastrService.error('Program name already exists', 'Error');
          }
        } else if (this.data.program && this.data.program.isNew) {
          this.dialogRef.close({
            programName: programFormValue.newProgramName,
            clientId: programFormValue.clientNewProgram
          });
        } else if (this.data.program && !this.data.program.isNew) {
          if (data.programs.length > 0 && (this.data.program.programName !== programFormValue.programName)) {
            this.toastrService.error('Program name already exists', 'Error');
          }
        }
      },
      error: () => {
        this.toastrService.error('Error checking duplicate program. Please try again');
      }
    });
  }

  /**
   * To dismiss/cancel the copy program popup
   */
  onDismiss() {
    this.dialogRef.close();
  }

  /**
   * To get list of clients by autocomplete text value
   * @param selectedValue auto complete text content
   */
  getListOfClients(selectedValue: string) {
    const options = selectedValue ? { searchText: selectedValue } : null;
    this.clientService.getClients(options).subscribe({
      next: res => {
        if (res) {
          this.clientList = (res.clients || this.allClients || []);

          if (!selectedValue && this.allClients?.length === 0) {
            this.allClients = res.clients;
          }
          this.spinner.hide();
        } else {
          this.clientList = this.allClients || [];
          this.spinner.hide();
        }
        return this.clientList;
      },
      error: err => {
        this.clientList = this.allClients || [];
        this.spinner.hide();
      }
    });
  }
}
