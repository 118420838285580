
<mat-card class="mat-card-candidate-contact" fxLayout="row" fxLayout fxLayoutAlign="start center"
  fxLayoutAlign.xs="start start">
  <button class="close-btn" mat-icon-button aria-label="close" (click)="closeTransferee()">
    <mat-icon aria-label="close">close</mat-icon>
  </button>
  <div class="candidate-short-name">
    <app-avatar [firstName]="transferee?.nameDetails.firstName" [lastName]="transferee?.nameDetails.lastName">
    </app-avatar>
  </div>
  <div class="candidate-contact-detail" *ngIf="transfereeDetails">
    <div class="candidate-user-name">{{transferee?.nameDetails.firstName}} {{transferee?.nameDetails.lastName}}</div>
    <!-- <div><span>Company:&nbsp;</span>candidateCompany</div> -->
    <div class="contact-detail" fxLayout fxLayout.xs="row" fxLayout.sm="column" fxLayout.md="column" fxHide.xs>
      <div fxLayout fxFlex="30" fxFlex.sm="100" fxFlex.xs="100">
        <img alt="phone-icon" (click)="toggle()" src="../../../../../../assets/images/baseline-phone-24px.svg" />
        <div class="xs-none">
          <app-sensitive-info-mask *ngIf="transferee?.phoneDetailsList[0]?.phoneNumber" type="phone"
            [value]="transferee?.phoneDetailsList[0]?.phoneNumber"
            [dialingCode]="transferee?.phoneDetailsList[0]?.phoneDialCode"></app-sensitive-info-mask>
        </div>
        <button fxHide.lt-md class="more-btn xs-none" (click)="toggle()" mat-icon-button
          aria-label="More details about phone number">
          <mat-icon aria-label="more verticle">more_vert</mat-icon>
        </button>
        <div class="phone-numbers" *ngIf="show">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <img src="../../../../../../assets/images/baseline-phone-24px.svg" alt="Telephone Receiver" />
              <span>Phone Number</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggle()">
              <mat-icon aria-label="close">close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs">
            <div fxLayout="row" fxLayoutGap="15px" *ngFor="let phone of transferee?.phoneDetailsList">
              <mat-form-field fxFlex="60">
                <span matPrefix> +{{phone.phoneDialCode}} &nbsp;</span>
                <input readonly type="tel" matInput [value]="phone.phoneNumber">
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input readonly matInput [value]="phone?.usageType.charAt(0).toUpperCase() + phone.usageType.slice(1)">
              </mat-form-field>

            </div>
          </div>
        </div>

      </div>
      <div fxLayout fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="(transferee?.emailDetailsList.length!=0)">
        <img alt="E-mail Symbol" (click)="toggleEmail(transferee)" src="../../../../../../assets/images/baseline-email-24px.svg" />
        <div class="xs-none">
          <app-sensitive-info-mask type="email" [value]="transferee?.emailDetailsList[0].emailAddress">
          </app-sensitive-info-mask>
        </div>
        <button (click)="toggleEmail(transferee)" class="more-btn xs-none" mat-icon-button aria-label="More details about email">
          <mat-icon>more_vert</mat-icon>
        </button>
        <div class="phone-numbers email-address" *ngIf="showEmail">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <img src="../../../../../../assets/images/baseline-email-24px.svg" alt="E-mail Symbol" />
              <span>Email Address</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggleEmail(transferee)">
              <mat-icon aria-label="close">close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs">
            <div fxLayout="row" fxLayoutGap="15px" *ngFor="let email of transferee?.emailDetailsList">
              <mat-form-field fxFlex="60">
                <input readonly="true" type="text" matInput [value]="email.emailAddress">
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input readonly="true" matInput
                  [value]="email?.usageType.charAt(0).toUpperCase() + email.usageType.slice(1)">
              </mat-form-field>

            </div>
          </div>
        </div>
      </div>
      <!-- Transferee ID Information link start -->
      <div *ngIf="(transfereeIDInfoCapability == 'read')" fxLayout fxFlex="20" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
        fxLayoutAlign="end center">
        <div fxLayout="row" class="launch-text">
          <span class="b-builder" (click)="openTransfereeInfoModal()">Transferee ID Information</span>
        </div>
      </div>
       <!-- Transferee ID Information link end -->
      <!-- Restore transferee button -->
      <div fxLayout fxFlex="10" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
        fxLayoutAlign="end center" *ngIf="(moveStatus === 'Withdrawn') && (restoreTransfereeCapability == 'read' || restoreTransfereeCapability == 'write')">
        <div fxLayout="row" class="restore">
          <img class="restore-disabled" alt="restore-transferee-disabled" *ngIf="restoreTransfereeCapability == 'read'" matTooltip=""
                src="../../../../../../assets/images/restore.svg" />
          <img class="restore-active" alt="restore-transferee" (click)="restoreTransferee()" *ngIf="restoreTransfereeCapability == 'write'" matTooltip="Restore Transferee"
                src="../../../../../../assets/images/restore_confirm.svg" />
        </div>
      </div>
       <!-- Restore transferee button end -->
      <div fxLayout fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
       fxLayoutAlign="end center" class="xs-none" *ngIf="isMoveStatusValid(moveStatus)">
        <div fxLayout="row" class="launch-text" *ngIf="bbTransfereePortalCapability  && (candidateDetails.authorizationType === 'BenefitsBuilder' || isLumpSum)">
          <span class="launch">Launch:</span>
          <span class="b-builder" (click)="launchBB()">{{isLumpSum ? 'Cartus Relocation Hub' : 'Benefits Builder'}}</span>
          <!-- <span class="cartus-online">CartusONLINE</span> -->
        </div>
      </div>
     <button class="launch-btn" fxLayoutAlign="center center" fxFlex.xs="100" fxHide.xs=true
       [matMenuTriggerFor]="menu">Launch: <mat-icon>expand_more</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item aria-label="Benefits Builder">{{isLumpSum ? 'Cartus Relocation Hub' : 'Benefits Builder'}}</button>
        <!-- <button mat-menu-item aria-label="Benefits Builder">CartusONLINE</button> -->
      </mat-menu>
    </div>
  </div>
</mat-card>

<!-- transferee details section-->

<mat-accordion class="candidate-review-panel candidate-info-panel" multi="true" *ngIf="transfereeDetailsCapability === 'read'">
  <mat-expansion-panel class="candidate-detail">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div class="panel-heading">Transferee Details</div>
    </mat-expansion-panel-header>

    <mat-panel-description *ngIf="candidateDetails.authorizationType === 'Traditional'">
      <div class="container" fxLayout="column" fxLayout.xs="column" fxFlex="100%" fxLayoutGap="10px">
        <div fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label> Auth Create Date</mat-label>
              <input matInput [value]="authCreateDate" readonly>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Auth Create Time</mat-label>
              <input matInput [value]="authSubmitTime"  readonly>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Auth Create Time Zone</mat-label>
              <input matInput [value]="authTimeZone" readonly>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="genericDatesTemplate"></ng-container>
        <div fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field" *ngIf="showSSOField && (transfereeSSOCapabilities == 'read' || transfereeSSOCapabilities == 'write')">
            <mat-form-field>
              <mat-label>SSO Number</mat-label>
              <div fxFlex="97%" *ngIf="!isSSOIdentifierEdit"><input readonly matInput class="businessUnit" [(ngModel)]="ssoEditValue"></div>
              <div fxFlex="97%" *ngIf="isSSOIdentifierEdit">
                <input (input)="ssoChange($event.target.value)" matInput class="businessUnit" [formControl]="ssoIdentifier">
              </div>
              <div fxFlex="3%">
                <mat-icon *ngIf="(transfereeSSOCapabilities == 'write' && moveStatus !== 'Withdrawn')"
                  (click)="setSSOIdentifier();" aria-label="sso number" tabindex="0"
                  [ngClass]="isSSOIdentifierEdit? 'sso-edit': 'sso-non-edit' " >edit</mat-icon>
              </div>
              <mat-error *ngIf="ssoIdentifier.invalid">{{showSSOApiErr? showSSOApiErr : ssoRegexErrorMessage}}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33%" *ngIf= "(jobStartCapability == 'read' || jobStartCapability == 'write')" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field jobstartDate">           
            <mat-form-field>
              <mat-label>Job Start Date </mat-label>
              <div class="align-fields">
              <input readonly matInput class="businessUnit" [value]="DefaultjobStartDate" *ngIf="!jobStartEdit">
              
              <input readonly matInput class="businessUnit" [matDatepicker]="jobStartDate" [hidden]="!jobStartEdit"
              [formControl]="initaljobStartDate" (dateChange)="jobStartDateChange('change', $event);jobStartEdit=false">
              <mat-datepicker-toggle matSuffix [for]="jobStartDate" *ngIf="jobStartEdit"></mat-datepicker-toggle>
              <mat-datepicker #jobStartDate></mat-datepicker>
              <button  tabindex="0" [disabled]="jobStartCapability ==='read'"  mat-icon-button aria-label="edit job startDate" class="edit_icon" (click)="jobStartEdit=true;jobStartDate.open();"
              >
             <mat-icon aria-label="edit" matTooltip="Edit request">edit</mat-icon>
           </button>
            </div>
          </mat-form-field>
          </div>

          <!-- estimated arrival date -->
          <ng-container>
            <ng-container *ngTemplateOutlet="estimatedArrivalDateSection"></ng-container>
          </ng-container>
        </div>
      </div>
    </mat-panel-description>
    
    <mat-panel-description *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'">
      <div class="container" fxLayout="column" fxLayout.xs="column" fxFlex="100%" fxLayoutGap="10px">
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Business Unit:</mat-label>
            <input class="businessUnit" readonly
              [value]="(transferee?.hasOwnProperty('divisionName')) ? transferee.divisionName : 'NA'" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
          <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
            *ngIf="consultantinfo?.firstName">
            <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>

          </button>

          <mat-form-field [ngClass]="{'sonsultant-field' : consultantinfo?.firstName}">
            <mat-label>Consultant: </mat-label>
            <input 
              [value]="(consultantinfo?.hasOwnProperty('firstName')) ? (consultantinfo.firstName + ' ' + consultantinfo.lastName) : 'NA'"
              readonly class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Atlas Customer ID:</mat-label>
            <input 
              [value]="(consultantinfo?.hasOwnProperty('custFileId')) ? consultantinfo?.custFileId : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        
        <!-- popover-->
        <div class="phone-numbers" *ngIf="showSN">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px">
              <span class="short-name" *ngIf="consultantinfo?.firstName">
                <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>
              </span>
              <span> {{consultantinfo? (consultantinfo?.firstName + ' ' + consultantinfo?.lastName) : 'N/A'}}</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggleSN()">
              <mat-icon aria-label="close">close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs" fxLayout="column" fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutGap="15px">
              <img src="../../../../../../assets/images/baseline-email-24px.svg" alt="Telephone Receiver" />
              <span class="email"> {{consultantinfo?.emailDetailsList[0].emailAddress}}</span>
            </div>

            <div fxLayout="row" fxLayoutGap="15px">
              <img src="../../../../../../assets/images/baseline-phone-24px.svg" alt="Telephone Receiver" />
              <span> +{{consultantinfo?.phoneDetailsList[0]?.phoneDialingCode}} &nbsp;
                {{consultantinfo?.phoneDetailsList[0]?.phoneNumber}}</span>
            </div>
          </div>
          <div fxLayoutAlign="center center">
            <button class="close-btn" mat-button (click)="toggleSN()" aria-label="close">Close</button>
          </div>

        </div>
      </div>
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Employee #</mat-label>
            <input 
              [value]="(candidateDetails.optionalDetails?.hasOwnProperty('employeeID')) ? ((candidateDetails?.optionalDetails.employeeID !== '') ? candidateDetails?.optionalDetails.employeeID : 'NA') : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Client Contact Initiator</mat-label>
            <input 
              [value]="(candidateDetails?.hasOwnProperty('ccinitiatorNameDetails')) ? candidateDetails?.ccinitiatorNameDetails.fullName  : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
             <mat-label>Created By</mat-label>
             <input 
               [value]="(candidateDetails?.hasOwnProperty('createdByNameDetails')) ? candidateDetails?.createdByNameDetails.fullName  : 'NA'" readonly
               class="level" matInput>
           </mat-form-field>
         </div>
        </div>
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label> Auth Create Date</mat-label>
              <input matInput [value]="authCreateDate" readonly>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Auth Create Time</mat-label>
              <input matInput [value]="authSubmitTime"  readonly>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Auth Create Time Zone</mat-label>
              <input matInput [value]="authTimeZone" readonly>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="genericDatesTemplate"></ng-container>
        <div fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field sso-field" *ngIf="showSSOField && (transfereeSSOCapabilities == 'read' || transfereeSSOCapabilities == 'write')">
            <mat-form-field>
              <mat-label>SSO Number</mat-label>
              <!--for read-only display and ssoEditValue will be set only if api succeed-->
              <div fxFlex="97%" *ngIf="!isSSOIdentifierEdit"><input readonly matInput class="businessUnit" [(ngModel)]="ssoEditValue"></div>
              <!--for user to edit and to check for regEx validation-->
              <div fxFlex="97%" *ngIf="isSSOIdentifierEdit">
                <input (input)="ssoChange($event.target.value)" matInput class="businessUnit" [formControl]="ssoIdentifier">
              </div>
              <div fxFlex="3%">
                <mat-icon *ngIf="(transfereeSSOCapabilities == 'write' && moveStatus !== 'Withdrawn')"
                  (click)="setSSOIdentifier();" aria-label="sso number" tabindex="0"
                  [ngClass]="isSSOIdentifierEdit? 'sso-edit': 'sso-non-edit' ">edit</mat-icon>
              </div>
              <mat-error *ngIf="ssoIdentifier.invalid">{{showSSOApiErr? showSSOApiErr : ssoRegexErrorMessage}}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33%" *ngIf= "(jobStartCapability == 'read' || jobStartCapability == 'write')" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field jobstartDate">           
            <mat-form-field>
              <mat-label>Job Start Date </mat-label>
              <div class="align-fields">
                <input readonly matInput class="businessUnit" [value]="DefaultjobStartDate" *ngIf="!jobStartEdit">
                
                <input readonly matInput class="businessUnit" [matDatepicker]="jobStartDate" [hidden]="!jobStartEdit"
                [formControl]="initaljobStartDate" (dateChange)="jobStartDateChange('change', $event);jobStartEdit=false">
                <mat-datepicker-toggle matSuffix [for]="jobStartDate" *ngIf="jobStartEdit"></mat-datepicker-toggle>
                <mat-datepicker #jobStartDate></mat-datepicker>
                <button  tabindex="0" [disabled]="jobStartCapability ==='read'"  mat-icon-button aria-label="edit job startDate" class="edit_icon" (click)="jobStartEdit=true;jobStartDate.open();">
                  <mat-icon aria-label="edit" matTooltip="Edit request">edit</mat-icon>
                </button>
              </div>
            </mat-form-field>
          </div>

          <!-- estimated arrival date  -->
          <ng-container>
            <ng-container *ngTemplateOutlet="estimatedArrivalDateSection"></ng-container>
          </ng-container>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>

<div class="trans-move-detail-chip trans-move-bottom-margin"  style="margin-bottom: 2px;">
  Transferee Move Details:
  <div class="trans-move-margin"  fxLayout="row" fxLayoutGap="2em">
    <div class="filter-chips">
      <mat-chip-list #chipSelect aria-label="Filter Selection">
        <!-- <ng-container *ngFor="let moveDetail of moveDetailList"> -->
          <mat-chip #chipRef="matChip" class="chip-style" *ngFor="let moveDetail of moveDetailList" selected ="{{moveDetail.orderRequestId === currentChipOrderRequestId ? true : false}}"
            >
            <span class="hand-cursor" (click)="onChipClick(chipRef, moveDetail)">
              <p>{{moveDetail.departureAddr?.state? moveDetail.departureAddr.state: 'N/A'}}, {{moveDetail.departureAddr?.country? moveDetail.departureAddr.countryCode: 'N/A '}}  >  {{moveDetail.destinationAddr?.state? moveDetail.destinationAddr.state: ' N/A'}}, {{moveDetail.destinationAddr?.country? moveDetail.destinationAddr.countryCode: ' N/A'}}</p>
              <p class="chip-movestatus">{{moveDetail.moveStatus | titlecase}} :  {{moveDetail.moveStatusDate}}</p>
            </span>
          </mat-chip>
          <!-- </ng-container> -->
      </mat-chip-list>
    </div>
    <div class="add-Move-Detail" *ngIf = "(manageAuthorizationsCapability == 'write' || manageAuthorizationsCapability == 'read') && enableAuthorization">
      <button [matMenuTriggerFor]="menu" [disabled]="manageAuthorizationsCapability ==='read'" class="user-water user-water-height" [ngClass] = "{'close-btn-style': menuStatus.menuOpen}" #menuStatus="matMenuTrigger"  tabindex="0" aria-label="Add">
        <mat-icon  *ngIf = "!menuStatus.menuOpen">add</mat-icon>
        <mat-icon *ngIf = "menuStatus.menuOpen">close</mat-icon>
      </button> 
      <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item *ngIf="clientService.bbRole.includes('benefitsBuilder')" (click)="openDialog(false)" (keyup.enter)="openDialog(false)">New Benefits Builder Move</button>
        <button mat-menu-item *ngIf="clientService.bbRole.includes('traditionalAuthorization')" (click)="openDialog(true)" (keyup.enter)="openDialog(true)">New Traditional Move</button>
      </mat-menu>
    </div>
  </div>
</div>

<mat-accordion class="candidate-review-panel move-departure-panel" multi="true" *ngIf="isVisible" >
  <!--Move Details starts-->
  <mat-expansion-panel class="move-detail-panel" *ngIf="transfereeMovDetailCapabilities == 'read'">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Move Details</div>
    </mat-expansion-panel-header>
    <!-- bb start -->
    <mat-panel-description class="remove-margin" >
      <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
        fxLayoutGap="32px" fxLayoutGap.xs="4px">
        <div fxFlex="45%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box" *ngIf="(transfereeLocationCapabilities == 'write' || transfereeLocationCapabilities == 'read')">
          <div class="tile-flex" fxLayout.xs="row" fxLayoutGap="1">
            <div class="box-fields" fxFlex.xs="45">
              <mat-form-field class="city" fxFlex.xs="100">
                <mat-label>Departure</mat-label>
               <input matInput placeholder="Departure" readonly
                   [value]="((transferee?.departureAddr?.city)?(transferee.departureAddr.city) + ', ':'') + ((transferee?.departureAddr?.state)?(transferee.departureAddr.state) + ', ':'') +( (transferee?.departureAddr?.country)?(transferee.departureAddr.country):'')">
              </mat-form-field>
            </div>
            <div class="separator" fxFlex.xs="5">
              <mat-icon class="material-icons">chevron_right</mat-icon>
            </div>
            <div class="box-fields" fxFlex.xs="45">
              <mat-form-field class="city" fxFlex.xs="100">
                <mat-label>Destination:</mat-label>
                <input class="city" matInput placeholder="Destination" readonly
                  [value]="((transferee?.destinationAddr?.city)?(transferee.destinationAddr.city) + ', ':'') + ((transferee?.destinationAddr?.state)?(transferee.destinationAddr.state) + ', ':'') +( (transferee?.destinationAddr?.country)?(transferee.destinationAddr.country):'')">
              </mat-form-field>
            </div>
            <div fxFlex.xs="5">
              <span matTooltip="Click here to edit Details"
                *ngIf="(transfereeLocationCapabilities == 'write') && (!confirmedPoints || (!(confirmedPoints && confirmedPoints > 0))) "
                class="create-style">
                <mat-icon tabindex="0" style="cursor: pointer;" (click)="openDialoge()" (keyup.enter)="openDialoge()">create
                </mat-icon>
              </span>
            </div>
          </div>
        </div>
        <!-- total people moving -->
        <div fxFlex="12%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box" *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'">
          <div class="tile-flex">
            <div class="box-fields" style="max-width: 85%;">
              <mat-form-field style="max-width: 80%;">
                <mat-label>Total People Moving:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly
                  [value]="(transferee?.hasOwnProperty('totalNumberOfRelocatingMembers')) ? transferee.totalNumberOfRelocatingMembers : 'NA'">
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- total people moving end -->

        <div fxFlex=45% fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
          <div class="tile-flex" fxFlex.xs="100%">
            <!-- estimated date -->
            <div class="movedate" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column">
              <legend fxFlex.xs="100%"><span class="label-text">Estimated Move Date:</span></legend>
              <div fxLayout="row" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between">
                <div fxFlex="50%" fxFlex.xs="45%" class="startdate">
                  <mat-form-field fxFlex.xs="100%">
                    <input class="startDate" matInput readonly [value]="estimatedMoveStartDate| date:'yyyy-MM-dd'">
                  </mat-form-field>
                </div>
                <div fxFlex="50%" fxFlex.xs="45%" class="enddate">
                  <mat-form-field fxFlex.xs="100%">
                    <input class="endDate" matInput readonly [value]="estimatedMoveEndDate| date:'yyyy-MM-dd'">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- estimated date end  -->

            <!-- job start date  -->
            <div class="movedate" style="margin-left: 5%;" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column" *ngIf="candidateDetails.authorizationType === 'Traditional'">
              <legend fxFlex.xs="50%"><span class="label-text">Job Start Date</span></legend>
              <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between">
                <div fxFlex="33%" fxFlex.xs="33%" class="startdate ">
                  <mat-form-field fxFlex.xs="100%">
                    <input class="startDate" matInput readonly  [value]="DefaultjobStartDate ? DefaultjobStartDate   : 'NA' " *ngIf="!jobStartEdit">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- job start date end  -->
          </div>
        </div>
      </div>
    </mat-panel-description>
    <!-- bb end -->

      <mat-panel-description class="add-margin">
        <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
            fxLayoutGap="32px" fxLayoutGap.xs="4px">
            <div fxLayout="column" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
          <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
            <div fxFlex="28%"  fxLayout = "row" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
              <mat-form-field >
                <mat-label>Contract Name </mat-label>
                <mat-select [formControl] = "contractDetails" [compareWith]="compareContractData" (selectionChange)="getContractBaseDetails($event)" [disabled] = "!transferee.authorizationDetails?.contractDetails?.editContractName" *ngIf="userRole === 'file-setup-specialist'" >
                  <mat-option [value]="contractData.contractDetails" *ngFor="let contractData of contractList">
                    {{contractData?.contractDetails?.baseContract}}  {{contractData?.contractDetails?.contractId}}   {{contractData?.contractDetails?.contractName}}
                </mat-option>
                </mat-select> 
                <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist'"
                [value]=" contractValues ? contractValues : 'NA'"
                matInput>
              </mat-form-field>  
            </div>
            <div fxFlex="28%"  fxLayout = "row" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
              <mat-form-field >
                <mat-label>Billing Currency</mat-label>  
                <mat-select [formControl] = "currencies" [compareWith]="displayCurrencyFn"   [disabled]="!transferee.authorizationDetails?.currency?.editCurrencyName" *ngIf="userRole === 'file-setup-specialist'" >
                    <mat-option  [value] ="curr.currencies" *ngFor="let curr of filteredCurrency ">
                        {{curr?.currencies?.code}}- {{curr?.currencies?.description}} 
                    </mat-option>
                  </mat-select>
                  <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist'"
                  [value]=" currencyValues ? currencyValues : 'NA'"
                  matInput> 
                <!-- </mat-autocomplete> -->
            </mat-form-field>
            </div>
            <div fxFlex="28%"  fxLayout = "row" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
              <mat-form-field >
                <mat-label>Move Type</mat-label>  
                <mat-select [formControl] = "moveType" [compareWith]="displaymoveFn" [disabled] ="!editMoveType"  *ngIf="userRole === 'file-setup-specialist'" >
                    <mat-option  [value] ="move" *ngFor="let move of moveTypeValues">
                        {{move}}
                    </mat-option>
                  </mat-select>
                  <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist'"
                  [value]=" transferee.moveType ? transferee.moveType : 'NA'"
                  matInput>
                <!-- </mat-autocomplete> -->
            </mat-form-field>
            </div>
            <div fxFlex="10%"  fxLayout = "row" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
              <button *ngIf="moveStatus !== 'Withdrawn' && (editTransfereeContractCapability === 'write' || editTransfereeContractCapability === 'read')" 
                [disabled]="editTransfereeContractCapability === 'read'" (click)="editcontractNameDetails()" class="btn-border-transparent">
                <mat-icon tabindex="0" class="edit-program-name" [ngStyle]="editContractName ? ( transferee.authorizationDetails.contractDetails.editContractName  ? {'color' : '#4F85C4'} :{'color' :'#000000'}) : {'color' :'#000000'}" fxLayoutAlign="end">edit</mat-icon>
              </button>
            </div>
          </div>
            <div *ngIf="geoOrigin.value"  fxFlex="100%" fxLayout="row" fxFlex.md="40%" fxFlex.xs="100%">
              <mat-form-field fxFlex="26%" class="geo-Origin">
                <mat-label>Geo Origin</mat-label>
                  <input readonly [formControl]="geoOrigin" matInput>
                  </mat-form-field>
               </div>
        </div>
      </div>
     </mat-panel-description>
    <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
      <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
          fxLayoutGap="32px" fxLayoutGap.xs="4px">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
         

          <!-- policy section -->
          <div fxFlex="47%" fxFlex.md="47%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Policy </mat-label>
              <div fxFlex="97%">
                <input  type="text"
                aria-label="Assign Policy" [formControl]="assignedPolicy"
                [matAutocomplete]="policyAutocomplete"
                class="level" 
                [readonly] = "!isPolicyEditEnabled"
                matInput>
                <mat-autocomplete #policyAutocomplete="matAutocomplete">
                  <mat-option style="font-size: large;" (click)="policySelection(option)"  *ngFor="let policy of filteredPolicyValues | async" [value]="policy">
                    {{policy}}
                  </mat-option>
                </mat-autocomplete>
              </div>
                           
              <div fxFlex="3%" *ngIf="!isPolicyFieldReadOnly" class="btn-border-transparent" (click)="editAssignedPolicy()">
                <mat-icon aria-label="edit" style="cursor: pointer"  >edit</mat-icon>
              </div>
              
            </mat-form-field>
          </div>
          
          <div fxFlex="47%" fxFlex.md="47%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Authorization Type</mat-label>
              <input 
                [value]="(candidateDetails?.hasOwnProperty('authorizationType')) ?(candidateDetails.authorizationType)  : 'NA'" readonly
                class="level" matInput>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
   </mat-panel-description>
        <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
          <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
              fxLayoutGap="32px" fxLayoutGap.xs="4px">
              <div fxFlex="48.5%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
            <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
              <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>Atlas Customer ID</mat-label>
                  <input 
                    [value]="(consultantinfo?.hasOwnProperty('custFileId')) ? consultantinfo?.custFileId : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
              <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>Division</mat-label>
                  <input 
                    [value]="(transferee?.hasOwnProperty('divisionName')) ? transferee.divisionName : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
     </mat-panel-description>
            <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
              <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
                  fxLayoutGap="32px" fxLayoutGap.xs="4px">
                  <div fxFlex="48.5%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
                <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                    <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                      *ngIf="consultantinfo?.firstName">
                      <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>
                    </button>
                    <mat-form-field [ngClass]="{'avatar-field' : consultantinfo?.firstName}">
                      <mat-label>Consultant: </mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('firstName')) ? (consultantinfo.firstName + ' ' + consultantinfo.lastName) : 'NA'"
                        readonly class="level" matInput>
                    </mat-form-field>
                  </div>
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                    <mat-form-field  >
                      <mat-label>Consultant Phone #</mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('phoneDetailsList')) ? (consultantinfo.phoneDetailsList[0]?.phoneNumber) : 'NA'"
                        readonly
                        class="level" matInput>
                    </mat-form-field>
                  </div>
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                    <mat-form-field>
                      <mat-label>Consultant Email</mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('emailDetailsList')) ? (consultantinfo.emailDetailsList[0].emailAddress) : 'NA'" readonly
                        class="level" matInput>
                    </mat-form-field>
                  </div>
                </div>
              </div>
                  <div fxFlex="48.7%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
                    <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                        <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                          *ngIf="candidateDetails?.createdByNameDetails">
                          <app-avatar [firstName]="candidateDetails?.createdByNameDetails.firstName" [lastName]="candidateDetails?.createdByNameDetails.lastName" ></app-avatar>
                        </button>
                        <mat-form-field [ngClass]="{'avatar-field' : candidateDetails?.createdByNameDetails.fullName}">
                          <mat-label>Created By </mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('createdByNameDetails')) ? candidateDetails?.createdByNameDetails.fullName  : 'NA'" readonly
                            readonly class="level" matInput>
                        </mat-form-field>
                      </div>
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                        <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                          *ngIf="candidateDetails?.ccinitiatorNameDetails">
                          <app-avatar [firstName]="candidateDetails?.ccinitiatorNameDetails.firstName" [lastName]="candidateDetails?.ccinitiatorNameDetails.lastName" ></app-avatar>
                        </button>
                        <mat-form-field [ngClass]="{'avatar-field' : candidateDetails?.ccinitiatorNameDetails.fullName}">
                          <mat-label>Client Contact Initiator </mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('ccinitiatorNameDetails')) ? candidateDetails?.ccinitiatorNameDetails.fullName  : 'NA'" readonly
                            readonly class="level" matInput>
                        </mat-form-field>
                      </div>
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                        <mat-form-field>
                          <mat-label>Authorization Date</mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('authorizationDate')) ?(candidateDetails.authorizationDate | date:'yyyy-MM-dd')  : 'NA'" readonly
                            class="level" matInput>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
             </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>

<!--Family Members starts -->
<mat-accordion class="candidate-review-panel move-departure-panel" multi="true" *ngIf="isVisible  && (familyMemberCapability == 'read' || familyMemberCapability == 'write')">
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Family Members</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div fxFlex="100%">
        <app-family-info (updateMembers)="updateTotalRelocatingMembers($event)" *ngIf="moveStatus && hasFamilyDetails" [isMoveSwitch]="isMoveSwitched" [relocatingMembers]="totalFamilyMembersRelo" [moveStatus]="moveStatus" [relocatingMembers]="totalFamilyMembersRelo" [familyMemberCapability]="familyMemberCapability">
        </app-family-info>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<!--Family Members end -->

<!-- Special Instructions Section-->
<mat-accordion class="candidate-review-panel move-departure-panel" *ngIf="isSpecialInstructionsEnabled">
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Special Instructions</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div fxFlex="100%" fxLayout="row" fxLayoutGap="1em">
        <strong>Relates To</strong>
        <div>{{candidateDetails?.specialInstructions?.categories.join(', ')}}</div>
      </div>
      <div fxFlex="100%" fxLayout="row" fxLayoutGap="1em">
        <strong>Instructions</strong>
        <div>{{candidateDetails?.specialInstructions?.comment}}</div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>

<!--Billing and Reporting Data fields starts -->
<mat-accordion *ngIf = "configFields?.length> 0 && isVisible && (additionalDetailCapability == 'read' || additionalDetailCapability == 'write')" class="candidate-review-panel move-departure-panel" multi="true">
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Billing and Reporting Data fields</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div class="container" fxFlex="100%"  >
        <app-configurable-field *ngIf="moveStatus"
          [configField] = "configFields"
          [orderId] ="orderRequestId"
          [additionalDetailCapability] ="additionalDetailCapability"
          [moveStatus]="moveStatus"
          ></app-configurable-field>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<!--Billing and Reporting Data fields end -->

<!--Additional Doc Upload Section-->

<mat-accordion class="candidate-review-panel" *ngIf="isLumpSumTransfereeEnabled && transfereeDetailDocUpload === 'write'">
  <mat-expansion-panel>
      <mat-expansion-panel-header class='panel-heading'>
          Additional Documents Upload
      </mat-expansion-panel-header>
      <app-additional-document-upload [orderRequestId]="orderRequestId" [displayButton]="true" [allowedDoc]="allowedDoc" [repaymentAgreementType]="repaymentAgreementType" [repayDocId]="repayDocId"></app-additional-document-upload>
    </mat-expansion-panel>
  </mat-accordion>


<!--Document Library starts -->
  <mat-accordion class="candidate-review-panel move-departure-panel doc-lib" multi="true" *ngIf="dmsCapability === 'read' || dmsCapability === 'write'">
    <mat-expansion-panel class="move-detail-panel" [expanded]="isDocLibraryUIExpanded" (opened)="isDocLibraryUIExpanded=true;documentLibSecExpanded()" (closed)="isDocLibraryUIExpanded=false;">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
        <div fxFlex="34%" class="panel-heading">Document Library</div>
      </mat-expansion-panel-header>
      <mat-panel-description class="remove-margin">
        <document-library style="width: 100%;" [orderRequestId]="orderRequestId" (repayDocId)="getRepayId($event)"></document-library>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
<!--Document Library end -->  



  




<!--Banking Information starts -->
<mat-accordion class="candidate-review-panel move-departure-panel" *ngIf="isLumpSum && isLumpSumTransfereeEnabled && bankingInfoCapability == 'read'" multi="true">
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Banking Information</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div fxFlex="100%">
        <banking-info [orderRequestId]="orderRequestId"></banking-info>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<!--Banking Information end -->

<!--------------------Benefits Builder Details------------------------>
<mat-expansion-panel class="benefit-builder-details candidate-review-panel" *ngIf="(candidateDetails.authorizationType === 'BenefitsBuilder' || (candidateDetails.authorizationType === 'Traditional' && isLumpSum)) && isVisible && (((benefitBuilderCapability == 'read' || benefitBuilderCapability == 'write') && !isMMUser) || ((managemovemoveCapability == 'read' || managemovemoveCapability == 'write') && isMMUser))">


  <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
    fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading" *ngIf="!isMMUser && !isLumpSum">Benefits Builder Details</div>
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading" *ngIf="isMMUser">Manage Move Details</div>
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading" *ngIf="isLumpSum">Lump Sum Details</div>
  </mat-expansion-panel-header>
  <mat-panel-description class="business-unit" fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px"
    fxLayoutAlign.xs="center">
    <!-- <ng-container *ngIf = "(transferee?.programDetails.policyCallRequired === true && transferee.policyCallDetails?.policyCallStatus !== 'completed'); then policyCallRequired; else policyCallNotRequired"> -->
    <ng-container
      *ngIf="(transferee?.programDetails.policyCallRequired === true && transferee?.policyCallDetails?.policyCallStatus !== 'completed')">
      <ng-container *ngTemplateOutlet="policyCallRequired"></ng-container>
    </ng-container>

    <ng-container *ngIf="(transferee?.policyCallDetails?.policyCallStatus === 'completed')">
      <ng-container *ngTemplateOutlet="policyCallNotRequired"></ng-container>
    </ng-container>

    <ng-container *ngIf="(transferee?.programDetails?.policyCallRequired === false && transferee?.policyCallDetails?.policyCallStatus !== 'completed') || isLumpSum">
      <ng-container *ngTemplateOutlet="policyCallRequiredIsFalse"></ng-container>
    </ng-container>

    <ng-template #policyCallRequired>
      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">
        
        <ng-container *ngTemplateOutlet="programSection"></ng-container>

        <div fxFlex="40%" fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field" *ngIf="isGOUser">

          <table class="total-budget" *ngIf="!isMMUser">
            <tr>
              <td *ngIf="((userRole === 'consultant'  || userRole === 'team-client-service-manager') && !isGOUser) || userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' || userRole === 'client-account-manager' || userRole === 'application-reliability-analyst' || userRole === 'application-owner'">Total Budget</td>
              <td class="points-td-btn"><div *ngIf="(recalcPointsCapability === 'read' || recalcPointsCapability === 'write') && isGOUser" [matTooltipDisabled]="!isRecalcPointsBtnDisabled" matTooltip="The points on this customer have been overridden. Please contact a points override user or FSU"><button class="points-btn" [ngClass]="{'btn-disabled': isRecalcPointsBtnDisabled}" [disabled] = "isRecalcPointsBtnDisabled && recalcPointsCapability !== 'write'" mat-button (click)="openWarningOrAddModal()">Recalculate <br> Total Points </button></div></td>
              <td class="total-bold" style="width: 45%"  [ngClass]="pointsOverrideCapability === 'write'  || (userRole === 'consultant' && isGOUser) ? 'card-point-cell' : 'bg-disabled'">
                <div class="points"  [ngClass]="{'mat-card-point': pointsOverrideCapability === 'write'}">
                  <div class="points-container" fxFlex = "0 0 100%" fxLayout="row">
                    <span tabindex="0" *ngIf = '!editPoint' class="points-value" fxFlex ="100%">{{transferee?.programDetails.points}} pts</span>
                    <input matInput class = 'points' number-only fxFlex = "0 0 80%" [(ngModel)] = points *ngIf = 'editPoint'>
                    <button fxFlex = "20%" fxLayoutAlign="end" class="green-edit-btn cursorPointer" [disabled]="moveStatus === 'Withdrawn'" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" *ngIf = "pointsOverrideCapability === 'write'">
                      <mat-icon  class="edit" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon>
                    </button>
                    <img class="edit-icon history-icon" *ngIf = "recalcPointsHistory === 'read' && isGOUser" matTooltip="View Points History" (click)="openHistory()" alt="edit"  src="../../../../../../assets/images/b_point history.svg" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td tabindex="0">Spent Amount</td>
              <td>{{confirmedPoints}} Pts
                <!-- <img class="edit-icon" alt="info" src="../../../../../../assets/images/info-24px .svg"/> -->
              </td>
            </tr>
            <tr>
              <td tabindex="0">Unspent Remainder</td>
              <td class="total-bold">{{transferee?.programDetails?.points - confirmedPoints}} Pts</td>
            </tr>
          </table>
        </div>
        <div fxFlex="13%" fxFlex.md="13%" fxFlex.xs="100%" class="item item-1 candidate-review-field" *ngIf="!isGOUser">
          <mat-card *ngIf="!isMMUser"  class="points" [ngClass]="{'mat-card-point': pointsOverrideCapability === 'write'}">
            <span class="points-text">Points Assigned:</span><br />
            <div class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
              <span *ngIf = '!editPoint' class="points-value">{{transferee?.programDetails.points}}</span>
              <input matInput class = 'points' number-only fxFlex="80%" fxLayoutAlign="center center" [(ngModel)] = points *ngIf = 'editPoint'>
              <mat-icon class="edit" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }" *ngIf="pointsOverrideCapability === 'write'">edit</mat-icon> 
            </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
          </mat-card>
          <mat-card  *ngIf="isMMUser" class="points" [ngClass]="{'mat-card-point': pointsOverrideCapability === 'write'}">
            <span class="points-text">Total Budget:</span><br />
            <div class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
              <span *ngIf = '!editPoint' class="points-value">{{transferee?.programDetails.programBudget.totalAmount | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}</span>
              <input matInput class = 'points' number-only fxFlex="80%" fxLayoutAlign="center center" [(ngModel)] = amount *ngIf = 'editPoint'>
              <mat-icon class="edit" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }" *ngIf="pointsOverrideCapability === 'write'">edit</mat-icon> 
            </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
          </mat-card>
        </div>
        <!-- Initial contact call start-->
        <ng-container *ngTemplateOutlet="initialContactSection"></ng-container>
        <!-- Initial contact call end-->
          <!--Policy call - create schedule start-->
          <div tabindex="0" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details" 
          role="none" aria-label="Policy call scheduled details" 
          *ngIf="(userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read') && (transferee?.moveStatus === 'In Progress') && ((candidateDetails.policyCallDetails?.policyCallStatus !== 'scheduled' && candidateDetails.policyCallDetails?.policyCallStatus !== 'completed'))">  
            <mat-card class="initial-contact-call-field">
              <mat-card-content class="initial-contact-call-card-content">
                <div class="initial-contact-header">Policy Call Scheduled Details</div>
                <div class="initial-contact-complete-content">
                  <p class="initial-contact-warning-content">                  
                    <span class="warning-message">Required for Scheduling Policy Call</span>
                  </p>
                  <span *ngIf="(userPolicyCallCapbilities == 'write'); else disablePolicyCallSchedule">
                    <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                    (click)="openSchedulePolicyCallModal(true)" mat-raised-button
                    matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                    </button>
                  </span>
                  <ng-template #disablePolicyCallSchedule>
                    <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                    disabled mat-raised-button
                    matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                    </button>
                  </ng-template>  
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <!-- Policy call - create schedule end-->
          <!--Policy call scheduled start-->
          <div tabindex="0" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details"
          (mouseover) = "editPolicyCallDetails = true" (mouseout) = "editPolicyCallDetails = false" 
          (focus) = "editPolicyCallDetails = true" (focusout) = "editPolicyCallDetails = false" 
          role="none" aria-label="Policy call scheduled details" 
          *ngIf="(userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read') && (candidateDetails.policyCallDetails?.hasOwnProperty('policyCallStatus')) && candidateDetails.policyCallDetails.policyCallStatus ==='scheduled'">  
            <mat-card class="initial-contact-call-field">
              <mat-card-content class="initial-contact-call-card-content">
                <div class="initial-contact-header">Policy Call Scheduled Details</div>
                <div class="initial-contact-complete-content">
                  <div class="initial-contact-complete-content" style="display: flex;">
                    <div class="initial-contact-complete-card-content">
                      <p class="call-completed-details"> Scheduled for
                        <span class="date-time-details">
                          {{displayPolicyCallTime}} {{timeZone}}
                          On {{policyCallDate | date:'MM/dd/yyyy'}} by
                          <span  *ngIf="(userPolicyCallCapbilities == 'write')">
                            <mat-icon tabindex="0" class="edit-program-name cursorPointer" style="display: inline-flex;float: right;margin: 0;" [ngStyle]="editPolicyCallDetails ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                            fxLayoutAlign="end" (click)="openSchedulePolicyCallModal()">edit</mat-icon>
                          </span>
                        </span>
                      </p>
                      <div class="consultant-details">
                        <div class="consultant-avatar">
                          <app-avatar  [firstName]="candidateDetails.policyCallDetails?.schedulerNameDetails?.firstName" [lastName]="candidateDetails.policyCallDetails?.schedulerNameDetails?.lastName"></app-avatar>
                        </div>
                        <div class="consultant-name-container">
                          &nbsp;<span>{{candidateDetails.policyCallDetails?.schedulerNameDetails?.firstName}} {{candidateDetails.policyCallDetails?.schedulerNameDetails?.lastName}}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <mat-icon  class="cursorPointer policy-history-icon" aria-label="policy call history" (click)="showPolicyHistory('policyCallSchedule')">error</mat-icon>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <!-- Policy call scheduled end-->
          <!--Policy call incomplete start-->
          <div *ngIf="(userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read')" tabindex="0" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details">  
            <mat-card class="initial-contact-call-field">
              <mat-card-content class="initial-contact-call-card-content">
                <div class="initial-contact-header">Policy Call Complete Details</div>
                <ng-container class="initial-contact-incomplete-content policy-call-incomplete-content" 
                *ngIf="(userPolicyCallCapbilities == 'read'); else markPolicyCallComplete">
                  <p class="initial-contact-warning-content">                  
                    <span class="warning-message">{{(transferee?.programDetails.policyCallRequired === true) ? 'Required for Benefit Confirmation' : 'Not Required for Benefit Confirmation'}} </span>
                  </p>
                  <mat-card class="policy-incomplet">
                    <span>Policy Call Incomplete</span>
                  </mat-card>
                </ng-container>
                <ng-template #markPolicyCallComplete>
                  <div class="initial-contact-complete-content">
                    <p class="initial-contact-warning-content">                  
                      <span class="warning-message">{{(transferee?.programDetails.policyCallRequired === true) ? 'Required for Benefit Confirmation' : 'Not Required for Benefit Confirmation'}} </span>
                    </p>
                    <span *ngIf="(transferee && transferee?.moveStatus === 'In Progress' && (userPolicyCallCapbilities == 'write')); else disableMarkPolicyComplete">
                      <button aria-label="Policy call completion" class="policy-incomplete active-card"
                      (click)="openCompletePolicyCallModal()" mat-raised-button
                      matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after">Mark Policy Call 
                      Complete</button>
                    </span>
                    <ng-template #disableMarkPolicyComplete>
                      <button aria-label="Policy call completion" class="policy-incomplete active-card"
                      mat-raised-button
                      matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after" style="background-color: #707070; color: white;" disabled>Mark Policy Call
                      Complete</button>
                    </ng-template>    
                  </div>
                </ng-template>
              </mat-card-content>
            </mat-card>
          </div>
          <!-- Policy call incomplete end-->
      </div>
    </ng-template>
    <ng-template #policyCallNotRequired>
      <div tabindex="0" class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">

        <ng-container *ngTemplateOutlet="programSection"></ng-container>

        <div fxFlex="40%" fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">

          <table class="total-budget" *ngIf="!isMMUser">
            <tr>
              <td *ngIf="((userRole === 'consultant' || userRole === 'team-client-service-manager') && !isGOUser) || userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' || userRole === 'client-account-manager' || userRole === 'application-reliability-analyst' || userRole === 'application-owner'">Total Budget</td>
              <td class="points-td-btn"><div *ngIf="(recalcPointsCapability === 'read' || recalcPointsCapability === 'write') && isGOUser" [matTooltipDisabled]="!isRecalcPointsBtnDisabled" matTooltip="The points on this customer have been overridden. Please contact a points override user or FSU"><button class="points-btn" [ngClass]="{'btn-disabled': isRecalcPointsBtnDisabled}" [disabled] = "isRecalcPointsBtnDisabled && recalcPointsCapability !== 'write'" mat-button (click)="openWarningOrAddModal()">Recalculate <br> Total Points </button></div></td>
              <td class="total-bold" style="width: 45%"  [ngClass]="pointsOverrideCapability === 'write' || (userRole === 'consultant' && isGOUser) ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" [ngClass]="{'mat-card-point': pointsOverrideCapability === 'write'}" >
                  <div class="points-container" fxFlex = "0 0 100%" fxLayout="row">
                    <span tabindex="0" *ngIf = '!editPoint' class="points-value" fxFlex ="100%">{{transferee?.programDetails.points}} pts</span>
                    <input matInput class = 'points' number-only fxFlex = "0 0 70%" [(ngModel)] = points *ngIf = 'editPoint'>
                    <button fxFlex = "20%" fxLayoutAlign="end" class="green-edit-btn cursorPointer" [disabled]="moveStatus === 'Withdrawn'" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" *ngIf = "pointsOverrideCapability === 'write'">
                      <mat-icon  class="edit" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon>
                    </button>
                    <img class="edit-icon history-icon" *ngIf = "recalcPointsHistory === 'read' && isGOUser" matTooltip="View Points History" (click)="openHistory()" alt="edit"  src="../../../../../../assets/images/b_point history.svg" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td tabindex="0">Spent Amount</td>
              <td>{{confirmedPoints}} Pts
                <!-- <img class="edit-icon" alt="info" src="../../../../../../assets/images/info-24px .svg"/> -->
              </td>
            </tr>
            <tr>
              <td tabindex="0">Unspent Remainder</td>
              <td class="total-bold">{{transferee?.programDetails?.points - confirmedPoints}} Pts</td>
            </tr>
          </table>
          <table class="total-budget-mmuser" role='presentation' *ngIf="isMMUser">
            <tr>
              <td>Total Budget</td>
              <td class="total-bold" style="width: 30%" [ngClass]="pointsOverrideCapability === 'write'  ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" [ngClass]="{'mat-card-point':pointsOverrideCapability === 'write'}">
                  <div class="points-container" fxFlex = "0 0 100%" fxLayout="row">
                    <span *ngIf = '!editPoint' class="points-value" fxFlex ="80%">{{transferee?.programDetails.programBudget.totalAmount | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}</span>
                    <input matInput class = 'points' number-only fxFlex = "0 0 80%" [(ngModel)] = amount *ngIf = 'editPoint'>
                    <mat-icon fxFlex = "20%" class="edit" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }" aria-label="edit" *ngIf="pointsOverrideCapability === 'write'">edit</mat-icon> 
                  </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
                </div>
                <!-- <div *ngIf = "userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && userRole !== 'relocation-accounting-analyst'">
                  {{transferee?.programDetails.programBudget.totalAmount | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}
                  <img class="edit-icon" alt="edit" src="../../../../../../assets/images/baseline-edit-24px.svg" />
                </div>  -->
              </td>
            </tr>
            <tr>
              <td>
                <div>Estimated Spent Amount</div>
                <div>{{confirmedAmount !== 0 ? (confirmedAmount | number:'1.0':'en-US') : "-"}} {{confirmedAmount !== 0 ? transferee?.programDetails.programBudget.currency : ""}}</div>
              </td>
              <td>
                <div>Actual Spent Amount</div>
                <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                  {{benefitsActualsInfo?.totalsInfo?.actualSpend && benefitsActualsInfo?.totalsInfo?.actualSpend !== 0 ? (benefitsActualsInfo?.totalsInfo?.actualSpend | number:'1.0-2':'en-US') : "-"}} {{benefitsActualsInfo?.totalsInfo?.actualSpend && benefitsActualsInfo?.totalsInfo?.actualSpend !== 0 ? transferee?.programDetails.programBudget.currency : ""}}
                </ng-container>
                <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                  {{actualHostCurrencySpend !== 0 ? (actualHostCurrencySpend| number:'1.0-2':'en-US'): "-"}} {{actualHostCurrencySpend !== 0 ? actualHostCurrencyCode : ""}}
                </ng-container>
              </td>
            </tr>
            <tr>
              <td>
                <div>Estimated Available</div>
                <div class="bold-amount">{{transferee?.programDetails.programBudget.totalAmount - confirmedAmount  | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}</div>
              </td>
              <td >
                <div>Actual Available</div>
                <div class="bold-amount">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{benefitsActualsInfo?.totalsInfo?.actualRemaining ? (benefitsActualsInfo?.totalsInfo?.actualRemaining | number:'1.0-2':'en-US') : "-"}} {{benefitsActualsInfo?.totalsInfo?.actualRemaining ? transferee?.programDetails.programBudget.currency : ""}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{actualHostCurrencyRemaining | number:'1.0-2':'en-US'}} {{actualHostCurrencyCode}}
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <!-- Initial contact call start-->
        <ng-container *ngTemplateOutlet="initialContactSection"></ng-container>
        <!-- Initial contact call end-->
         <!--Policy call - create schedule start-->
         <div tabindex="0" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details" 
          role="none" aria-label="Policy call scheduled details" 
          *ngIf="(userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read') && (transferee?.moveStatus === 'In Progress') && ((candidateDetails.policyCallDetails?.policyCallStatus !== 'scheduled' && candidateDetails.policyCallDetails?.policyCallStatus !== 'completed'))">  
            <mat-card class="initial-contact-call-field">
              <mat-card-content class="initial-contact-call-card-content">
                <div class="initial-contact-header">Policy Call Scheduled Details</div>
                <div class="initial-contact-complete-content">
                  <p class="initial-contact-warning-content">                  
                    <span class="warning-message">Required for Scheduling Policy Call</span>
                  </p>
                  <span *ngIf="(userPolicyCallCapbilities == 'write'); else disablePolicyCallSchedule">
                    <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                    (click)="openSchedulePolicyCallModal(true)" mat-raised-button
                    matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                    </button>
                  </span>
                  <ng-template #disablePolicyCallSchedule>
                    <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                    disabled mat-raised-button
                    matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                    </button>
                  </ng-template>  
                </div>
              </mat-card-content>
            </mat-card>
         </div>
         <!-- Policy call - create schedule end-->
         <!--Policy call completed start-->
         <div *ngIf="(userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read')" tabindex="0" fxFlex="20%" fxFlex.md="20%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details">  
          <mat-card class="initial-contact-call-field">
            <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Policy Call Complete Details</div>
              <div class="initial-contact-complete-content">
                <p class="initial-contact-warning-content">                  
                  <span class="warning-message">{{(transferee?.programDetails.policyCallRequired === true) ? 'Required for Benefit Confirmation' : 'Not Required for Benefit Confirmation'}} </span>
                </p>
                <div class="initial-contact-complete-content" style="display: flex;">
                  <div class="initial-contact-complete-card-content">
                    <p class="call-completed-details"> Policy Call Completed On
                      <span class="date-time-details">
                        {{policyCallCompleteDate}}
                        at {{displayPolicyCompleteCallTime}} by
                      </span>
                      <span *ngIf="(userPolicyCallCapbilities == 'write')">
                        <mat-icon tabindex="0" class="edit-program-name complete-policy-call-edit-icon  cursorPointer" style="display: inline-flex;float: right;margin: 0;"
                        fxLayoutAlign="end" (click)="openCompletePolicyCallModal()">edit</mat-icon>
                      </span>
                    </p>
                    <div *ngIf="transferee?.policyCallDetails?.consultantNameDetails" class="consultant-details">
                      <div class="consultant-avatar">
                        <app-avatar  [firstName]="transferee?.policyCallDetails?.consultantNameDetails?.firstName" [lastName]="transferee?.policyCallDetails?.consultantNameDetails?.lastName"></app-avatar>
                      </div>
                      <div class="consultant-name-container">
                        &nbsp;<span>{{transferee?.policyCallDetails?.consultantNameDetails?.firstName}} {{transferee?.policyCallDetails?.consultantNameDetails?.lastName}}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-icon  class="cursorPointer policy-history-icon" aria-label="policy call history" (click)="showPolicyHistory('policyCallComplete')">error</mat-icon>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- Policy call completed end-->
        <div *ngIf="unconfirmBenefitsCapability ==='write' || unconfirmBenefitsCapability ==='read'" style="padding-right: 0;" fxFlex="15%" fxFlex.md="15%" fxFlex.xs="100%" class="item item-1 candidate-review-field"  fxLayout="column" fxLayoutAlign="end end">
          <button [disabled]="!canUnconfirmBenefit" class="unconfirm-btn" (click)="unconfirmBenefitDialog($event)" >Unconfirm All Benefits</button>
        </div>
      </div>
      <div class="move-phase" fxFlex="100%" fxLayout="column">
        <div fxLayout="row" fxFlex="40" fxLayoutAlign="end center" class="currency-toggle-align" *ngIf="(hostBillingCapability === 'read' || hostBillingCapability === 'write') && isMMUser">
          <mat-label>Host&nbsp;&nbsp;&nbsp;</mat-label>
          <mat-slide-toggle [disabled]="hostBillingCapability === 'read'" [formControl]="selectCurrency"></mat-slide-toggle>
          <mat-label>&nbsp;&nbsp;&nbsp;Billing</mat-label>
        </div>
        <div class="before-move" *ngFor="let benefit of benefitsList" fxLayout="column" fxLayoutGap="8px">
          <ng-container *ngIf="benefit.items.length > 0; else noneBenefit">
            <p> <span class="move-phase">Move Phase:</span><span class="status"> {{benefit.group}}</span></p>
            <div class="before-move-label" *ngFor='let item of benefit.items' fxLayout="row" fxLayout.xs="column"
              fxLayoutAlign="space-between center">
              <div class='confirmed-benefit' fxFlex="55">
                <span class="title">{{item.displayName}}</span> <span class="date"><span>Confirmed On:</span>
                  {{item.confirmedDate}}</span>
                  <button  *ngIf="unconfirmBenefitsCapability ==='write' && isDeleteEnable(item)" mat-button matSuffix mat-icon-button aria-label="Unconfirm Benefit" role="button" (click)="unconfirmSelectedBenefit(item)" >
                    <mat-icon matTooltip="Cancel">delete</mat-icon>
                  </button>
              </div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser  && !item.productMonetaryValue && !item.tierValues && !item.hybridTempLiving" class="pts"> {{item.points == 'Guaranteed' ? 'Core Benefit 0' : item.points}} Pts</div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser && item.productMonetaryValue" class="pts"> {{item.points === 0 ? '0': item.points }} Point(s): {{item.productMonetaryValue.amount}} {{item.productMonetaryValue.currency}}</div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser && !item.tierValues && !item.productMonetaryValue && item.hybridTempLiving" class="pts"><div>Core Benefit {{item.coreUnitsPerSelection}}</div><div style="text-align: end;" *ngIf = "item.points"> {{item.points}}</div> </div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser && item.tierValues" class="pts"> {{item.tierValues[0].value}} Pts</div>
              <div *ngIf = "item.value && item.currency" class="pts"> {{item.points === 0 ? '0 Pts': item.points }} Point(s): {{formatReplacementValue(item.value)}} {{item.currency}}</div>
              <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount == 'Guaranteed'" fxFlex="45" class="pts confirmed-benefit" >
                <div fxFlex="20" class="core-benefit" >
                  <img class="core-benefits-img" alt="core-benefits" src="../../../../../../assets/images/Group_3141.svg">
                  <span class="core-benefits-text" fxShow.xs="false" fxShow="true" >CoreBenefits</span>
                </div>
                <div  class="estimated-cost"  fxFlex="40">Estimated Cost : <span class="estimate-cost-value">-</span></div>
                <div class="actual-cost"  fxFlex="40"><strong>Actual Cost : </strong>  <span class="actual-cost-value">-</span></div>
              </div>
            <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount !== 'Guaranteed' && !item.confirmedCount" fxFlex="45" class="pts confirmed-benefit" >
              <div fxFlex="20" role="none"></div>
              <div class="estimated-cost" fxFlex="40">Estimated Cost : <span class="estimate-cost-value">{{item.budget?.total.estimatedAmount.amount | number:'1.0':'en-US'}} {{item.budget?.total.estimatedAmount.currency}}</span></div>
              <div class="actual-cost"  fxFlex="40"><strong>Actual Cost :</strong>  
                <span class="actual-cost-value">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') : "-"}} {{item?.hostCurrencyCode}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{!selectCurrency.value ? (item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') :  "-") : (item?.billingCurrencyAmt ? (item?.billingCurrencyAmt | number:'1.0-2':'en-US') :  "-") }}
                    {{!selectCurrency.value ? item?.hostCurrencyCode : item?.billingCurrencyCode }} 
                  </ng-container>
                </span>
              </div>
            </div>
            <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount !== 'Guaranteed' && item.confirmedCount" fxFlex="45" class="pts confirmed-benefit" >
              <div fxFlex="20" role="none">
                {{item.confirmedCount}}  instance(s) : 
              </div>
              <div class="estimated-cost" fxFlex="40">Estimated Cost : <span class="estimate-cost-value">{{item.budget?.total.estimatedAmount.amount | number:'1.0':'en-US'}} {{item.budget?.total.estimatedAmount.currency}}</span></div>
              <div class="actual-cost"  fxFlex="40"><strong>Actual Cost :</strong>  
                <span class="actual-cost-value">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') : "-"}} {{item?.hostCurrencyCode}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{!selectCurrency.value ? (item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') :  "-") : (item?.billingCurrencyAmt ? (item?.billingCurrencyAmt | number:'1.0-2':'en-US') :  "-") }}
                    {{!selectCurrency.value ? item?.hostCurrencyCode : item?.billingCurrencyCode }} 
                  </ng-container>
                </span>
              </div>
            </div>
            </div>
          </ng-container>
          <ng-template #noneBenefit>
            <p> <span class="move-phase">Move Phase:</span><span class="status">{{benefit.group}}</span></p>
            <div class="shipping-label" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
              None Added
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
    <ng-template #policyCallRequiredIsFalse>
      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">

        <ng-container *ngTemplateOutlet="programSection"></ng-container>

        <div *ngIf="!isLumpSum" fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" class="item item-1 candidate-review-field right-space-remover">
          <table class="total-budget" *ngIf="!isMMUser">
            <tr>
              <td *ngIf="((userRole === 'consultant'|| userRole === 'team-client-service-manager') && !isGOUser)|| userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'  || userRole === 'client-account-manager' || userRole === 'application-reliability-analyst' || userRole === 'application-owner'">Total Budget</td>
              <td class="points-td-btn"><div *ngIf="(recalcPointsCapability === 'read' || recalcPointsCapability === 'write') && isGOUser" [matTooltipDisabled]="!isRecalcPointsBtnDisabled" matTooltip="The points on this customer have been overridden. Please contact a points override user or FSU"><button class="points-btn" [ngClass]="{'btn-disabled': isRecalcPointsBtnDisabled}" [disabled] = "isRecalcPointsBtnDisabled && recalcPointsCapability !== 'write'" mat-button (click)="openWarningOrAddModal()">Recalculate <br> Total Points </button></div></td>
              <td class="total-bold" style="width: 45%"  [ngClass]="pointsOverrideCapability === 'write' || (userRole === 'consultant' && isGOUser) ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" [ngClass]="{'mat-card-point': pointsOverrideCapability === 'write'}" >
                  <div class="points-container" fxFleX= "0 0" fxLayout="row" fxLayoutAlign="space-around stretch">
                    <span *ngIf = '!editPoint' class="points-value" fxFlex = "100%">{{transferee?.programDetails.points}} pts</span>
                    <input matInput class = 'points' number-only fxFlex="0 0 70%" fxLayoutAlign="center center" [(ngModel)] = points *ngIf = 'editPoint'>
                    <button fxFlex = "20%" fxLayoutAlign="end" class="green-edit-btn" [disabled]="moveStatus === 'Withdrawn'" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" *ngIf = "pointsOverrideCapability === 'write'">
                      <mat-icon  class="edit" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon>
                    </button> 
                    <img class="edit-icon history-icon" *ngIf = "recalcPointsHistory === 'read' && isGOUser" matTooltip="View Points History" (click)="openHistory()" alt="edit"  src="../../../../../../assets/images/b_point history.svg" />
                  </div>
                </div>
                <ng-template #edits>
                  <div class="points"  [ngClass]="{'mat-card-point':pointsOverrideCapability === 'write'}">
                    <div class="points-container" fxFleX= "0 0" fxLayout="row" fxLayoutAlign="space-around stretch">
                      <span *ngIf = '!editPoint' class="points-value" fxFlex = "80%">{{transferee?.programDetails?.points}}</span>
                      <input matInput class = 'points' number-only fxFlex="0 0 80%" fxLayoutAlign="center center" [(ngModel)] = points *ngIf = 'editPoint'>
                      <mat-icon fxFlex="0 0 20%" class="edit edit-budget" (keydown.enter)= "editPointValidation()"  (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }" aria-label="edit" *ngIf="pointsOverrideCapability === 'write'">edit</mat-icon> 
                    </div>
                  </div>
                </ng-template>
               
              </td>
            </tr>
            <tr>
              <td>Spent Amount</td>
              <td>{{confirmedPoints}} Pts</td>
            </tr>
            <tr>
              <td>Unspent Remainder</td>
              <td class="total-bold">{{transferee?.programDetails?.points - confirmedPoints}} Pts</td>
            </tr>
          </table>
          <table class="total-budget-mmuser" *ngIf="isMMUser">
            <tr>
              <td>Total Budget</td>
              <td class="total-bold" style="width: 30%"  [ngClass]="pointsOverrideCapability === 'write' ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" [ngClass]="{'mat-card-point':pointsOverrideCapability === 'write'}">
                  <div class="points-container" fxFleX= "0 0" fxLayout="row" fxLayoutAlign="space-around stretch">
                    <span *ngIf = '!editPoint' class="points-value">{{transferee?.programDetails?.programBudget.totalAmount | number:'1.0':'en-US'}} {{transferee?.programDetails?.programBudget.currency}}</span>
                    <input matInput class = 'points' number-only fxFlex="0 0 80%" fxLayoutAlign="center center" [(ngModel)] = amount *ngIf = 'editPoint'>
                    <mat-icon fxFlex="0 0 20%" class="edit" (keydown.enter)= "editPointValidation()" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }" *ngIf="pointsOverrideCapability === 'write'">edit</mat-icon>
                  </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
                </div>
                <!-- <div *ngIf = "userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && userRole !== 'relocation-accounting-analyst'">
                  {{transferee?.programDetails.programBudget.totalAmount | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}
                  <img class="edit-icon" alt="edit" src="../../../../../../assets/images/baseline-edit-24px.svg" />
                </div> -->
              </td>
            </tr>
            <tr>
              <td>
                <div>Estimated Spent Amount</div>
                <div>{{confirmedAmount !== 0 ? (confirmedAmount | number:'1.0':'en-US') : "-"}} {{confirmedAmount !== 0 ? transferee?.programDetails.programBudget.currency : ""}}</div>
              </td>
              <td>
                <div>Actual Spent Amount</div>
                <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{benefitsActualsInfo?.totalsInfo?.actualSpend && benefitsActualsInfo?.totalsInfo?.actualSpend !== 0 ? (benefitsActualsInfo?.totalsInfo?.actualSpend | number:'1.0-2':'en-US') : "-"}} {{benefitsActualsInfo?.totalsInfo?.actualSpend && benefitsActualsInfo?.totalsInfo?.actualSpend !== 0 ? transferee?.programDetails.programBudget.currency : ""}}
                </ng-container>
                <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                  {{actualHostCurrencySpend !== 0 ? (actualHostCurrencySpend| number:'1.0-2':'en-US'): "-"}} {{actualHostCurrencySpend !== 0 ? actualHostCurrencyCode : ""}}
                </ng-container>
              </td>
            </tr>
            <tr>
              <td>
                <div>Estimated Available</div>
                <div class="bold-amount">{{transferee?.programDetails.programBudget.totalAmount - confirmedAmount  | number:'1.0':'en-US'}} {{transferee?.programDetails.programBudget.currency}}</div>
              </td>
              <td >
                <div>Actual Available</div>
                <div class="bold-amount">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{benefitsActualsInfo?.totalsInfo?.actualRemaining ? (benefitsActualsInfo?.totalsInfo?.actualRemaining | number:'1.0-2':'en-US') : "-"}} {{benefitsActualsInfo?.totalsInfo?.actualRemaining ? transferee?.programDetails.programBudget.currency : ""}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{actualHostCurrencyRemaining | number:'1.0-2':'en-US'}} {{actualHostCurrencyCode}}
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <ng-container *ngIf="isLumpSum">
          <ng-container *ngTemplateOutlet="lumpSumSection"></ng-container>
        </ng-container>

        <!-- lump sum Transfree audit details start  -->
        <div *ngIf="isLumpSum && ( lumpSumAuditCompleteCapability == 'read' ||  lumpSumAuditCompleteCapability == 'write')" tabindex="0" fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details">  
          <mat-card class="initial-contact-call-field">
            <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Transferee Audit Details</div>
              <ng-container class="initial-contact-incomplete-content" 
              *ngIf="(transferee && transferee?.moveStatus ==='Audit Pending'); else markAuditComplete">
                <span *ngIf="editLumpDetails || lumpSumAuditCompleteCapability == 'read' || !lumpSumForm.get('amount').value || !lumpSumForm.get('currency').value || (transferee.programDetails?.cartusCalculate && !lumpSumForm.get('source').value ) ; else enableMarkAudit ">                    
                  <button style="margin-top: 20%;display:block;width: 100%;background-color: #707070; color: white;" aria-label="Policy call completion" class="policy-incomplete active-card"
                    mat-raised-button
                    matTooltip="Audit can only be completed by Consultant" matTooltipPosition="after" disabled>Mark Audit Complete
                  </button>
                    
                  
                  </span>

                <ng-template #enableMarkAudit>    
                  <span>
                    <button style="margin-top: 20%;display:block;width: 100%;" aria-label="Policy call completion" class="policy-incomplete active-card"
                    (click)="markAuditCallComplete()" mat-raised-button
                     matTooltipPosition="after">Mark Audit Complete</button>
                  </span>                                
                </ng-template>
              </ng-container>
              
              <ng-template #markAuditComplete>
                <div class="initial-contact-complete-content">
                  
                  <div class="initial-contact-complete-card-content">
                    <p class="call-completed-details"> Audit Completed On 
                      <span class="date-time-details">
                        {{transferee?.auditStatusDetails?.auditStatusCompleteDate | date:'yyyy-MM-dd'}}
                        at {{transferee?.auditStatusDetails?.auditStatusCompleteDate | date:'shortTime'}} by
                      </span>
                    </p>
                    
                    <div class="consultant-details" (click)="openConsultantDetailsModal()">
                      <div class="consultant-avatar">
                        <app-avatar  [firstName]="transferee?.auditStatusDetails?.updatedByFirstName" [lastName]="transferee?.auditStatusDetails?.updatedByLastName"></app-avatar>
                      </div>
                      <div class="consultant-name-container">
                        &nbsp;<span>{{transferee?.auditStatusDetails?.updatedByFirstName}} {{transferee?.auditStatusDetails?.updatedByLastName}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template> 
            </mat-card-content>
          </mat-card>
        </div>
        <!-- lump sum Transfree audit details end  -->

       <!-- Initial contact call start-->
       <ng-container *ngTemplateOutlet="initialContactSection"></ng-container>
       <!-- Initial contact call end-->
        <!--Policy call - create schedule start-->
        <div tabindex="0" fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details" 
        role="none" aria-label="Policy call scheduled details" 
        *ngIf="!isLumpSum && (userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read') && (transferee?.moveStatus === 'In Progress') && ((candidateDetails.policyCallDetails?.policyCallStatus !== 'scheduled' && candidateDetails.policyCallDetails?.policyCallStatus !== 'completed'))">  
          <mat-card class="initial-contact-call-field">
            <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Policy Call Scheduled Details</div>
              <div class="initial-contact-complete-content">
                <p class="initial-contact-warning-content">                  
                  <span class="warning-message">Required for Scheduling Policy Call</span>
                </p>
                <span *ngIf="(userPolicyCallCapbilities == 'write'); else disablePolicyCallSchedule">
                  <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                  (click)="openSchedulePolicyCallModal(true)" mat-raised-button
                  matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                  </button>
                </span>
                <ng-template #disablePolicyCallSchedule>
                  <button aria-label="Policy call schedule" class="policy-incomplete active-card"
                  disabled mat-raised-button
                  matTooltip="Policy Call can be scheduled by Consultant" matTooltipPosition="after">Schedule Policy Call
                  </button>
                </ng-template>  
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- Policy call - create schedule end-->
        <!--Policy call scheduled start-->
        <div tabindex="0" fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details"
        (mouseover) = "editPolicyCallDetails = true" (mouseout) = "editPolicyCallDetails = false" 
        (focus) = "editPolicyCallDetails = true" (focusout) = "editPolicyCallDetails = false" 
        role="none" aria-label="Policy call scheduled details" 
        *ngIf="!isLumpSum && (userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read') && (candidateDetails.policyCallDetails?.hasOwnProperty('policyCallStatus')) && candidateDetails.policyCallDetails.policyCallStatus ==='scheduled'">  
          <mat-card class="initial-contact-call-field">
            <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Policy Call Scheduled Details</div>
              <div class="initial-contact-complete-content">
                <div class="initial-contact-complete-content" style="display: flex;">
                  <div class="initial-contact-complete-card-content">
                    <p class="call-completed-details"> Scheduled for
                      <span class="date-time-details">
                        {{displayPolicyCallTime}} {{timeZone}}
                        On {{policyCallDate | date:'MM/dd/yyyy'}} by
                        <span  *ngIf="(userPolicyCallCapbilities == 'write')">
                          <mat-icon tabindex="0" class="edit-program-name cursorPointer" style="display: inline-flex;float: right;margin: 0;" [ngStyle]="editPolicyCallDetails ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                          fxLayoutAlign="end" (click)="openSchedulePolicyCallModal()">edit</mat-icon>
                        </span>
                      </span>
                    </p>
                    <div class="consultant-details">
                      <div class="consultant-avatar">
                        <app-avatar  [firstName]="candidateDetails.policyCallDetails?.schedulerNameDetails?.firstName" [lastName]="candidateDetails.policyCallDetails?.schedulerNameDetails?.lastName"></app-avatar>
                      </div>
                      <div class="consultant-name-container">
                        &nbsp;<span>{{candidateDetails.policyCallDetails?.schedulerNameDetails?.firstName}} {{candidateDetails.policyCallDetails?.schedulerNameDetails?.lastName}}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <mat-icon  class="cursorPointer policy-history-icon" aria-label="policy call history" (click)="showPolicyHistory('policyCallSchedule')">error</mat-icon>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- Policy call scheduled end-->
        <!--Policy call incomplete start-->
        <div *ngIf="!isLumpSum && (userPolicyCallCapbilities == 'write' || userPolicyCallCapbilities == 'read')" tabindex="0" fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details">  
          <mat-card class="initial-contact-call-field">
            <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Policy Call Complete Details</div>
              <ng-container class="initial-contact-incomplete-content policy-call-incomplete-content" 
              *ngIf="(userPolicyCallCapbilities == 'read'); else markPolicyCallComplete">
                <p class="initial-contact-warning-content">                  
                  <span class="warning-message">{{(transferee?.programDetails.policyCallRequired === true) ? 'Required for Benefit Confirmation' : 'Not Required for Benefit Confirmation'}} </span>
                </p>
                <mat-card class="policy-incomplet">
                  <span>Policy Call Incomplete</span>
                </mat-card>
              </ng-container>
              <ng-template #markPolicyCallComplete>
                <div class="initial-contact-complete-content">
                  <p class="initial-contact-warning-content">                  
                    <span class="warning-message">{{(transferee?.programDetails.policyCallRequired === true) ? 'Required for Benefit Confirmation' : 'Not Required for Benefit Confirmation'}} </span>
                  </p>
                  <span *ngIf="(transferee && transferee?.moveStatus === 'In Progress' && (userPolicyCallCapbilities == 'write')); else disableMarkPolicyComplete">
                    <button aria-label="Policy call completion" class="policy-incomplete active-card"
                    (click)="openCompletePolicyCallModal()" mat-raised-button
                    matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after">Mark Policy Call 
                    Complete</button>
                  </span>
                  <ng-template #disableMarkPolicyComplete>
                    <button aria-label="Policy call completion" class="policy-incomplete active-card"
                    mat-raised-button
                    matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after" style="background-color: #707070; color: white;" disabled>Mark Policy Call
                    Complete</button>
                  </ng-template>    
                </div>
              </ng-template>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- Policy call incomplete end-->
        <div *ngIf="!isLumpSum && (unconfirmBenefitsCapability ==='write' || unconfirmBenefitsCapability ==='read')" style="padding-right: 0;" fxFlex="14%" fxFlex.md="14%" fxFlex.xs="100%" class="item item-1 candidate-review-field"  fxLayout="column" fxLayoutAlign="end end">
          <button [disabled]="!canUnconfirmBenefit" class="unconfirm-btn" (click)="unconfirmBenefitDialog($event)" >Unconfirm All Benefits</button>
        </div>
      </div>

      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center" style="margin-top: 2%;">


       

        <div fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%">
          </div>

         

        <!-- payment eligibility start -->
        <div *ngIf="isLumpSum && transferee && transferee?.paymentEligibilityInfo  && (overridePaymentEligibilityCapability === 'read' || overridePaymentEligibilityCapability === 'write')" fxFlex="25%" tabindex="0" fxFlex.md="25%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details" style="margin-top: 3%;">
          <mat-card class="initial-contact-call-field" >
            <mat-card-content class="initial-contact-call-card-content" style="margin-bottom: 19%;">
              <div class="initial-contact-header">Payment Eligibility 
                <span style="position: absolute;top: 2%;">
                  <mat-icon  class="cursorPointer policy-history-icon" aria-label="policy call history" style="margin-top: 0;" (click)="showPaymentOverrideHistory()">error</mat-icon>
                </span>
              </div>
             <div class="initial-contact-complete-content">
              <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                  fxLayoutGap.xs="0em">
                  <!--Eligibility date-->
                  <div fxLayout.xs="column" fxFlex.xs="100%" style="margin-top: 5%">
                    <mat-form-field>
                      <mat-label>Payment Eligibilty Date</mat-label>
                      <input [readonly]="!isOverrideClicked || transferee?.lumpSumAmountRequested" matInput [matDatepicker]="moveInDate" datePickerFormat="YYYY-MM-DD" [formControl]="eligibilityDate" (dateChange)="eligibilityDateChange()" >
                      <mat-datepicker-toggle [disabled]="!isOverrideClicked || transferee?.lumpSumAmountRequested"   matSuffix [for]="moveInDate">
                      </mat-datepicker-toggle>
                      <mat-datepicker #moveInDate></mat-datepicker>
                    </mat-form-field>
                  </div> 
              </div>

              <p class="initial-contact-warning-content" style="text-align: start;margin-top: 10%;">                  
                <span class="warning-message" style="padding-left: 0 ;">{{paymentEligibilityText}}</span>
              </p>

              <mat-checkbox [disabled] = "isPaymentEligibilityDisabled()" (change)="isOverrideChecked()" aria-label="payment Eligibility" [formControl]="isPaymentEligibilityOverride" aria-checked="false" style="left:5%;position: absolute;margin-top: 3%;">
                Override Payment Eligibility
              </mat-checkbox>
              
             </div>

            </mat-card-content>
          </mat-card>
        </div>
        <!-- payment eligibility end -->
        
      </div>
      
      <div *ngIf="!isLumpSum" class="move-phase" fxFlex="100%" fxLayout="column">
        <div fxLayout="row" fxFlex="40" fxLayoutAlign="end center" class="currency-toggle-align" *ngIf="(hostBillingCapability === 'read' || hostBillingCapability === 'write') && isMMUser">
          <mat-label>Host&nbsp;&nbsp;&nbsp;</mat-label>
          <mat-slide-toggle [disabled]="hostBillingCapability === 'read'" [formControl]="selectCurrency"></mat-slide-toggle>
          <mat-label>&nbsp;&nbsp;&nbsp;Billing</mat-label>
        </div>
        <div tabindex="0" class="before-move" *ngFor="let benefit of benefitsList" fxLayout="column" fxLayoutGap="8px">
          <ng-container *ngIf="benefit.items.length > 0; else noneBenefit">
            <p>
              <span class="move-phase">Move Phase:</span>
              <span class="status"> {{benefit.group}}</span>
            </p>
            <div class="before-move-label" *ngFor='let item of benefit.items' fxLayout="row" fxLayout.xs="column"
              fxLayoutAlign="space-between center">
              <div class="confirmed-benefit" fxFlex="55">
                <span class="title">{{item.displayName}}</span> <span class="date"><span>Confirmed On:</span>
                  {{item.confirmedDate}}</span>
                  <button *ngIf="unconfirmBenefitsCapability ==='write' && isDeleteEnable(item)" mat-button matSuffix mat-icon-button aria-label="Unconfirm Benefit" role="button" (click)="unconfirmSelectedBenefit(item)" >
                    <mat-icon matTooltip="Cancel">delete</mat-icon>
                  </button>
              </div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser  && !item.productMonetaryValue && !item.tierValues && !item.hybridTempLiving" class="pts"> {{item.points == 'Guaranteed' ? 'Core Benefit 0' : item.points}} Pts</div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser  && !item.productMonetaryValue && !item.tierValues && item.hybridTempLiving" class="pts"><div>Core Benefit {{item.coreUnitsPerSelection}}</div><div style="text-align: end;" *ngIf = "item.points"> {{item.points}}</div> </div>

              <div *ngIf = "!item.value && !item.currency && !isMMUser && item.productMonetaryValue && !item.hybridTempLiving" class="pts"> {{item.points === 0 ? '0': item.points }} Point(s): {{item.productMonetaryValue.amount}} {{item.productMonetaryValue.currency}}</div>
              <div *ngIf = "!item.value && !item.currency && !isMMUser && item.tierValues" class="pts"> {{item.tierValues[0].value}} Pts</div>
              <div *ngIf = "item.value && item.currency" class="pts"> {{item.points === 0 ? '0 Pts': item.points }} Point(s): {{formatReplacementValue(item.value)}} {{item.currency}}</div>
              <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount == 'Guaranteed'" fxFlex="45" class="pts confirmed-benefit" >
                <div fxFlex="20" class="core-benefit" >
                  <img class="core-benefits-img" alt="core-benefits" src="../../../../../../assets/images/Group_3141.svg">
                  <span class="core-benefits-text" fxShow.xs="false" fxShow="true" >CoreBenefits</span>
                </div>
                <div  class="estimated-cost"  fxFlex="40">Estimated Cost : <span class="estimate-cost-value">-</span></div>
                <div class="actual-cost"  fxFlex="40"><strong>Actual Cost : </strong>  <span class="actual-cost-value">-</span></div>
              </div>
            <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount !== 'Guaranteed' && !item.confirmedCount" fxFlex="45" class="pts confirmed-benefit" >
              <div fxFlex="20" role="none"></div>
              <div class="estimated-cost" fxFlex="40">Estimated Cost : <span class="estimate-cost-value">{{item.budget?.total.estimatedAmount.amount | number:'1.0':'en-US'}} {{item.budget?.total.estimatedAmount.currency}}</span></div>
              <div class="actual-cost"  fxFlex="40"><strong>Actual Cost :</strong> 
                <span class="actual-cost-value">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') : "-"}} {{item?.hostCurrencyCode}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{!selectCurrency.value ? (item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') :  "-") : (item?.billingCurrencyAmt ? (item?.billingCurrencyAmt | number:'1.0-2':'en-US') :  "-") }}
                    {{!selectCurrency.value ? item?.hostCurrencyCode : item?.billingCurrencyCode }} 
                  </ng-container>
                </span>
              </div>
            </div>
            <div *ngIf = "!item.value && !item.currency && isMMUser && item.amount !== 'Guaranteed' && item.confirmedCount" fxFlex="45" class="pts confirmed-benefit" >
              <div fxFlex="20" role="none">
                {{item.confirmedCount}}  instance(s) : 
              </div>
              <div class="estimated-cost" fxFlex="40">Estimated Cost : <span class="estimate-cost-value">{{item.budget?.total.estimatedAmount.amount * item.confirmedCount  | number:'1.0':'en-US'}} {{item.budget?.total.estimatedAmount.currency}}</span></div>
              <div class="actual-cost"  fxFlex="40"><strong>Actual Cost :</strong>  
                <span class="actual-cost-value">
                  <ng-container *ngIf="userRole !== 'relocation-accounting-analyst'">
                    {{item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') : "-"}} {{item?.hostCurrencyCode}}
                  </ng-container>
                  <ng-container *ngIf="userRole === 'relocation-accounting-analyst'">
                    {{!selectCurrency.value ? (item?.hostCurrencyAmt ? (item?.hostCurrencyAmt | number:'1.0-2':'en-US') :  "-") : (item?.billingCurrencyAmt ? (item?.billingCurrencyAmt | number:'1.0-2':'en-US') :  "-") }}
                    {{!selectCurrency.value ? item?.hostCurrencyCode : item?.billingCurrencyCode }}
                  </ng-container>
                </span>
              </div>
            </div>
           </div>
          </ng-container>
          <ng-template #noneBenefit>
            <p> <span class="move-phase">Move Phase:</span><span class="status">{{benefit.group}}</span></p>
            <div class="shipping-label" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
              None Added
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </mat-panel-description>
  <!--Download-->
  <div class="download" *ngIf="!isLumpSum">
    <div>Download:
        <mat-icon tabindex="0" class="download-icon cursorPointer" (click)="openDownloadToastr('benefitBuilderDetail')">save_alt</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

<!--insurance Details-->
<mat-expansion-panel *ngIf="userRole !== 'client-contact-initiator' && benefitsHVG?.length && isVisible"
  class="benefit-builder-details candidate-review-panel">
  <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
    fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading">Insurance Details</div>
  </mat-expansion-panel-header>
  <mat-panel-description class="business-unit">
    <div class="insurance-details" *ngFor="let hvgbenefit of benefitsHVG" fxFlex="100%">
      <!--Shipment Section-->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div>
          <span class="shipment">Shipment Type: </span>
          <span class="title-bold">{{hvgbenefit.displayName}}</span>
        </div>
        <div class="statusHGV">
          <span class="text-style">Status: </span>
          <span class="title-bold">{{ hvgbenefit.hvgDetail.status}}</span>
          <mat-icon
          *ngIf="hvgbenefit.hvgDetail.status !== 'In Progress'"
           matTooltip="{{
            userRole==='consultant' 
            ? 'Unlock Form for Transferee to continue editing' 
            : 'Form can only be unlocked by Consultant' }}"
           class="cursorPointer" 
           (click)="unlockSubmittedHighValueGoodsPopup(hvgbenefit)">lock_open</mat-icon>
        </div>
      </div>
      <div class="margintop" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div>
          <span class="date" style="font-size: 16px;"><span>Confirmed On:</span>
            {{hvgbenefit.confirmedDate}}</span>
        </div>
        <div>
          <span class="text-style " *ngIf="hvgbenefit.hvgDetail.status !== 'Opted Out'">Total:</span>
          <span class="text-style " *ngIf="hvgbenefit.hvgDetail.status === 'Opted Out'">Opted Out On:</span>
          <span class="title-bold"
            *ngIf="hvgbenefit.hvgDetail.status !== 'Opted Out'">${{hvgbenefit.hvgDetail.totalReplacementValue}}</span>
          <span class="date" style="font-size: 16px;"
            *ngIf="hvgbenefit.hvgDetail.status === 'Opted Out'">{{hvgbenefit.hvgDetail.date}}</span>
        </div>
      </div>
      <!--hvg items box-->
      <div *ngIf="hvgbenefit.hvgDetail.highValueItems?.length > 0" fxFlex="100%">
        <div class="hvgItems" *ngFor="let item of hvgbenefit.hvgDetail.highValueItems">
          <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%">
            <div class="space-left"><span class="title-bold">{{item.name}}</span></div>
            <div><span class="text-style">Replacement Value: </span>
              <span class="title-bold space-right">${{formatReplacementValue(item.replacementValue)}}</span>
            </div>
          </div>
          <div class="margintop" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100%">
            <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="35%">
              <div class="space-left "> <span class="text-style"> Description of item </span> </div>
              <div class="space-left" style="margin-right: 28px;"><span class="title">{{item.description}}</span> </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="56%">
              <div><span class="text-style">ID Number</span> </div>
              <div><span class="title">{{item.serialNumber}}</span> </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="9%">
              <div class="space-right "><span class="text-style">Date Added</span> </div>
              <div class="space-right"><span class="title">{{item.dateAdded}}</span> </div>
            </div>
          </div>
        </div>
      </div>
      <!--none added block-->
      <div class="move-phase" *ngIf="hvgbenefit.hvgDetail.highValueItems?.length === 0">
        <div class="shipping-label" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
          None Added
        </div>
      </div>
      <!--line divider-->
      <div class="space"></div>
    </div>
    <!--Download-->
    <div class="download">
      <div>Download:
          <mat-icon class="download-icon cursorPointer" (click)="openDownloadToastr()">save_alt</mat-icon>
      </div>
    </div>
  </mat-panel-description>
</mat-expansion-panel>
<!--Move Phase and Risk starts -->
<mat-accordion *ngIf="movePhaseRiskCapability =='read' && candidateDetails.authorizationType === 'Traditional' && fileNumber && isVisible && movePhaseAndRiskData" class="candidate-review-panel move-departure-panel" multi="true">
  <mat-accordion 
    class="candidate-review-panel" multi="true">
    <mat-expansion-panel class="move-detail-panel">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
        <div fxFlex="34%" class="panel-heading">Move Phase & Risk</div>
      </mat-expansion-panel-header>
      <mat-panel-description class="remove-margin">
        <div fxFlex="100%" fxLayoutAlign="start center" fxLayoutGap="30px">
          <!--Move Phase start-->
          <div fxFlex="30%">
            <app-employee-move-phase 
            [traditional]="true"
            [selectedMovePhaseNo]= "movePhaseAndRiskData.moveRiskVal">
            </app-employee-move-phase>
          </div>
          <!--Move Phase end-->
          <!--Move Risk start-->
          <div fxFlex="30%">
            <app-move-risk-modal
             [traditional]="true"
             [movePhaseAndRiskData]="movePhaseAndRiskData">
            </app-move-risk-modal>
          </div>
           <!--Move Risk end-->
        </div>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
</mat-accordion>
<!--Move Phase and Risk end -->

<!-- Milestones -->
<mat-accordion  class="candidate-review-panel move-departure-panel"  multi="true" *ngIf = "milestoneCapability === 'read' && candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-expansion-panel *ngIf = "isVisible && this.milestonesData?.length > 0" class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Milestones</div>
    </mat-expansion-panel-header>
    <div style="width: 100%;">
    <app-linked-list [dataSet]="milestonesData"></app-linked-list>
  </div>
  </mat-expansion-panel>
</mat-accordion>
<!-- sentiments -->
<mat-accordion  class=" candidate-review-panel move-departure-panel" multi="true"  *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-expansion-panel *ngIf = " isVisible && this.sentimentData?.length > 0 " class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Sentiment</div>
    </mat-expansion-panel-header>
    <app-linked-list [dataSet]="sentimentData"></app-linked-list>
  </mat-expansion-panel>
</mat-accordion>
<!-- providers -->
<div class="providers" multi="true" *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-card *ngIf="isVisible &&  providerCount > 0">
    <div class="text-style" fxLayout="row" fxFlex fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div>Providers : <span class="left-margin-style">{{providerCount}}</span></div>
      <div><button mat-button class="contained-button button-width hand-cursor"  (click)="viewProviders()"> View Providers
        </button></div>
    </div>  
  </mat-card>
  <div [ngClass]="{'display-none': !isProvider }" fxLayout="row" fxLayout.xs="column">
    <div *ngIf="fileNumber" fxFlex="99">
      
       <span> <mat-icon class="hand-cursor" (click)="leftArrowClick()">keyboard_arrow_left</mat-icon> </span>
      <h1 class="provider-heading"> <span>Providers</span></h1>
       <span> <mat-icon class="hand-cursor" *ngIf="!isSearch" (click)="openSearch()"> search </mat-icon> </span>
        <mat-form-field *ngIf="isSearch" appearance="outline" class="searchInput" >
          <span matPrefix class="lg-only" role="none">
            <mat-icon class="hand-cursor" (click)="closeSearch()">search</mat-icon>
          </span>
          <input  matInput tabindex="0" placeholder="Search search with in the table for.." [(ngModel)]="searchProvider"
            aria-label="Search by name" role="text">
          <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
            [hidden]="!searchProvider" (click)="clearTextSearch()" role="button">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      
      <app-employee-providers [orderRequestId]="currOrderRequestId" [fileNumber]="fileNumber" [traditional]="true"
        [searchProvider]="searchProvider">
      </app-employee-providers>
    </div>
  </div>
</div>
<!--Transactions Section -->
<div class="transaction" *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-card *ngIf="isVisible && transactionAmount > 0">
    <div class="trans-cost-row" fxLayout="row" fxFlex fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div style="display: flex !important;">Total Cost:  <span class="left-margin-style">
          <span>{{displayTransactionAmtValue(transactionAmount)}}</span> <span class="cur-margin">{{transactionCurrency}}</span></span>
      </div>
      <div><button mat-button class="contained-button button-width hand-cursor" (click)="viewTransaction()">View Transactions</button></div>
    </div>
  </mat-card>
  <div class="transaction-table" [ngClass]="{'display-none': !isTransactionShow }" 
    fxLayout="row" fxLayout.xs="column">
    <div fxFlex="1"><mat-icon class="user-water-height" (click)="leftArrowClick()">keyboard_arrow_left</mat-icon></div>
    <div *ngIf="fileNumber" fxFlex="99">
      <app-employee-transactions
      [orderRequestId] = "currOrderRequestId"
      [fileNumber] = "fileNumber"
      [traditional] = "true"
      (sendTotalBillAmount)="getTransactionAmt($event)"
      (sendTotalBillCurrency) ="getTransactionCurrency($event)"
      >
      </app-employee-transactions>
    </div>
  </div>
</div>

<!--Work Order-->
<mat-accordion class=" candidate-review-panel move-departure-panel main-panel  " multi="true"
  *ngIf="accordinData?.length && userRole === 'consultant'">
  <mat-expansion-panel class="move-detail-panel" [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
      <div fxFlex="34%" class="panel-heading">Work Orders</div>
    </mat-expansion-panel-header>
    <!--Temporary living Accomodation-->
    <mat-accordion class=" candidate-review-panel move-departure-panel border-radius" [togglePosition]="'before'"
      multi="true">
      <mat-expansion-panel class="move-detail-panel work-expansion-panel" [expanded]="true"
        *ngFor="let benefits of accordinData; let i =index">
        <mat-expansion-panel-header class="panel-color" [collapsedHeight]="'auto'"
          [expandedHeight]="matExpansionHeaderHeight">
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between none" class="work-panel-heading ">
            <p style="color: #2065F8;">{{benefits.displayName}}</p>
          </div>
          <div fxLayout fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
            fxLayoutAlign="end center" class="xs-none">
            <div fxLayout="row" class="launch-text">
              <span class="launch">Added by:</span>
              <span class="added-by">{{benefits.supplierInfo.lastUpdatedBy}}</span>
              <span class="added-by">on</span>
              <span class="added-by">{{benefits.supplierInfo.lastUpdatedAt| date:"yyyy-MM-dd"}}</span>
            </div>
          </div>
        </mat-expansion-panel-header>
        <table style="width: 100%;" mat-table [dataSource]="benefits?.supplierInfo?.mergedSupOrdAndOrd">
          <!-- reference Column -->
          <ng-container matColumnDef="orderno">
            <th mat-header-cell *matHeaderCellDef> Reference # </th>
            <td mat-cell (click)="workDetailPoup(false,element.supplierRequestId, i,'add', woi, element.isSupplierOrd ? true:false,!element.isSupplierOrd ? element.supplierOrderReference : null,!element.isSupplierOrd ? element.modification : null)" class="reference-no"  style="padding-right: 42px;"
              *matCellDef="let element; let woi = index">
              <span *ngIf="element.isSupplierOrd">
                <span *ngIf="element.status=='paused'"><a> Request order {{ benefits.supplierInfo.supplierOrderRequests.length - woi}} </a></span>
                <span *ngIf="element.status !='paused'"> Request order {{ benefits.supplierInfo.supplierOrderRequests.length - woi}}</span>
              </span>
              <span *ngIf="!element.isSupplierOrd"><a>{{element.supplierOrderReference}} </a> </span> 
            </td>
          </ng-container>
          <!-- Provider Column -->
          <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef> Provider Name </th>
            <td mat-cell *matCellDef="let element">{{element.providerName?element.providerName:'None'}} </td>
          </ng-container>

          <!-- Booking Agent Column -->
          <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef> Booking Agent </th>
            <td mat-cell *matCellDef="let element"> {{element.bookingAgent?element.bookingAgent:null}} </td>
          </ng-container>
          <!--Modification-->
          <ng-container matColumnDef="modification">
            <th mat-header-cell *matHeaderCellDef> Modifications </th>
            <td mat-cell *matCellDef="let element"> <span style="color:#4b85ff;"(click)="workDetailPoup(true,element.supplierRequestId, i,'add', woi, element.isSupplierOrd ? true:false,!element.isSupplierOrd ? element.supplierOrderReference : null,!element.isSupplierOrd ? element.modification : null)">{{ !element.isSupplierOrd ? element.modification : 0 }}</span><span  *ngIf="checkForModificationStatus(element) && !element.isSupplierOrd"><mat-chip class="modification-chip">New!</mat-chip></span></td>
          </ng-container>
          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">{{element.status | titlecase | customTitlecase: "-"}} <span *ngIf="element.status=='paused'"
                matTooltip="API Failure , please re-submit. If second failure, Open a help-desk ticket.">
                <mat-icon style="color: #ff0000;" class="cursorPointer">warning</mat-icon>
              </span>
              <div *ngIf = "element.statusDate" style="font-size: 0.75em;">on {{element.statusDate}}</div>
            </td>
          </ng-container>
          <!--Action column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element; let woi = index">
              <span>
                <mat-icon class="cursorPointer" matTooltip="Cancel" *ngIf="element.status=='in-progress' || element.status=='open'&&element.isSupplierOrd || element.status=='paused' && element.isSupplierOrd ||element.status=='pending' && !element.isSupplierOrd" (click)="deleteWorkorder(element.supplierRequestId,i, woi,element.isSupplierOrd ? true:false)">delete</mat-icon>
              </span>
              <!-- <mat-icon class="edit-column" *ngIf="element.status=='paused'" size="20">edit</mat-icon> -->
              <img class="action-colmn" *ngIf="element.status=='paused'" alt="edit"
                (click)="workDetailPoup(false,element.supplierRequestId, i,'add', woi, true)" matTooltip="Edit request"
                src="../../../../../../assets/images/edit-24px.svg" />
              <img class="action-colmn" matTooltip="{{element.viewAction==true ? 'View Booking Details':'Enter Booking Details'}}"
                (click)="bookingDetailPopup(element.supplierRequestId,i, woi,element.supplierOrderReference)"
                *ngIf="element.status=='pending' || (!element.isSupplierOrd && element.status=='in-progress')" alt="edit" src="../../../../../../assets/images/booking info-24px.svg" />
              <!-- <mat-icon class="action-colmn" matTooltip="{{element.viewAction==true ? 'View Booking Details':'Enter Booking Details'}}"
                              *ngIf="element.status=='pending' || element.status=='in-progress'">info_outline</mat-icon> -->
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <button class="add-btn" [disabled]="checkForDisable(i)" (click)="add(i,benefits)" mat-button>Add</button>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-expansion-panel>
</mat-accordion>

<!--------------------Transferee Details------------------------>
<mat-expansion-panel class="benefit-builder-details candidate-review-panel" *ngIf="transferee?.authorizationType === 'Traditional' && !this.isLumpSum && isSSOEnabled">
  <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading">Traditional Details</div>
  </mat-expansion-panel-header>
  <mat-panel-description fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign.xs="center">
    <!-- Initial contact call start-->
    <ng-container *ngTemplateOutlet="initialContactSection"></ng-container>
    <!-- Initial contact call end-->
  </mat-panel-description>
</mat-expansion-panel>

<ng-template #genericDatesTemplate>
  <div fxLayout="row" fxLayout.xs="column" fxFlex="100%" *ngIf="candidateDetails.cliAuthSubDt">
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label> Client Authorization Submit Date</mat-label>
        <input matInput [value]="candidateDetails.cliAuthSubDt.split('T')[0]" readonly>
      </mat-form-field>
    </div>
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label>Client Authorization Submit Time</mat-label>
        <input matInput [value]="candidateDetails.cliAuthSubTime"  readonly>
      </mat-form-field>
    </div>
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label>Client Authorization Time Zone</mat-label>
        <input matInput [value]="candidateDetails.cliAuthSubTimezone" readonly>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxFlex="100%" *ngIf="candidateDetails.authReadytoProcessDt">
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label> Authorization Ready to Process Date</mat-label>
        <input matInput [value]="candidateDetails.authReadytoProcessDt.split('T')[0]" readonly>
      </mat-form-field>
    </div>
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label>Authorization Ready to Process Time</mat-label>
        <input matInput [value]="candidateDetails.authReadytoProcessTime"  readonly>
      </mat-form-field>
    </div>
    <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
      <mat-form-field>
        <mat-label>Authorization Ready to Process Time Zone</mat-label>
        <input matInput [value]="candidateDetails.authReadytoProcessTimezone" readonly>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #lumpSumSection>
  <div *ngIf="lumpSumAmountCapability === 'read' || lumpSumAmountCapability === 'write' || lumpSumAmountOverrideCapability === 'read' || lumpSumAmountOverrideCapability === 'write' " fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" class="item item-1 candidate-review-field right-space-remover lumpSum">
    <form [formGroup]="lumpSumForm">
      <table class="total-budget">
        <tr>
          <td>
            Total Lump Sum Amount
            <mat-icon style="cursor: pointer;" class="error-icon" aria-label="points overridden" (click)="showOverrideHistory()">error</mat-icon>
          </td>
          <td class="total-bold" style="width: 45%"  class="card-point-cell">
            <div class="points" class="mat-card-point" >
              <div class="points-container" fxFleX= "0 0" fxLayout="row" fxLayoutAlign="space-around stretch">
                <span *ngIf="!editLumpDetails" class="points-value" fxFlex = "100%">{{lumpSumAmount}}</span>
                <input matInput class = 'points' number-only fxFlex="0 0 70%" fxLayoutAlign="center center" formControlName="amount" *ngIf="editLumpDetails">
                <button fxFlex = "20%" fxLayoutAlign="end" class="green-edit-btn" [disabled]="isEditLumpSumAmountEnabled()" (keydown.enter)= "editLumpSumDetails()" (click)="editLumpSumDetails()">
                  <mat-icon  class="edit" [ngStyle] = "{'color':editLumpDetails ? '#4F85C4':'#ffffff' }">edit</mat-icon>
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Currency</td>
          <td>
            <mat-form-field>
              <mat-label>Currency</mat-label>
              <input type="text"
              aria-label="currency"
              matInput
              formControlName="currency"
              [matAutocomplete]="lumpSumCurrency"
              [readonly]="isFieldReadOnly">
              <mat-autocomplete #lumpSumCurrency ="matAutocomplete">
                  <mat-option style="font-size: inherit;"  [value]="curr.currency.code" *ngFor="let curr of filteredLumpSumCurrency | async">
                      {{curr.currency?.code}}
                  </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-checkbox  aria-label="Cartus Calculate" formControlName="cartusCalculate" aria-checked="false">
              Cartus Calculate  
            </mat-checkbox>
          </td>
          <td>
            <mat-form-field *ngIf="cartusCalculate">
              <mat-label>Source</mat-label>
              <mat-select formControlName="source" >
                <mat-option [value]="source" *ngFor="let source of sourcesList">
                  {{source}}
                </mat-option>
              </mat-select> 
            </mat-form-field>
          </td>
        </tr>
      </table>
    </form>
  </div>
</ng-template>

<ng-template #estimatedArrivalDateSection>
  <div fxFlex="33%" *ngIf= "(jobStartCapability == 'read' || jobStartCapability == 'write')" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field jobstartDate">           
    <mat-form-field>
      <mat-label>Estimated Arrival Date</mat-label>
      <div class="align-fields">
        <input readonly matInput class="businessUnit" [value]="defaultEstimatedArrivalDate" *ngIf="!estimatedArrivalDateEdit">
        <input readonly matInput class="businessUnit" [matDatepicker]="estimatedArrivalDate" [hidden]="!estimatedArrivalDateEdit"
           (dateChange)="estimatedArrivalDateChange($event);estimatedArrivalDateEdit=false">
        <mat-datepicker-toggle matSuffix [for]="estimatedArrivalDate" *ngIf="estimatedArrivalDateEdit"></mat-datepicker-toggle>
        <mat-datepicker #estimatedArrivalDate></mat-datepicker>
        <button  tabindex="0" [disabled]="jobStartCapability ==='read'"  mat-icon-button aria-label="edit job startDate" class="edit_icon" (click)="estimatedArrivalDateEdit=true;estimatedArrivalDate.open();">
          <mat-icon aria-label="edit" matTooltip="Edit request">edit</mat-icon>
        </button>
      </div>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #programSection>
  <div fxLayout="column" style="min-width: 25%;">
    <div tabindex="0"  class="item item-1 candidate-review-field" (mouseover)="benefits?.length > 0 ? editProgramName = false : editProgramName = true"
      (mouseout)="!transferee.programDetails.editProgram ? editProgramName = false:null" (focus)="benefits?.length > 0 ? editProgramName = false : editProgramName = true" (focusout)="!transferee.programDetails.editProgram ? editProgramName = false:null">
      <ng-container *ngIf="updateProgramCapability === 'read' || updateProgramCapability === 'write'">
        <mat-form-field>
          <mat-label>Assigned Program: </mat-label>

          <div *ngIf="updateProgramCapability === 'write'" fxFlex="97%">
            <mat-select [formControl] = "programName" id = "programName" (selectionChange) = "updatePoints($event)" [disabled] = "!transferee.programDetails?.editProgram || moveStatus === 'Withdrawn' || updateProgramCapability === 'read'">
              <mat-option *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
            </mat-select> 
          </div>

          <div *ngIf="updateProgramCapability === 'read'" fxFlex="97%">
            <input class="businessUnit" readonly 
            [value]="(transferee?.programDetails.hasOwnProperty('programName')) ? transferee.programDetails.programName : 'NA'"
            matInput> 
          </div>
          
            <div fxFlex="3%" [disabled]="updateProgramCapability === 'read' || (isLumpSum && moveStatus !=='Audit Pending') " (click)="editProgramDetails()" class="btn-border-transparent" >
              <mat-icon aria-label="edit" style="cursor: pointer"  [ngStyle]="editProgramName? (transferee.programDetails.editProgram ? {'color' : '#4F85C4','visibility': 'visible'} :{'visibility': 'visible'}) : {'visibility': 'hidden'}" >edit</mat-icon>
            </div>
            
        </mat-form-field>
         
          <mat-error [ngClass]="!isInProgress ? 'programname-error-contact' :'programname-error'"  *ngIf="!contractAvailable">{{contractERR}}</mat-error>
      </ng-container>
    </div>
    
    <div tabindex="0" *ngIf="isLumpSum && isLumpSumTransfereeEnabled" class="item item-1 candidate-review-field" style="margin-top: 25px;">
      <ng-container>
        <mat-form-field>
          <mat-label>Repayment Agreement Date: </mat-label>        
            <input class="businessUnit" readonly  [formControl]="repaymentAgreementAcceptedDate" matInput> 
          </mat-form-field>         
      </ng-container>
    </div>
    <div tabindex="0" *ngIf="isLumpSum && isLumpSumTransfereeEnabled" class="item item-1 candidate-review-field" style="margin-top: 25px;">
      <ng-container >
        <mat-form-field>
          <mat-label>Lump Sum Requested Date: </mat-label>
          <input class="businessUnit" readonly  [formControl]="lumpSumAmountRequestedDate" matInput> 
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  
  
</ng-template>

<ng-template #initialContactSection>
  <div fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none"
      class="item item-1 candidate-review-field" role="none" aria-label="Initial Contact call details"
      *ngIf="(userInitialContactCapbilities == 'read' || userInitialContactCapbilities == 'write') && 
      (transferee?.programDetails?.initialContactRequired || 
        (transferee?.authorizationType === 'Traditional' && isSSOEnabled)
      )">
      <mat-card class="initial-contact-call-field">
          <mat-card-content class="initial-contact-call-card-content">
              <div class="initial-contact-header">Initial Contact Details</div>
              <ng-container class="initial-contact-incomplete-content"
                  *ngIf="!transferee?.initialCallDetails?.initialCallStatus || transferee?.initialCallDetails?.initialCallStatus !== 'completed'">
                  <p class="initial-contact-info-content"><em>This needs to be done within 24-48 hours of
                          authorization</em></p>
                  <p class="initial-contact-warning-content">
                      <mat-icon class="warning-icon">warning</mat-icon>
                      <span class="warning-message">Required for portal access</span>
                  </p>
                  <span
                      *ngIf="(userInitialContactCapbilities == 'read') || moveStatus === 'In Progress' || moveStatus === 'Audit Pending'; else otherRoles">
                      <button aria-label="Initial Contact call completion"
                          class="initial-contact-call-incomplete active-card makePolicy" mat-raised-button
                          matTooltip="Initial Contact Call can only be completed by Consultant"
                          matTooltipPosition="after" style="background-color: #707070; color: white;" disabled>Mark
                          Initial Contact
                          Complete</button>
                  </span>
                  <ng-template #otherRoles>
                      <button aria-label="Initial Contact call completion"
                          class="initial-contact-call-incomplete active-card" (click)="makeInitialContactComplete()"
                          mat-raised-button matTooltip="Initial Contact Call can only be completed by Consultant"
                          matTooltipPosition="after">Mark Initial Contact
                          Complete</button>
                  </ng-template>
              </ng-container>
              <ng-container class="initial-contact-complete-content"
                  *ngIf="(transferee?.initialCallDetails?.initialCallStatus === 'completed')">
                  <div class="initial-contact-complete-card-content">
                      <p class="call-completed-details"> Initial Contact Completed On
                          <span class="date-time-details">
                              {{transferee?.initialCallDetails?.initialCallCompleteDate | date:'yyyy-MM-dd'}}
                              at {{transferee?.initialCallDetails?.initialCallCompleteDate | date:'shortTime'}} by
                          </span>
                      </p>
                      <div class="consultant-details" (click)="openConsultantDetailsModal()">
                          <div class="consultant-avatar">
                              <app-avatar [firstName]="transferee?.initialCallDetails?.updatedByFirstName"
                                  [lastName]="transferee?.initialCallDetails?.updatedByLastName"></app-avatar>
                          </div>
                          <div class="consultant-name-container">
                              &nbsp;<span>{{transferee?.initialCallDetails?.updatedByFirstName}}
                                  {{transferee?.initialCallDetails?.updatedByLastName}}</span>
                          </div>
                      </div>
                      <!-- consultant details pop up start-->
                      <ng-container *ngIf="isconsultantDetailsModalOpen">
                          <div class="consultantDetailsModal" #consultantDetailsModal>
                              <div class="detail-panel">
                                  <mat-icon class="mailIcon">mail</mat-icon>&nbsp;<span
                                      class="consultant-email">{{transferee?.initialCallDetails?.emailInfo}}</span>
                              </div>
                              <div class="detail-panel">
                                  <mat-icon class="phoneIcon">phone</mat-icon>&nbsp;<span
                                      class="consultant-phone">{{transferee?.initialCallDetails?.phoneInfo}}</span>
                              </div>
                              <mat-divider></mat-divider>
                              <button aria-label="Close" class="active-card close-btn"
                                  (click)="closeConsultantDetails()" mat-raised-button matTooltip=" Close"
                                  matTooltipPosition="after"> Close </button>
                          </div>
                      </ng-container>
                      <!-- consultant details pop up end-->
                  </div>
              </ng-container>
          </mat-card-content>
      </mat-card>
  </div>
</ng-template>