<!-- Account Manager / Consultant view / AO / AR-->
<ng-container *ngIf="(userRole === 'consultant' || userRole === 'client-account-manager' || userRole === 'application-reliability-analyst' || userRole === 'application-owner' || this.userRole === 'team-client-service-manager') && currentViewType == 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>

    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab label="Transferees" *ngIf="authorizationListCapability === 'read'|| authorizationListCapability ==='write'">
            <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Cost Projections" *ngIf="clientService.bbRole.includes('costProjection')">
            <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
        </mat-tab>
        <!--commentted as part of BB-4000 ticket-->
        <!-- <mat-tab label="Client Contacts">
            <ng-container *ngTemplateOutlet="searchBlockforClientContacts"></ng-container>
            <app-client-contacts-list
                [searchKeyword]="searchFilterText"
                (sortList)="getClientContactsList(selectedClientID, $event)"
                [clientContacts]="clientContacts"
                [clientContactCount]= clientContactCount></app-client-contacts-list>
        </mat-tab> -->
        <mat-tab *ngIf="(userCapabilities > -1)" label="Programs">
            <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list>
        </mat-tab>
    </mat-tab-group>
    </div>
</ng-container>

<!-- Program administrator-->
<ng-container *ngIf="(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && currentViewType == 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>

    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab *ngIf="(userCapabilities > -1)" label="Programs">
            <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list>
        </mat-tab>
    </mat-tab-group>
    </div>
</ng-container>

<!-- Client Contact view -->
<ng-container *ngIf="userRole === 'client-contact-initiator' && currentViewType === 'transfereeList'">
    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab *ngIf="authorizationListCapability === 'read'|| authorizationListCapability ==='write'" label="Authorization">
            <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Cost Projections" *ngIf="clientService.bbRole.includes('costProjection')">
            <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="(userCapabilities > -1)" label="Programs">
            <app-program-list [clientId]="(cc_clientPartyId)" ></app-program-list>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<!-- File Setup Specialist view -->
<ng-container *ngIf="userRole === 'file-setup-specialist' && currentViewType === 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>
        <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)" >
            <mat-tab label="Authorization" *ngIf="authorizationListCapability === 'read'|| authorizationListCapability ==='write'">
                <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
                <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
            </mat-tab>
            <mat-tab label="Cost Projections" *ngIf="clientService.bbRole.includes('costProjection')">
                <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
                <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
            </mat-tab>
            <!-- <mat-tab label="Client Contacts">
                <ng-container *ngTemplateOutlet="searchBlockforClientContacts"></ng-container>
                <app-client-contacts-list
                [searchKeyword]="searchFilterText"
                    (sortList)="getClientContactsList(selectedClientID, $event)"
                    [clientContacts]="clientContacts"
                    [clientContactCount]= clientContactCount></app-client-contacts-list>
            </mat-tab> -->
            <mat-tab *ngIf="(userCapabilities > -1)" label="Programs">
                <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>

 <!-- relocation-accounting-analyst user -->
 <ng-container *ngIf="userRole === 'relocation-accounting-analyst' && currentViewType === 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>
        <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)" >
            <mat-tab label="Authorization" *ngIf="authorizationListCapability === 'read'|| authorizationListCapability ==='write'">
                <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
                <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>

<!-- Account Manager / Program Mgr / Consultant / FSU / ARE View in Client List -->
<ng-container *ngIf="(userRole === 'consultant' || userRole === 'program-admin' || userRole === 'product-mgmt-admin' || userRole === 'client-account-manager' || userRole === 'file-setup-specialist' || userRole === 'application-reliability-analyst' || userRole === 'application-owner' || userRole === 'relocation-accounting-analyst' || this.userRole === 'team-client-service-manager' || this.userRole === 'cartus-legal-manager') && currentViewType === 'clientList'">
    <mat-tab-group [selectedIndex]="listTabIndex" (selectedTabChange)="tabClick($event)">
        <mat-tab label="Client" *ngIf="clientListCapability == 'read'|| clientListCapability == 'write'">
            <ng-container *ngTemplateOutlet="searchBlockClients"></ng-container>
            <ng-container *ngTemplateOutlet="listOfClients"></ng-container>
        </mat-tab>
        <mat-tab label="Unprocessed"
            *ngIf="(manageAuthorizationFailures == 'read' || manageAuthorizationFailures == 'write') && isUnprocessedTabEnabled">
            <ng-container *ngTemplateOutlet="searchBlockUnprocessed"></ng-container>
            <app-admin-filter-chip [filterData]="selectedUnprocessedFiltersWithCategories" (filterOptions)="getAdminFilterOptions($event,unprocessedFilters)"></app-admin-filter-chip>
            <ng-container *ngTemplateOutlet="UnprocessedList"></ng-container>
        </mat-tab>
        <mat-tab label="Compliance" *ngIf="(complianceListCapability === 'read' || complianceListCapability === 'write') && isComplianceTabEnabled">
            <ng-container *ngTemplateOutlet="searchBlockComplinace"></ng-container>
            <app-admin-filter-chip [filterData]="selectedComplianceFiltersWithCategories" (filterOptions)="getAdminFilterOptions($event,complianceFilters)"></app-admin-filter-chip>
            <app-compliance-list [searchKeyword]="searchValueForCompliance" [searchComplianceValue]="searchCompliantValue" [selectedFilters]="complianceFilters"></app-compliance-list>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<!-- List of unprocessed transferees  -->
<ng-template #UnprocessedList>
    <app-unprocessed-list [searchKeyword]="searchValueForUnprocessed" [searchValue]="searchUprocessedValue"
        [selectedFilters]="unprocessedFilters"></app-unprocessed-list>
</ng-template>
<!-- Search Block for Client List -->
<ng-template #searchBlockClients>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="search">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  [(ngModel)]="searchValueForClient" (keyup.enter)="textSearchClients($event.target.value)" (keyup.backspace)="textSearchClients($event.target.value)" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Client')" *ngIf="searchValueForClient" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
         <button mat-icon-button class="filter-icon" matTooltip="Filter results" *ngIf="adminTabLabel === 'Unprocessed' || adminTabLabel === 'Compliance'"><span tabindex="0" aria-hidden="true" class="material-icons" (click)="OpenAdminFilter()">filter_list</span></button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons" role="none">view_column</span></button> -->
    </div>
</ng-template>
<!-- Search Block for Unprocessed List -->
<ng-template #searchBlockUnprocessed>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="search">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  [(ngModel)]="searchValueForUnprocessed" (keyup.enter)="searchUnprocessedList($event.target.value)" (keyup.backspace)="searchList($event.target.value,'Unprocessed')" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Unprocessed')" *ngIf="searchValueForUnprocessed" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
         <button mat-icon-button class="filter-icon" matTooltip="Filter results"><span tabindex="1" aria-hidden="true" class="material-icons" (click)="OpenAdminFilter()">filter_list</span></button>
    </div>
</ng-template>
<!-- Search Block for Compliance List -->
<ng-template #searchBlockComplinace>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="search">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  [(ngModel)]="searchValueForCompliance" (keyup.enter)="searchCompliantList($event.target.value)" (keyup.backspace)="searchList($event.target.value,'Compliance')" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Compliance')" *ngIf="searchValueForCompliance" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
         <button mat-icon-button class="filter-icon" matTooltip="Filter results"><span tabindex="2" aria-hidden="true" class="material-icons" (click)="OpenAdminFilter()">filter_list</span></button>
    </div>
</ng-template>
<!-- Search Block for Transferee -->
<ng-template #searchBlockForTransferee>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group" *ngIf = "!transfereeDetailView">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."
                [(ngModel)]="searchValue"
                (keyup)="textSearch($event)"
                (keyup.enter)="textSearchList($event.target.value, 'Transferees')"
                aria-label="Search within table for..."
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Transferees')" *ngIf="searchValue" role="button">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="selectedTabIndex === 0"
            mat-icon-button class="filter-icon"
            matTooltip="Filter results"
            (click)="getListFilters()"
            role="button" aria-label="Open Filter Modal">
            <span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <button tabindex="0" mat-icon-button aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
            <mat-icon>view_column</mat-icon>
          </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
        <app-admin-filter-chip [filterData]="filterDetailsListItems" (filterOptions)="getUpdatedFilterOptions($event, 'move')"></app-admin-filter-chip>
    </div>


</ng-template>



<!--Search block for CP transfree-->

<ng-template #searchBlockForCPTransferee>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group" *ngIf = "!transfereeDetailView">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."
                [(ngModel)]="searchCPValue"
                (keyup)="textSearch($event)"
                (keyup.enter)="textSearchList($event.target.value,'Cost Projections')"
                aria-label="Search within table for..."
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Cost Projections')" *ngIf="searchCPValue" role="button">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="authTabLabel === 'Transferees'"
            mat-icon-button class="filter-icon"
            matTooltip="Filter results"
            (click)="getListFilters()"
            role="button" aria-label="Open Filter Modal">
            <span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <button tabindex="0" mat-icon-button aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
            <mat-icon>view_column</mat-icon>
          </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
        <app-admin-filter-chip [filterData]="filterDetailsListItems" (filterOptions)="getUpdatedFilterOptions($event, 'cp')"></app-admin-filter-chip>
    </div>


</ng-template>


<!--Search block for CP transfree ends here-->



<ng-template #searchBlockforClientContacts>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."
                [(ngModel)]="searchValue"
                (keyup)="textSearch($event)"
                (keyup.enter)="textSearchList($event.target.value,'Client Contacts')"
                aria-label="Search within table for..."
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch('Client Contacts')" *ngIf="searchValue" role="button">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="selectedTabIndex === 0"
            mat-icon-button class="filter-icon"
            matTooltip="Filter results"
            (click)="getListFilters()"
            role="button" aria-label="Open Filter Modal">
            <span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
    </div>
</ng-template>

<!-- List of Transferees for Client Contact Only -->
<ng-template #listOfTransferees>
    <div class="kpi-section">

    </div>
    <!-- Transferee List Table -->
    <app-transferee-list
        (searchCandidates)="callSearchFilter($event, 'move')"
        [filterObj]="filterDetailsListItems"
        [searchKeyword]="searchValue"
        [candidateList]="candidates"
        [candidatesCount]="candidatesCount"
        [userRole]="userRole"
        (transfereeDetailView) = "getTransfereeDetailView($event)"
        (refreshList)="getCandidatesList($event)"
        (sortList)="getCandidatesList($event)"
        (deactivateTransferees)="deactivateTransferee($event)"
        [clientDetails]="clientDetails"
        (changeTab) = "changeTab($event)"
        (closeFilterChips)="closeFilterChips($event)">
    </app-transferee-list>

    <!-- <div mat-elevation-z8>
        <mat-card class="empty-results">
            <div fxLayout="column" fxLayoutAlign="space-around center" >
                <p>0 results matching your criteria</p>
                (click)="clearCriteria()"
                <a>Clear Criteria</a>
            </div>
        </mat-card>
    </div> -->

</ng-template>

<!-- Client List Table for Account Manager & Program Admin Only -->
<ng-template #listOfClients>
    <app-client-list
        [searchKeyword]="searchFilterText"
        [clientList]="clientList"
        [clientListCount]="clientListCount"
        [userRole]="userRole"
        (refreshList)="getClientsList()"
        (sortList)="getClientsList($event)"
        (paginationOptions)="getClientsList($event)"
        (openSelectedList)="openSelectedList($event)">
    </app-client-list>
</ng-template>

<!-- List of Transferees for cost-projection -->
<ng-template #costProjectionTransferees>
    <div class="kpi-section">

    </div>
    <!-- Transferee List Table -->
    <app-transferee-list
        (searchCandidates)="callSearchFilter($event, 'cp')"
        [isCostProjection] = "true"
        [filterObj]="filterDetailsListItems"
        [searchKeyword]="searchCPValue"
        [candidateList]="costCandidates"
        [candidatesCount]="costCandidatesCount"
        [userRole]="userRole"
        (changeTab) = "changeTab($event)"
        (cpTransfereeDetailView) = "getTransfereeDetailView($event)"
        (cpRefreshList)="getCostProjCandidatesList($event)"
        (cpsortList)="getCostProjCandidatesList($event)"
        [clientDetails]="clientDetails">

    </app-transferee-list>

</ng-template>
