import { ChangeDetectorRef, Component, HostListener, Injectable, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListFilterModalComponent } from '../transferee-list-filter/list-filter-modal/list-filter-modal.component';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { GetClientOptions, ClientService } from '../../services/client.service';
import { candidateProfile } from './../../../../core/models/candidate.model';
import { AdminClientContacts } from './../../../../core/models/admin-client-contacts.model';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { RemoteLoggingService } from './../../../../core/services/remote-logging.service';
import { UserTypeService, UserType } from '../../services/user-type.service';
import { ClientContactsService } from '../../services/client-contacts.service';
import { AdminDataviewSelectionModalComponent } from './../admin-dataview-selection-modal/admin-dataview-selection-modal.component';
import { ViewChild } from '@angular/core';
import { TransfereeListComponent } from '../transferee-list/transferee-list.component';
import * as transfereeColumns from '../../../../core/data/transfereeDataView.json';
import * as transfereeColumnDisplay from '../../../../core/data/transfereeColumnDisplay.json';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryService } from '../../services/category.service';
import { AdminFilterModalComponent } from '../admin-filter-modal/admin-filter-modal.component';
import { FeatureFlag, FeatureFlagService } from '../../../../core/services/feature-flag.service';
import { ClientListComponent } from '../client-list/client-list.component';

// import { Client } from '../core/models/client.model';

export interface SearchFilter {
  [key: string]: any;
}

/** message to be displayed on selecting more than 10 transferees for deactivation */
export const deactivationMaxNoMsg = `Maximum 10 transferees can be deactivated at a time`;

/** message on selecting transferees with status other than invitation not sent for deactivation */
export const deactivationWrongStatus = `Only those Transferees with status Invitation Not Sent & Invitation Sent can be deactivated`;

export const deactivationWrongStatusConsultant = `Only those Transferees with status Invitation Not Sent can be deactivated`;

export const deactivationWrongStatusForFSU = `Only those Transferees with status Invitation Not Sent, Invitation Sent or In Progress can be deactivated`;

export const deactivateErrorMessage = 'Deactivate transferee not successful. Refer the guidance information.';

/** message on successfull deactivation of multiple transferees */
export const deactivationSuccessMutilple = `Deactivated selected records`;

/** message on successfull deactivation of single transferee */
export const deactivationSuccess = `Deactivated`;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  /** Can have 3 types of View -> (AccMgr/PgrmMgr -> clientList) | (others -> transfereeList) */
  currentViewType: 'clientList' | 'transfereeList' | 'detailsView';

  /** filterDialogRef to access the Dialog */
  filterDialogRef: MatDialogRef <ListFilterModalComponent>;

  //  dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<AdminDataviewSelectionModalComponent>;

  /** @viewChild to get the child component */
  @ViewChild(TransfereeListComponent) childObj: TransfereeListComponent;


  /** @viewChild to get the MatTabGroup */
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  /** List of Clients */
  clientList: Array<AdminClient> = [];
  /** Contains the Filter Option for the Current Selected Tab */
  currentFilterOptions: GetClientOptions = {};

  /** List of Candidates */
  candidates: Array <candidateProfile> = [];
  candidatesCount = 0;
  searchValueForClient: string;
  searchValueForUnprocessed = ''
  searchValueForCompliance = ''

  /** List of cost-projection Candidates */
  costCandidates: Array <candidateProfile> = [];
  costCandidatesCount = 0;

  selectedFilters: any;

  searchValue: string;

  searchCPValue: string;

  searchFilterText: string;

  /** list of orderrequest ID's to be deactivated */
  deactivationList: Array <any> = [];

  /** user role */
  userRole: UserType;

  cc_clientPartyId: string;
  clientEntityName: string;
  /** Default tabs selection index value */
  selectedTabIndex = 0;

  /** stored details of client to be viewed on selection of client in program/account manager view */
  clientDetails: AdminClient;

  /** stored details of client contact to be viewed on selection of client in program/account manager view */
  clientContacts: Array<AdminClientContacts>;

  /**count of client contacts retrieved */
  clientContactCount = 0;

  /** SelectedClientID */
  selectedClientID: string;

  transfereeDetailView: boolean;

  transfereeListDataView: string[] = ['TransfereeListViewColumnDisplay'];

  /** display column for filter popup */
  displayedColumns: string[] = [];

  /**transferee list column details */
  transfereeColumnsList: any = (transfereeColumns as any).default;

  /**transferee column display details */
  transfereeColumnsDisplay: any = (transfereeColumnDisplay as any).default;

  /** filterDetailsList variable to store the FilterDetails object. */
  filterDetailsListItems: any = [];

  /** filterCount will hold the number of times the filters are applied */
  filterCount = 0;

  /* manage programadmin capabilities*/
  userCapabilities: number;

  /** filterDetailsListCounter will hold the filters to display as chips */
  filterDetailsListCounter = 0;

  filters: any = {};

  /** filterOptions variable to store the updated filter list */
  filterOptions: any;

  /** endDateExist will hold the boolean value whether end date exists or not */
  endDateExist = true;

  /** viewProgramDetailFlag for enable program Deatil */
  viewProgramDetailFlag = false;
  adminTabIndex: number = 0;
  adminTabLabel = '';
  authTabLabel = '';
  listTabIndex = 0;
  manageAuthorizationFailures = '';
  complianceListCapability = '';
  authorizationListCapability = '';

  capabilitySubs: Subscription;

  adminFilterDialogRef: MatDialogRef <AdminFilterModalComponent>;
  selectedAdminFilters: any;
  selectedAuthDateRange: any;
  searchCompliantValue:string;
  searchUprocessedValue:string


    /** feature flag for unprocessed tab */
  isUnprocessedTabEnabled: boolean = false;
  adminSearchValue = ''
  clientListCapability = ''

  unprocessedFilters: any;
  complianceFilters: any;
  selectedUnprocessedFiltersWithCategories;
  selectedComplianceFiltersWithCategories;
  isComplianceTabEnabled: boolean = false;
  clientListCount: number = 0;
  
  /** @viewChild to get the clients list component */
  @ViewChild(ClientListComponent) clientListObj: ClientListComponent;

  // Ensure Change Detection runs before accessing the instance
  //@ContentChild('tabGroup', { static: false }) tabGroup: MatTabGroup;

  // If you need to access it in ngOnInit hook

  /**
   * injecting dependencies
   * @param dialog object for matdialog
   */
  constructor(
    public dialog: MatDialog,
    private candidateService: CandidateProfileService,
    private spinner: NgxSpinnerService,
    private logSrv: RemoteLoggingService,
    private readonly toastrService: ToastrService,
    private userTypeService: UserTypeService,
    private readonly clientService: ClientService,
    private readonly clientContactService: ClientContactsService,
    private dateConversionService: DateConversionService,
    private changeDetector : ChangeDetectorRef,
    private categoryService : CategoryService,
    private featureFlagService: FeatureFlagService 
  ) {
    this.setViewType();
  }

  ngOnInit() {
    this.getFeatureFlag();
    this.capabilitySubs = this.userTypeService.capabilities$.subscribe(ele => {
      if (ele){ this.userCapabilities = ele.findIndex(element => element.name == "Benefits Builder Program Administration Portal" && (element.operation == "write" || element.operation == "read" )) };
      if (ele) { this.checkCapabilities(ele) };
    });
    if (!this.clientService.bbRole.length) { this.checkRole() };
    // category behavioral subject update
    this.categoryService.getCategoryDisplayNames();
    this.cc_clientPartyId = sessionStorage.getItem('cc_clientPartyId');
    this.candidateService.refreshListSubject.subscribe( data => {
      data === 'authorization' ? this.getCandidatesList() :this.getCostProjCandidatesList();
    });
    if (this.userRole === 'consultant' || this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin' ||
    this.userRole === 'file-setup-specialist' || this.userRole === 'application-reliability-analyst' || this.userRole === 'application-owner' || this.userRole === 'client-account-manager' || this.userRole === 'relocation-accounting-analyst' || this.userRole === 'team-client-service-manager') {
      this.getClientsList();
    } else if(this.userRole !== 'cartus-legal-manager' ) {
      if(this.authorizationListCapability === 'read' || this.authorizationListCapability ==='write'){
        this.getCandidatesList();
      }
      this.getCostProjCandidatesList();
    }
    this.clientService.tabIndex$.subscribe((res) => {
      if(res) {
        this.changeDetector.detectChanges();
        if (this.tabGroup) { this.selectedTabIndex = this.tabGroup._tabs.length - 1 };
        this.viewProgramDetailFlag = true;
      }
    });
    if (this.displayedColumns.length === 0) { this.displayedColumns = this.transfereeColumnsDisplay[0].preferencesetting.value }; 
  }

  checkCapabilities(capabilitiesList: any[]) {
    capabilitiesList.forEach(capability => {
      if (capability && capability.permission === 'allow' && capability.name && capability.operation) {
        if (capability.name === "MP - Manage Authorization Failures") {
          this.manageAuthorizationFailures = capability.operation;
        } else if (capability.name === 'View Teams Client List') {
          this.clientListCapability = capability.operation;
        } else if (capability.name === 'MP - Compliance Management') {
          this.complianceListCapability = capability.operation;
        } else if (capability.name === 'MP - Authorization List') {
          this.authorizationListCapability = capability.operation;
        }
      }
    });
  }

  /**
   * Decides what should the view of the component will be.
   * Client List View OR Transferee List View
   */
  setViewType(): void {
    this.userRole = this.userTypeService.userType;
    if (this.userRole === 'consultant' || this.userRole === 'program-admin' || this.userRole === 'product-mgmt-admin' || this.userRole === 'client-account-manager' ||
    this.userRole === 'file-setup-specialist' || this.userRole === 'application-reliability-analyst' || this.userRole === 'application-owner' || this.userRole === 'relocation-accounting-analyst' || this.userRole === 'team-client-service-manager' || this.userRole === 'cartus-legal-manager') {
      this.currentViewType = 'clientList';
    } else {
      this.currentViewType = 'transfereeList';
    }
  }

  /**
   * Gets the List of Clients;
   */
  getClientsList(options?: GetClientOptions) {
    this.spinner.show();
    if (!options && (this.searchValueForClient === undefined || this.searchCPValue === "")){
      options = {
        itemsPerPage: 75,
        pageNumber: 0
      };
    }  else {
      if (this.clientListObj && this.clientListObj.paginator) {
        options['itemsPerPage'] = this.clientListObj.paginator.pageSize;
        options['pageNumber'] = this.clientListObj.paginator.pageIndex;
      }
      if (this.searchValueForClient) {
        options.searchText = this.searchValueForClient;
        options['itemsPerPage'] = 75;
        options['pageNumber'] = 0;
      }
      if(this.searchCPValue){
        options.searchText= this.searchCPValue;
        options['itemsPerPage'] = 75;
        options['pageNumber'] = 0;
      }
    }

    // Persisting the value
    this.currentFilterOptions = options;
    this.clientService.getClients(options).subscribe(res => {
      if (res) {
        this.clientList = res.clients;
        this.clientListCount = typeof res.totalCount === 'string' ? Number(res.totalCount) : res.totalCount;
        if (!options) {
          // clientList to be updated with list of all clients
          this.clientService.clientList.next(this.clientList);
        }
        this.spinner.hide();
      } else {
        this.clientList = [];
        this.clientListCount = 0;
        this.spinner.hide();
      }
    }, err => {
      this.clientList = [];
      this.clientListCount = 0;
      this.spinner.hide();
    });
  }

  getCandidatesList(options ?: {
    sortBy ?: string,
    sortDirection ?: string,
    searchProperties?: any[],
    filters ?: any,
    searchText?: string,
    itemsPerPage?: number,
    pageNumber?:number
  }): void {
    //this.selectedTabIndex = 0;
    if (!options && (this.searchValueForClient === undefined || this.searchValue === "")){
      options = {
        itemsPerPage: 75,
        pageNumber: 0,
        filters:{"moveStatuses":["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress","In Review","Audit Pending","Audit Complete", "Payment Requested"]}
      };
    } else {
      if(options && !options.filters) {
        options = {...options, filters: {"moveStatuses":["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress","In Review","Audit Pending","Audit Complete", "Payment Requested"]}}
        // options['filters'] = {"moveStatuses":["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress"]}
      }
      if (this.childObj && this.childObj.paginator) {
        options['itemsPerPage'] = this.childObj.paginator.pageSize;
        options['pageNumber'] = this.childObj.paginator.pageIndex;
        //options['filters'] = {"moveStatuses":["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress"]};
      } else {
        let colDisplay = [];
        if (this.displayedColumns.length === 0) { this.displayedColumns = this.transfereeColumnsDisplay[0].preferencesetting.value };
        this.transfereeColumnsList.forEach((item) => {
          if (this.displayedColumns.includes(item.field) && item.filter) {
            colDisplay = colDisplay.concat(item.filter);
          }
        });
        this.searchValue ? options = {
          //...options,
          itemsPerPage: 75,
          pageNumber: 0,
          searchProperties:colDisplay,
          filters:{"moveStatuses":["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress","In Review","Audit Pending","Audit Complete", "Payment Requested"]},
          searchText: this.searchValue
        } : options = {
          //...options,
          itemsPerPage: 75,
          pageNumber: 0
        };
      }
      if (this.childObj && this.childObj.sortOptions && this.childObj.sortOptions.hasOwnProperty('sortColumnName') && this.childObj.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.childObj.sortOptions.sortColumnName;
        options['sortDirection'] = this.childObj.sortOptions.sortDirection;
      }
    }
    if (this.userRole !== 'program-admin' && this.userRole !== 'product-mgmt-admin') {
    this.spinner.show();
    if (options && options.filters) {

      if (!options.searchText) {
        delete options.searchText;
      }
      this.candidateService.searchCandidateProfiles(options, this.clientDetails, 'move').subscribe(data => {
        if (data) {
          this.candidates = data.candidates;
          this.candidatesCount = data.count;
        } else {
          this.candidates = [];
          this.candidatesCount = 0;
        }
        if(this.selectedTabIndex === 0){ this.spinner.hide() };
      }, () => {
        this.candidates = [];
        this.candidatesCount = 0;
        if(this.selectedTabIndex === 0){ this.spinner.hide() };
      });

    } else {
      this.candidateService.getCandidateProfiles(options, this.clientDetails, 'move').subscribe(data => {
        if (data) {
          this.candidates = data.candidates;
          this.candidatesCount = data.count;
        } else {
          this.candidates = [];
          this.candidatesCount = 0;
        }

        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      }, () => {
        this.candidates = [];
        this.candidatesCount = 0;
        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      });
    }
    }
  }
  
  searchList(event, tabLabel) {
    if (!event) {
      if (tabLabel === 'Compliance') {
        this.searchCompliantValue = ''
      } else if (tabLabel === 'Unprocessed') {
        this.searchUprocessedValue = ''
      }
    }
  }

  changeTab(evt) {
    this.selectedTabIndex = 0;
    this.getCandidatesList();
    this.getCostProjCandidatesList();
  }
  /**
  * Gets the List of Client Contacts;
  * @param clientId client id  selected by user
  */
  getClientContactsList(clientId?: string, options: GetClientOptions = null) {
    this.clientContacts = [];
    if (options && !options.searchText && this.searchValue) {
      options.searchText = this.searchValue;
    }

    if (options && !options.searchText && this.searchCPValue) {
      options.searchText = this.searchCPValue;
    }

    if ( this.userRole === 'file-setup-specialist' || this.userRole === 'relocation-accounting-analyst') {
      options = {...options, division : true};
    }
    this.spinner.show();
    this.clientContactService.getClientContacts(clientId || this.selectedClientID, options).subscribe(res => {
      if (res) {
        this.clientContacts = res.clientContacts;
        this.clientContactCount = res.count;
      } else {
        this.clientContacts = [];
      }
      this.spinner.hide();
    }, err => {
      this.clientContacts = [];
    });
  }

  /**
   * Search for Clients
   * @param searchText Search Text Value
   */
  textSearchClientContacts(searchText: string) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchValue = searchText;
      this.searchFilterText = this.searchValue;
      const newOptions = Object.assign(this.currentFilterOptions, { searchText });
      this.currentFilterOptions = newOptions;
      this.getClientContactsList(this.selectedClientID, newOptions);
    }

    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchCPValue = searchText;
      this.searchFilterText = this.searchCPValue;
      const newOptions = Object.assign(this.currentFilterOptions, { searchText });
      this.currentFilterOptions = newOptions;
      this.getClientContactsList(this.selectedClientID, newOptions);
    }
  }

  /**
   * Get Table Filters
   */
  getListFilters() {
    this.spinner.show();
    this.candidateService.getListFilters().subscribe(res => {
      if (res) {
        if (res.moveStatuses) {
          this.spinner.hide();
          const filter = { moveStatuses: null };
          filter.moveStatuses = res.moveStatuses.map(item => {
            return {
              label: item,
              value: item
            };
          });
          this.filterModal(filter);
        }
      } else {
        this.spinner.hide();
      }
    }, () => {
      this.spinner.hide();
    });
  }

  displayChips(filters) {
    if (filters) {
      this.filters = filters;
      /* const preference: UserPreference = {
        Key: 'aggregation_filter',
        Value: this.filters,
        Persist: false
      };*/
      //this.userPreferenceService.setPreference(preference);
      //Lump Sum
      if(this.filters.lumpsum) {
        if(this.filterDetailsListItems.findIndex(ele => ele.category === 'Show Only Lump Sum') === -1){
           this.filterDetailsListItems.push({
             // tslint:disable-next-line: rxjs-no-unsafe-scope
             category: 'Show Only Lump Sum',
             value: 'ON',
           })} 
       } else {
         this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
           return (data.category !== 'Show Only Lump Sum');
         });
       }
      // favourite
      if(this.filters.favorites) {
       if(this.filterDetailsListItems.findIndex(ele => ele.category === 'Show Only Starred') === -1){
          this.filterDetailsListItems.push({
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            id: this.filterDetailsListCounter,
            category: 'Show Only Starred',
            value: 'ON',
          })} 
      } else {
        this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
          return (data.category !== 'Show Only Starred');
        });
      }
      // Status
      if (this.filters.movePhases) {
        if (this.filters.movePhases.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return (data.category !== 'Status');
          });
        } else {
        const movePhaseObject = this.filterDetailsListItems.filter(items => items.category === 'Status');
        const removeItems = [];
        movePhaseObject.map(data => {
          const index = this.filters.movePhases.filter((value) => value === data.value);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.movePhases.forEach(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Status');
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Status',
              value: data
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Status',
              value: data
            });
          }
        });
      }
      }

      // Authorization Type 
      if (this.filters.authorizationType) {
        if (this.filters.authorizationType.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return (data.category !== 'Authorization Type');
          });
        } else {
        const authorizationTypeObject = this.filterDetailsListItems.filter(items => items.category === 'Authorization Type');
        const removeItems = [];
        authorizationTypeObject.map(data => {
          const index = this.filters.authorizationType.filter((value) => value === data.value);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.authorizationType.forEach(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Authorization Type');
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Authorization Type',
              value: data
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Authorization Type',
              value: data
            });
          }
        });
      }
      }

      // Departure
      if (this.filters.departure) {
        if (this.filters.departure.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return (data.category !== 'Departure');
          });
        } else {
          const deptCountryObject = this.filterDetailsListItems.filter(items => items.category === 'Departure');
          const removeCountryItems = [];
          deptCountryObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.departure.filter((value) => value.value === data.value);
            if (index.length === 0) {
              removeCountryItems.push(data);
            }
          });
          const filteredCountryItems = this.filterDetailsListItems.filter(item => !removeCountryItems.includes(item));
          this.filterDetailsListItems = filteredCountryItems;
          this.filters.departure.forEach(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data.value && items.category === 'Departure');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data.value,
                type: data.label
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data.value,
                type: data.label
              });
            }
          });
        }
      }

      // Destination
      if (this.filters.destination) {
        if (this.filters.destination.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return (data.category !== 'Destination');
          });
        } else {
          const destCountryObj = this.filterDetailsListItems.filter(items => items.category === 'Destination');
          const removeCountryItems = [];
          destCountryObj.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.destination.filter((value) => value.value === data.value);
            if (index.length === 0) {
              removeCountryItems.push(data);
            }
          });
          const filteredCountryItems = this.filterDetailsListItems.filter(item => !removeCountryItems.includes(item));
          this.filterDetailsListItems = filteredCountryItems;
          this.filters.destination.forEach(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data.value && items.category === 'Destination');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data.value,
                type: data.label
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data.value,
                type: data.label
              });
            }
          });

        }
      }

      // Status Status Status Date Range
      if (this.filters.startDate || this.filters.endDate) {
        const transferStartDate = this.dateConversionService.convertToDisplayDate(this.filters.startDate.toString());
        let dateRange: any, transferEndDate: any;
        if (!this.filters.endDate) {
          this.endDateExist = false;
          dateRange = transferStartDate + ' - ';
        } else {
          this.endDateExist = true;
          transferEndDate = this.dateConversionService.convertToDisplayDate(this.filters.endDate.toString());
          dateRange = transferStartDate + ' - ' + transferEndDate;
        }
        this.filterDetailsListCounter++;
        for (let i = 0; i < this.filterDetailsListItems.length; i++) {
          if (this.filterDetailsListItems[i].category === 'Status Date Range') {
            this.filterDetailsListItems.splice(i, 1);
            break;
          }
        }
        if (this.filterCount > 0 && this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Status Date Range',
            value: dateRange
          });
        } else if (this.filterCount > 0 && !this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Status Date Range',
            value: dateRange,
            infinite: true
          });
        } else if (!this.endDateExist) {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Status Date Range',
            value: dateRange,
            infinite: true
          });
        } else {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Status Date Range',
            value: dateRange
          });
        }
      } else {
        const dateRangeObject = this.filterDetailsListItems.filter(items => items.category === 'Status Date Range');
        const filteredItems = this.filterDetailsListItems.filter(range => !dateRangeObject.includes(range));
        this.filterDetailsListItems = filteredItems;
      }


      // Authorization Date Range
      if (this.filters.authorizationStartDate || this.filters.authorizationEndDate) {
        const transferStartDate = this.dateConversionService.convertToDisplayDate(this.filters.authorizationStartDate.toString());
        let dateRange: any, transferEndDate: any;
        if (!this.filters.authorizationEndDate) {
          this.endDateExist = false;
          dateRange = transferStartDate + ' - ';
        } else {
          this.endDateExist = true;
          transferEndDate = this.dateConversionService.convertToDisplayDate(this.filters.authorizationEndDate.toString());
          dateRange = transferStartDate + ' - ' + transferEndDate;
        }
        this.filterDetailsListCounter++;
        for (let i = 0; i < this.filterDetailsListItems.length; i++) {
          if (this.filterDetailsListItems[i].category === 'Authorization Date Range') {
            this.filterDetailsListItems.splice(i, 1);
            break;
          }
        }
        if (this.filterCount > 0 && this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange
          });
        } else if (this.filterCount > 0 && !this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange,
            infinite: true
          });
        } else if (!this.endDateExist) {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange,
            infinite: true
          });
        } else {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange
          });
        }
      } else {
        const dateRangeObject = this.filterDetailsListItems.filter(items => items.category === 'Authorization Date Range');
        const filteredItems = this.filterDetailsListItems.filter(range => !dateRangeObject.includes(range));
        this.filterDetailsListItems = filteredItems;
      }

    }

    if (this.searchValue && (this.searchValue.length > 2)) {
      filters.searchText = this.searchValue;
    }
    if (this.searchCPValue && (this.searchCPValue.length > 2)) {
      filters.searchText = this.searchCPValue;
    }

    this.filterCount++;
  }

  /** getUpdatedFilterOptions method to get the updated list of filters from filter chip component */
  getUpdatedFilterOptions($event, cp) {
    /*this.userPreferenceService.getPreference('aggregation_filter', false).subscribe((response) => {
      if (response) {
        this.filters = response;
      }
    });*/
    this.filterOptions = $event;
    //favourite
    if(this.filterOptions.category === 'Show Only Starred') {
      delete this.filters.favorites
    }
    if(this.filterOptions.category === 'Show Only Lump Sum') {
      delete this.filters.lumpsum
    }
    // Status
    if (this.filterOptions.category === 'Status') {
      for (let i = 0; i < this.filters.movePhases.length; i++) {
        if (this.filters.movePhases[i] === this.filterOptions.value && this.childObj) {
          this.childObj.paginator.pageIndex = 0;
          this.filters.movePhases.splice(i, 1);
        }
      }
    }

    // Authorization Type
    if (this.filterOptions.category === 'Authorization Type') {
      for (let i = 0; i < this.filters.authorizationType.length; i++) {
        this.filters.authorizationType[i] === 'BenefitsBuilder' ? this.filters.authorizationType[i] = 'Benefits Builder' : null;
        if (this.filters.authorizationType[i] === this.filterOptions.value && this.childObj) {
          this.childObj.paginator.pageIndex = 0;
          this.filters.authorizationType.splice(i, 1);
        }
      }
    }


    // Departure
    if (this.filterOptions.category === 'Departure') {
      //if (this.filterOptions.type === 'country') {
        for (let i = 0; i < this.filters.departure.length; i++) {
          if (this.filters.departure[i].value === this.filterOptions.value) {
            this.filters.departure.splice(i, 1);
          }
        }
      //}
      /*if (this.filterOptions.type === 'state') {
        const index = this.filters.departure[1].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.departure[1].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'city') {
        const index = this.filters.departure[2].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.departure[2].values.splice(index, 1);
        }
      }*/
    }

    // Destination
    if (this.filterOptions.category === 'Destination') {
      //if (this.filterOptions.type === 'country') {
        for (let i = 0; i < this.filters.destination.length; i++) {
          if (this.filters.destination[i].value === this.filterOptions.value) {
            this.filters.destination.splice(i, 1);
          }
        }
      //}
      /*if (this.filterOptions.type === 'state') {
        const index = this.filters.destination[1].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.destination[1].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'city') {
        const index = this.filters.destination[2].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.destination[2].values.splice(index, 1);
        }
      } */
    }

    // Status Status Date Range
    if (this.filterOptions.category === 'Status Date Range') {
      delete this.filters['startDate'];
      delete this.filters['endDate'];
    }

    // Authorization Date Range
    if (this.filterOptions.category === 'Authorization Date Range') {
      delete this.filters['authorizationStartDate'];
      delete this.filters['authorizationEndDate'];
    }

    this.displayChips(this.filters);
    if(this.childObj) {
    this.childObj.pageEvent.pageIndex = 0;
    }
    let aggregationFetchParameter: any = {};
    if (this.searchValue && (this.searchValue.length > 2)) {
      aggregationFetchParameter.searchText = this.searchValue;
    }

    // if (this.searchCPValue && (this.searchCPValue.length > 2)) {
    //   aggregationFetchParameter.searchText = this.searchCPValue;
    // }
     if(this.childObj) {
     aggregationFetchParameter = {
      itemsPerPage: this.childObj.paginator.pageSize,
      pageNumber: this.childObj.pageEvent.pageIndex
    };
  }
    /*if (this.sortDetails) {
      aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
    }*/

    if (this.filters) {
      aggregationFetchParameter.filters = this.filters;
    }
    cp === 'cp'? this.searchCandidates(this.filters, this.searchCPValue, cp): this.searchCandidates(this.filters, this.searchValue, cp);
    //this.searchCandidates(this.filters, this.searchCPValue);
    //this.childObj.applyFilter(this.searchValue);
  }

  /** filterModal method to open the Filter modal popup */
  filterModal(filterData): void {
    this.filterDialogRef = this.dialog.open(ListFilterModalComponent, {
      data: {
        selectedFilters: this.selectedFilters || {
          "movePhases": []
      },
        filterData,
        displayedColumns : this.displayedColumns
      },
      panelClass: 'filterModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.filterDialogRef.afterClosed().subscribe(filters => {
      if (filters) {

        Object.keys(filters).length !== 0 ? (() => {
          this.childObj.paginator.pageIndex = 0;
          this.childObj.pageEvent.pageIndex = 0;
        })() : null;
        this.displayChips(filters);
        this.searchCandidates(filters);
      }
    });
  }

  searchCandidates(filters: SearchFilter, searchText ?: string, cp ?: string) {

    this.selectedFilters = filters;
    const searchObj: any = {};

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const itemList = filters[key];
        if(key === 'lumpsum'){
          searchObj.isLumpSum = filters.lumpsum;
        } else if (key === 'favorites') {
          searchObj.favorites = filters.favorites;
        }else if (key === 'startDate' || key === 'endDate') {
          searchObj.statusDateRange = {
            'startDate': filters.startDate,
            'endDate' : filters.endDate
          }
          }else if (key === 'authorizationType') {
            filters.authorizationType.forEach((val,index) => val === "Benefits Builder" ? filters.authorizationType[index] = "BenefitsBuilder" : null);
            searchObj.authorizationType = filters.authorizationType;
          }else if (key === 'authorizationStartDate' || key === 'authorizationEndDate') {
            searchObj.authorizationDateRange = {
              'startDate': filters.authorizationStartDate,
              'endDate' : filters.authorizationEndDate
            }
        } else if (key !== 'movePhases' && key !== 'startDate' && key !== 'endDate') {
          /**
           * The objective is to create a search object with following structure
           * "departure": { -> searchObj[key]
              "countries": [ -> searchObj[key][item.label]
                "India" -> item.value
              ],
              "states": [],
              "cities": []
            },
           */
          Array.isArray(itemList) ? itemList.map(item => {
            if (searchObj[key] && searchObj[key][item.label]) {
              (searchObj[key][item.label] as Array<string>).push(item.value);
            } else {
              if (typeof searchObj === 'object' && !searchObj.hasOwnProperty(key)) {
                searchObj[key] = {};
              }
              searchObj[key][item.label] = [];
              (searchObj[key][item.label] as Array<string>).push(item.value);
            }
          }) : null;
        } else {
          if(filters.movePhases.length) {
            searchObj.moveStatuses = filters.movePhases;
            // tslint:disable-next-line: no-unused-expression
            searchObj.moveStatuses = searchObj.moveStatuses.map(x => (x === 'Deactivated') ? 'Withdrawn' : x);
          } else {
            searchObj.moveStatuses = ["Benefits Confirmed", "Initial Contact Pending", "Initial Contact Complete","Invitation Not Sent","Authorization Initiated","Invitation Sent","In Progress","In Review","Audit Pending","Audit Complete", "Payment Requested"]
          }
        }
      }
    }
    // console.log(searchObj);
    let colDisplay = [];
    if (this.displayedColumns.length === 0) { this.displayedColumns = this.transfereeColumnsDisplay[0].preferencesetting.value };
    this.transfereeColumnsList.forEach((item) => {
      if (this.displayedColumns.includes(item.field) && item.filter) {
        colDisplay = colDisplay.concat(item.filter);
      }
    });
    if( cp !== 'cp') {

    if (Object.keys(searchObj).length > 0) {
      this.getCandidatesList({
        filters: searchObj,
        searchProperties: colDisplay,
        searchText: this.searchValue || null
      });
    } else {
      this.getCandidatesList({
        searchProperties: colDisplay,
        searchText: this.searchValue || null
      });
    }
    } else {
      if (Object.keys(searchObj).length > 0) {
        this.getCostProjCandidatesList({
          filters: searchObj,
          searchProperties: colDisplay,
          searchText: this.searchCPValue || null
        });
      } else {
        this.getCostProjCandidatesList({
          searchProperties: colDisplay,
          searchText: this.searchCPValue || null
        });
      }
    }
  }

  /**
   * Search for Candidates using search Text
   * @param searchText Search Text string
   */
  textSearchCandidateProfiles(searchText: string) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchCandidates(this.selectedFilters, searchText, 'move');
      this.searchValue = searchText;
      this.searchFilterText = this.searchValue;
    }
  }


  textSearchCandidateCPProfiles(searchText: string) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchCandidates(this.selectedFilters, searchText, 'cp');
      this.searchCPValue = searchText;
      this.searchFilterText = this.searchCPValue;
    }
  }


  /**
   * Search for Clients
   * @param searchText Search Text Value
   */
  textSearchClients(searchText: string) {
    if (searchText && searchText.length >= 3 ) {
        if(this.clientListObj && this.clientListObj.paginator){
          this.clientListObj.paginator.pageIndex = 0;
          this.clientListObj.pageEvent.pageIndex = 0
        }
        this.searchValueForClient = searchText;
        this.searchFilterText = this.searchValue;
        const newOptions = Object.assign({ searchText }, this.currentFilterOptions);
        this.getClientsList(newOptions);
    } else if(searchText.length === 0){
        this.getClientsList();
    }
  }


  textSearchCPClients(searchText: string) {
    if (searchText && searchText.length >= 3 ) {
      if(this.clientListObj && this.clientListObj.paginator){
        this.clientListObj.paginator.pageIndex = 0;
        this.clientListObj.pageEvent.pageIndex = 0
      }
      this.searchCPValue = searchText;
      this.searchFilterText = this.searchCPValue;
      const newOptions = Object.assign({ searchText }, this.currentFilterOptions);
      this.getClientsList(newOptions);
    } else if(searchText.length === 0) {
      this.getClientsList();
    }
  }


  /**
   * Clear Text search
   */
  clearTextSearch(tabLabel) {
    if (tabLabel) {
      this.adminTabLabel = tabLabel
    }
    this.searchValue = '';
    this.searchCPValue= '';
    this.searchFilterText = this.searchValue;
    this.searchFilterText = this.searchCPValue;
    this.searchValueForClient = ''

    if (this.adminTabLabel === 'Client' ) {
      if (this.currentViewType === 'clientList') {
        this.getClientsList();
      } else {
        if (tabLabel === 'Transferees') {
          this.searchCandidates(this.selectedFilters);
        } else if (tabLabel === 'Client Contacts') {
          this.getClientContactsList(this.selectedClientID);
        } else if(tabLabel === 'Cost Projections') {
          this.searchCandidates(this.selectedFilters,'','cp');
        } else {
          //none
        }
      }
    } else if (this.adminTabLabel === 'Unprocessed') {
      this.searchValueForUnprocessed = ''
      this.searchUprocessedValue = ''
    } else if(this.adminTabLabel === 'Compliance'){
      this.searchValueForCompliance = ''
      this.searchCompliantValue = '' 
    }
  }

  /**
   * To deactivate selected transferees from the list
   * @param deactivationList array of order request id's to be deactivated
   */
  deactivateTransferee(deactivationList) {
    let isvalid = true;
    let allowedBBTransfereeStatusForCSM = ['Initial Contact Pending', 'Invitation Sent', 'In Progress'];
    let allowedTradLumpsumTransfereeStatusForCSM = ['Draft', 'Authorization Initiated', 'Invitation Sent', 'In Progress', 'Benefits Confirmed', 'Initial Contact Pending','Initial Contact Complete', 'In Review', 'Audit Pending', 'Audit Complete'];
    let allowedBBTransfereeStatusForCCI = ['Invitation Not Sent', 'Invitation Sent']
    if (deactivationList.length <= 10) {
      if (this.userRole === 'file-setup-specialist') { // For FSU different workflow..
        this.deactivateTransfereeByFSU(deactivationList);
      } else {
        this.deactivationList = [];
        for (const item of deactivationList) {
          const { authorizationType, moveStatus, orderRequestId } = item;
          let message: string;
          if (authorizationType == "Traditional") {
            if(this.userRole === 'team-client-service-manager') {
              if(item.isLumpSum && allowedTradLumpsumTransfereeStatusForCSM.includes(moveStatus))
                this.deactivationList.push(orderRequestId);
              else if(moveStatus === "In Progress")
                this.deactivationList.push(orderRequestId);
            } else if(this.userRole === 'client-contact-initiator' && moveStatus === "Invitation Not Sent") {
              this.deactivationList.push(orderRequestId);
            } else {
              isvalid = false;
              message = deactivateErrorMessage;
              this.displayToastr(message);
              break;
            }
          } else {
            if (this.userRole === 'team-client-service-manager' && allowedBBTransfereeStatusForCSM.includes(moveStatus)) {
              this.deactivationList.push(orderRequestId);
            } else if (this.userRole === 'client-contact-initiator' && allowedBBTransfereeStatusForCCI.includes(moveStatus)) {
              this.deactivationList.push(orderRequestId);
            } else {
              isvalid = false;
              message = deactivateErrorMessage;
              this.displayToastr(message);
              break;
            }
          }
        }
        if (this.deactivationList.length > 0 && isvalid) {
          this.spinner.show();
          const deactivateRequest = {'orderRequestId': this.deactivationList };
          this.candidateService.deactivateTransferee(deactivateRequest).subscribe(
            (response) => {
              if (response) {
                const displayMessage = (deactivationList.length === 1 ? `${deactivationSuccess} ${deactivationList[0].nameDetails.firstName} ${deactivationList[0].nameDetails.lastName}` :
                  deactivationSuccessMutilple);
                this.displayToastr(displayMessage);
                this.candidates = this.updateDeactivatedData(response, this.candidates);
              }
              this.spinner.hide();
            },
            err => {
              this.spinner.hide();
            });
        }
      }
    } else {
      this.displayToastr(deactivationMaxNoMsg);
    }
  }

  /**
   * Deactivation workflow for user type FSU..
   */
  deactivateTransfereeByFSU(deactivationList: Array<any>) {
    if (this.userRole !== 'file-setup-specialist') {
      return false;
    }
    let isValid = true;
    const allowedDeactiveTypes = ['Audit Pending','Audit Complete','In Progress', 'Invitation Not Sent', 'Invitation Sent', 'Authorization Initiated', 'Initial Contact Pending'];
    const allowedDeactivateTypesForTraditional = ['Audit Pending','Audit Complete','Invitation Not Sent','Authorization Initiated', 'Initial Contact Pending'];
    const allowedDeactivateTypesForTraditionalLumpsum = ['Draft', 'Authorization Initiated', 'Invitation Sent', 'In Progress', 'Benefits Confirmed', 'Initial Contact Pending','Initial Contact Complete', 'In Review', 'Audit Pending', 'Audit Complete'];
    this.deactivationList = [];
    for (const order of deactivationList) {
      let message: string;
      if(order.authorizationType == "Traditional" ) {
        if(order.isLumpSum) {
          if(allowedDeactivateTypesForTraditionalLumpsum.includes(order.moveStatus))
            this.deactivationList.push(order.orderRequestId);
          else {
            isValid = false;
            message = deactivateErrorMessage;
            this.displayToastr(message);
            break;
          }
        } else if (!allowedDeactivateTypesForTraditional.includes(order.moveStatus)) {
          isValid = false;
          message = deactivateErrorMessage;
          this.displayToastr(message);
          break;
        }
      } else {
        if (allowedDeactiveTypes.includes(order.moveStatus)) {
          this.deactivationList.push(order.orderRequestId);
        } else {
          isValid = false;
          message = deactivateErrorMessage;
          this.displayToastr(message);
          break;
        }
      }
    }

    if (this.deactivationList.length > 0 && isValid === true) {
      this.spinner.show();
      const deactivateRequest = {'orderRequestId': this.deactivationList };
      this.candidateService.deactivateTransferee(deactivateRequest).subscribe(
        (response) => {
          if (response) {

            const failedItems: Array<any> = response.length > 0 ? response.filter(item => item.status === 'FAILED') : [];

            if (failedItems.length === this.deactivationList.length) {
              this.displayToastr('Transferee deactivation failed. Please try again.');
            }

            if (deactivationList.length === 1 && failedItems.length === 0) {
              this.displayToastr(`${deactivationSuccess} ${deactivationList[0].nameDetails.firstName} ${deactivationList[0].nameDetails.lastName}`);
            }

            if (deactivationList.length > 1 && failedItems.length === 0) {
              this.displayToastr(deactivationSuccessMutilple);
            }

            if (failedItems.length > 0 && failedItems.length < this.deactivationList.length) {
              this.displayToastr(`${failedItems.length} transferee deactivation failed and ${this.deactivationList.length - failedItems.length} transferee deactivation successfully completed.`);
            }

            this.candidates = this.updateDeactivatedData(response, this.candidates);
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        });
    }

  }

  /**
   * To display informative messages on screen
   * @param message message to be displayed
   */
  displayToastr(message) {
    this.toastrService.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false
    });
  }

  /**
   * To update table with move status and move status date
   * @param deactivationResponse Response from API
   * @param existingCandidateList Existing list of transferees to be updated
   */
  updateDeactivatedData(deactivationResponse: Array < any > , existingCandidateList) {
    if(deactivationResponse && deactivationResponse.length > 0) {

      deactivationResponse.forEach(element => {
        existingCandidateList.map(i => {
          if (i.orderRequestId === element._id) {
            i.moveStatus = element.moveStatus;
            i.moveStatusDate = element.moveStatusDate;
          }
        });
      });

      return existingCandidateList;
    }
  }

  /**
   * To open selected tab based on links clicked by user
   * @param data contains clicked element details and type of page to be redirected to
   */
  openSelectedList(data: {
    client: AdminClient,
    type: 'clientName' | 'clientContact' | 'candidates' | 'programs'
  }) {
    this.currentFilterOptions = {};

    this.spinner.show();
    this.clientDetails = data.client;

    // Load Candidates List
    if(this.authorizationListCapability === 'read' || this.authorizationListCapability ==='write'){
     this.getCandidatesList();
    }
    this.getCostProjCandidatesList();

    // Load Client Contacts List
    this.selectedClientID = data.client.clientId;
    this.getClientContactsList(data.client.clientId, null);

    this.currentViewType = 'transfereeList';
    if (data.type === 'clientContact') {
      this.selectedTabIndex = 2; // set client contact tab to active
    } else if (data.type === 'programs'){
      this.selectedTabIndex = 3; // set programs to active
    } else{
      this.selectedTabIndex = 0; // set transferee tab to active
    }
    // Clear searchText value
    this.searchValue = '';
    this.searchCPValue = '';
  }

  /**
   * Will decide based on the selectedTabIndex which Search Function to call.
   * Only Valid for (currentViewType === 'transfereeList')
   * @param searchText Search Test String
   */
  textSearchList(searchText: string,tabLabel?) {
    if(this.childObj && this.childObj.paginator){
    this.childObj.paginator.pageIndex = 0;
    this.childObj.pageEvent.pageIndex = 0
    }
    if (tabLabel === 'Transferees') {
      this.textSearchCandidateProfiles(searchText);
    } else if (tabLabel === 'Client Contacts') {
      this.textSearchClientContacts(searchText);
    }  else if(tabLabel === 'Cost Projections' ){
      this.textSearchCandidateCPProfiles(searchText);


    }
    else{
         // do nothing
    }
  }

  searchCompliantList(eve) {
    if(eve) {
      if (eve.length >= 3) {
        this.searchCompliantValue = eve.trim()
      }
    } else {
      this.searchCompliantValue =''
    }
  }

  searchUnprocessedList(eve){ 
    if(eve) {
      if (eve.length >= 3) {
        this.searchUprocessedValue = eve.trim()
      }
    } else {
      this.searchUprocessedValue =''
    }
  }

  textSearch(event) {
    if ( event.key === 'Backspace' && event.target.value.length === 0 ) {
      if (event.target.value.toLowerCase() == "benefits builder"){
        this.textSearchList("BenefitsBuilder");
      }else
      this.textSearchList(event.target.value);
    }
  }



  getTransfereeDetailView(event) {
    this.transfereeDetailView = event;
  }

  /** dataview selection method to open modal popup */
  dataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(AdminDataviewSelectionModalComponent, {
      data: {
        dataKey : this.transfereeListDataView
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumn => {
      if (displayedColumn && displayedColumn.length > 0) {
        this.displayedColumns = [...displayedColumn];
        this.showHideFilterOptions(this.displayedColumns);
        this.childObj.updateDisplayedColumn(this.displayedColumns);
      }

    });
  }

  /** show hide display chip and filter options */
  showHideFilterOptions(displayCol) {
    if (!displayCol.includes('status')) {
        this.filters.movePhase = [];
    }

    // Departure
    if (!displayCol.includes('departue')) {
      this.filters.departure = [];
    }

    // Destination
    if (!displayCol.includes('destination')) {
      this.filters.destination = [];
    }

    // Effective Transfer Status Status Date Range
    /*if (!displayCol.includes('effectiveTransferDate')) {
      this.filters.transferStartDate = null;
      this.filters.transferEndDate = null;
      this.sortDetails = [];
    }

    // Total Cost Range
    if (!displayCol.includes('totalCost')) {
      this.filters.minTotalCost = null;
      this.filters.maxTotalCost = null;
      this.sortDetails = [];
    }*/

    this.displayChips(this.filters);
    this.searchCandidates(this.filters);
  }


  /** Clearing all filters for the last Selected Tab */
  clearExistingFilters(event) {
    this.authTabLabel = event?.tab?.textLabel
    if (!this.viewProgramDetailFlag) {
      this.selectedTabIndex = event.index;
    }
    if(event.index === 0) {
      this.selectedTabIndex = event.index;
    }
    this.currentFilterOptions = {};
    this.searchValue = '';
    this.searchCPValue = '';
  }

  close() {
    this.currentViewType = 'clientList';
    this.currentFilterOptions = {};
    this.searchValue = '';
    this.searchCPValue = '';
    this.filterDetailsListItems = [];
    this.selectedFilters = null;
    this.displayedColumns = [...this.transfereeColumnsDisplay[0].preferenceconfig.default];
    this.candidates = [];
    this.costCandidates = []
    this.candidatesCount = 0;
    this.costCandidatesCount = 0;
  }

  callSearchFilter(pageObj:any) {
    this.searchCandidates(this.filters,pageObj.searchText)
  }

  closeFilterChips(){
    this.filterDetailsListItems=[];
    this.selectedFilters && this.selectedFilters.movePhases ? this.selectedFilters.movePhases = [] : this.selectedFilters = {};
  }

getCostProjCandidatesList(options ?: {
    sortBy ?: string,
    sortDirection ?: string,
    searchProperties?: any[],
    searchText?: string,
    filters ?: any,
    itemsPerPage?: number,
    pageNumber?:number
}): void {

  if (!options) {
    options = {
      itemsPerPage: 75,
      pageNumber: 0
    };
  }

  if (this.userRole !== 'program-admin') {
    this.spinner.show();
    if (options && options.filters) {
      if (!options.searchText) {
        delete options.searchText;
      }
      this.candidateService.searchCandidateProfiles(options, this.clientDetails, 'cost-projection').subscribe(data => {
        if (data) {
          this.costCandidates = data.candidates;
          this.costCandidatesCount = data.count;
        } else {
          this.costCandidates = [];
          this.costCandidatesCount = 0;
        }
        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      }, () => {
        this.costCandidates = [];
        this.costCandidatesCount = 0;
        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      });
    }
    else{
      this.candidateService.getCandidateProfiles(options, this.clientDetails, 'cost-projection').subscribe(data => {
        if (data) {
          this.costCandidates = data.candidates;
          this.costCandidatesCount = data.count;
        } else {
          this.costCandidates = [];
          this.costCandidatesCount = 0;
        }

        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      }, () => {
        this.costCandidates = [];
        this.costCandidatesCount = 0;
        if (this.selectedTabIndex === 0) { this.spinner.hide() };
      });
    }
  }
}

checkRole() {
  let clientPartyId;
  if (this.userRole === 'client-contact-initiator') {
    clientPartyId = sessionStorage.getItem("cc_clientPartyId");
  }
  clientPartyId ? this.clientService.getBbRole(clientPartyId).subscribe(res => {
    if (res) {
      res.loginUserRoleCapabilities.length ?
        res.loginUserRoleCapabilities.forEach(element => {
          if (element.clientRoles[0].benefitsBuilder) {
            this.clientService.bbRole.push('benefitsBuilder');
          }
          if (element.clientRoles[0].traditionalAuthorization) {
            this.clientService.bbRole.push('traditionalAuthorization');
          }
          if (element.clientRoles[0].costProjection) {
            this.clientService.bbRole.push('costProjection');
          }
          if (element.clientRoles[0].sso){
            this.clientService.bbRole.push('sso');
            this.clientService.ssoRegEx = {
              'ssoRegex': element.clientRoles[0].ssoRegex,
              'ssoRegexErrorMessage': element.clientRoles[0].ssoRegexErrorMessage
            }}
          if (element.clientRoles[0].pointsMatrix ) {
            this.clientService.bbRole.push('pointsMatrix');
          }
          if (element.clientRoles[0].managedMove ) {
            this.clientService.bbRole.push('managedMove');
          }
          if (element.clientRoles[0]?.lumpSum?.lumpSumEnabled) {
            this.clientService.bbRole.push('lumpSum');
            this.clientService.traditionalRole.push('lumpSum');
          }
        }) : null;
    }
  }, err => {
    console.log(err);
  }) : null;
}

  tabClick(event) {
    this.adminTabLabel = event?.tab?.textLabel
    this.adminTabIndex = event.index;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if(event.target.location.hash === '#/administration') {
      this.close();
    }
  }

  OpenAdminFilter(): void {
    this.adminFilterDialogRef = this.dialog.open(AdminFilterModalComponent,{
      panelClass: 'dialog-full-width',
      autoFocus: false,
      restoreFocus: false,
      data: {
        selectedTabLabel: this.adminTabLabel,
        selectedAdminFilters: this.getFiltersForTabs()
      },
    })
    this.adminFilterDialogRef.afterClosed().subscribe(filters => {
      if (filters) {
        this.setFiltersForTabs(filters);
        this.displayAdminFilterChips(filters);
      }
    });
  }

  setFiltersForTabs (filters) {
    if (this.adminTabLabel === 'Unprocessed') {
      this.unprocessedFilters = {...filters};
      this.selectedUnprocessedFiltersWithCategories = this.displayAdminFilterChips(filters);
    } else if (this.adminTabLabel === 'Compliance') {
      this.complianceFilters = {...filters};
      this.selectedComplianceFiltersWithCategories = this.displayAdminFilterChips(filters);
    }
  }

  getFiltersForTabs () {
    if (this.adminTabLabel === 'Unprocessed') {
      return this.unprocessedFilters;
    } else if (this.adminTabLabel === 'Compliance') {
      return this.complianceFilters;
    }
  }

  getFeatureFlag() {
    this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
      (res) => {
        const featureFlags: FeatureFlag[] = res.items;
        for(let featureFlag of featureFlags) {
          if (featureFlag.key === 'Is_unprocessed_tab_enabled') { this.isUnprocessedTabEnabled = featureFlag.on };
          if (featureFlag.key === 'is_compliance_tab_enabled') { this.isComplianceTabEnabled = featureFlag.on };    
        }
      }
    )
  }

  displayAdminFilterChips(filters) {
    const selectedFilters = {...filters}
    let selectedAdminFiltersWithCategories: any [] = [];
    let unprocessedFilterDetailsListCounter: number = 0;
    let unprocessedfilterCount: number = 0;
    if(selectedFilters) {
      // Status
      if (selectedFilters.status) {
        if (selectedFilters.status.length === 0) {
          selectedAdminFiltersWithCategories = selectedAdminFiltersWithCategories.filter(data => {
            return (data.category !== 'Status');
          });
        } else {
          const statusPhaseObject = selectedAdminFiltersWithCategories.filter(items => items.category === 'Status');
          const removeItems = [];
          statusPhaseObject.forEach(data => {
            const index = selectedFilters.status.filter((value) => value === data.value);
            if (index.length === 0) {
              removeItems.push(data);
            }
          });
          const filteredItems = selectedAdminFiltersWithCategories.filter(item => !removeItems.includes(item));
          selectedAdminFiltersWithCategories = filteredItems;
          selectedFilters.status.forEach(data => {
            let checkFilter = true;
            const filtersCount = selectedAdminFiltersWithCategories.filter(items => items.value === data && items.category === 'Status');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            unprocessedFilterDetailsListCounter++;
            if (unprocessedfilterCount > 0 && checkFilter) {
              selectedAdminFiltersWithCategories.unshift({
                id: unprocessedFilterDetailsListCounter,
                category: 'Status',
                value: data
              });
            } else if (checkFilter) {
              selectedAdminFiltersWithCategories.push({
                id: unprocessedFilterDetailsListCounter,
                category: 'Status',
                value: data
              });
            }
          });
        }
      }

      // clients
      if (selectedFilters.clients) {
        if (selectedFilters.clients.length === 0) {
          selectedAdminFiltersWithCategories = selectedAdminFiltersWithCategories.filter(data => {
            return (data.category !== 'Client');
          });
        } else {
          const clientObject = selectedAdminFiltersWithCategories.filter(items => items.category === 'Client');
          const removeItems = [];
          clientObject.forEach(data => {
            const index = selectedFilters.clients.filter((value) => value === data.value);
            if (index.length === 0) {
              removeItems.push(data);
            }
          });
          const filteredItems = selectedAdminFiltersWithCategories.filter(item => !removeItems.includes(item));
          selectedAdminFiltersWithCategories = filteredItems;
          selectedFilters.clients.forEach(data => {
            let checkFilter = true;
            const filtersCount = selectedAdminFiltersWithCategories.filter(items => items.value === data && items.category === 'Client');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            unprocessedFilterDetailsListCounter++;
            if (unprocessedfilterCount > 0 && checkFilter) {
              selectedAdminFiltersWithCategories.unshift({
                id: unprocessedFilterDetailsListCounter,
                category: 'Client',
                value: `${data.clientEntityName} (Client #:${data.clientNumber})`
              });
            } else if (checkFilter) {
              selectedAdminFiltersWithCategories.push({
                id: unprocessedFilterDetailsListCounter,
                category: 'Client',
                value: `${data.clientEntityName} (Client #:${data.clientNumber})`
              });
            }
          });
        }
      }
      
      // Date Range
      if (selectedFilters.authorizationDateRange && (selectedFilters.authorizationDateRange.startDate || selectedFilters.authorizationDateRange.endDate)) {
        const authorizationStartDate = this.dateConversionService.convertToDisplayDate(selectedFilters.authorizationDateRange.startDate.toString());
        let dateRange: any, authorizationEndDate: any;
        if (!selectedFilters.authorizationDateRange.endDate) {
          this.endDateExist = false;
          dateRange = authorizationStartDate + ' - ';
        } else {
          this.endDateExist = true;
          authorizationEndDate = this.dateConversionService.convertToDisplayDate(selectedFilters.authorizationDateRange.endDate.toString());
          dateRange = authorizationStartDate + ' - ' + authorizationEndDate;
        }
        unprocessedFilterDetailsListCounter++;
        for (let i = 0; i < selectedAdminFiltersWithCategories.length; i++) {
          if (selectedAdminFiltersWithCategories[i].category === 'Authorization Date Range') {
            selectedAdminFiltersWithCategories.splice(i, 1);
            break;
          }
        }
        if (unprocessedfilterCount > 0 && this.endDateExist) {
          selectedAdminFiltersWithCategories.unshift({
            id: unprocessedFilterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange
          });
        } else if (unprocessedfilterCount > 0 && !this.endDateExist) {
          selectedAdminFiltersWithCategories.unshift({
            id: unprocessedFilterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange,
            infinite: true
          });
        } else if (!this.endDateExist) {
          selectedAdminFiltersWithCategories.push({
            id: unprocessedFilterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange,
            infinite: true
          });
        } else {
          selectedAdminFiltersWithCategories.push({
            id: unprocessedFilterDetailsListCounter,
            category: 'Authorization Date Range',
            value: dateRange
          });
        }
      } else {
        const dateRangeObject = selectedAdminFiltersWithCategories.filter(items => items.category === 'Authorization Date Range');
        const filteredItems = selectedAdminFiltersWithCategories.filter(range => !dateRangeObject.includes(range));
        selectedAdminFiltersWithCategories = filteredItems;
      }

      // Authorization Type
      if (selectedFilters.authorizationType) {
        if (selectedFilters.authorizationType.length === 0) {
          selectedAdminFiltersWithCategories = selectedAdminFiltersWithCategories.filter(data => {
            return (data.category !== 'Authorization Type');
          });
        } else {
          const statusPhaseObject = selectedAdminFiltersWithCategories.filter(items => items.category === 'Authorization Type');
          const removeItems = [];
          statusPhaseObject.forEach(data => {
            const index = selectedFilters.authorizationType.filter((value) => value === data.value);
            if (index.length === 0) {
              removeItems.push(data);
            }
          });
          const filteredItems = selectedAdminFiltersWithCategories.filter(item => !removeItems.includes(item));
          selectedAdminFiltersWithCategories = filteredItems;
          selectedFilters.authorizationType.forEach(data => {
            let checkFilter = true;
            const filtersCount = selectedAdminFiltersWithCategories.filter(items => items.value === data && items.category === 'Authorization Type');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            unprocessedFilterDetailsListCounter++;
            if (unprocessedfilterCount > 0 && checkFilter) {
              selectedAdminFiltersWithCategories.unshift({
                id: unprocessedFilterDetailsListCounter,
                category: 'Authorization Type',
                value: data
              });
            } else if (checkFilter) {
              selectedAdminFiltersWithCategories.push({
                id: unprocessedFilterDetailsListCounter,
                category: 'Authorization Type',
                value: data
              });
            }
          });
        }
      }
    }
    unprocessedfilterCount++;
    return selectedAdminFiltersWithCategories;
  }

  onAdminChipDelete($event, filters) {
    /** adminFilterOptions variable to store the updated unprocessed filter list */
    let adminFilterOptions: any;
    adminFilterOptions = $event;
    const selectedAdminFilters = {...filters}
    // Status
    if (adminFilterOptions.category === 'Status') {
      for (let i = 0; i < selectedAdminFilters.status.length; i++) {
        if (selectedAdminFilters.status[i] === adminFilterOptions.value) {
          selectedAdminFilters.status.splice(i, 1);
        }
      }
    }  

    // Clients
    if (adminFilterOptions.category === 'Client') {
        for (let i = 0; i < selectedAdminFilters.clients.length; i++) {
          if (adminFilterOptions.value.includes(selectedAdminFilters.clients[i].clientEntityName)) {
            selectedAdminFilters.clients.splice(i, 1);
          }
        }
    }

    // Auth Date Range
    if (adminFilterOptions.category === 'Authorization Date Range') {
      delete selectedAdminFilters.authorizationDateRange;
    }

    // Status
    if (adminFilterOptions.category === 'Authorization Type') {
      for (let i = 0; i < selectedAdminFilters.authorizationType.length; i++) {
        if (selectedAdminFilters.authorizationType[i] === adminFilterOptions.value) {
          selectedAdminFilters.authorizationType.splice(i, 1);
        }
      }
    }
    
    return selectedAdminFilters;  
  }

  getAdminFilterOptions($event, filters) {
    if (this.adminTabLabel === 'Unprocessed') {
      return this.unprocessedFilters = this.onAdminChipDelete($event, filters);
    } else if (this.adminTabLabel === 'Compliance') {
      return this.complianceFilters = this.onAdminChipDelete($event, filters);
    }
  }

  ngOnDestroy(): void {
    if (this.capabilitySubs) { this.capabilitySubs.unsubscribe() };
  }

}