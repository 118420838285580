<div class="dialog-container" role="region">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
        fxLayoutGap.xs="0em" role="heading">
        <span aria-label="program" matTooltip="program">{{data.program ? 'Edit' : 'Add New'}} Program</span>
        <a class="close-icon" tabindex="0" aria-label="close" (click)="onDismiss($event)"
             title="close">
            <mat-icon>close</mat-icon>
        </a>

    </div>

    <mat-dialog-content style="min-width: 550px;max-width: 640px;display: block;">
        <div fxLayout="row" class="middle-section">
            <div  fxFlex="100%" class="form-container">
                <div *ngIf="isLumpSumEnabled && enableLumpSumOption && enableBBOption" class="program-type-radio-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                    <mat-radio-group ngDefaultControl [disabled]="data.hasOwnProperty('program')"  [(ngModel)]="selectedProgramType" (change)="programTypeChange($event)">
                        <mat-radio-button value="benefitsBuilder" style="margin-right: 15px;" [checked]="selectedProgramType == 'benefitsBuilder'">Benefits Builder</mat-radio-button> 
                        <mat-radio-button value="traditional"  [checked]="selectedProgramType == 'traditional'">Traditional</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div>
                    <form *ngIf="selectedProgramType == 'benefitsBuilder' || !isLumpSum" fxLayout="column" class="middle-section" [formGroup]="programForm">
                        <!--First Name field-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Name</mat-label>
                                <input matInput placeholder="Program Name" [readonly] = "data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false" formControlName="programName" role="text">
                            </mat-form-field>
                        </div>
                        <!-- Program Template -->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Template</mat-label>
                                <mat-select formControlName="templateName"[disabled] = "data.program" >
                                    <mat-option [value]="program.name" *ngFor="let program of programList" style="font-size: 14px;">
                                        {{ program.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="5px">
                        <!-- Points -->
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" *ngIf="(!isMMUser && !isGOUser) && !isLumpSum">
                            <mat-form-field>
                                <mat-label>Total Points for Assigned Transferees</mat-label>
                                <input matInput number-only placeholder="Total Points for Assigned Transferees"  [readonly] = "data.program && !isDrafted && !isActive || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)"  formControlName="totalPoints" role="none" aria-label="Total Points for Assigned Transferees">
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" *ngIf="isGOUser  && !isLumpSum">
                            <!-- <mat-form-field>
                                <mat-label>Custom Points</mat-label>
                                <input matInput number-only placeholder="Custom Points"  [readonly] = "data.program && !isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false) || isGOUser"   role="none" aria-label="Custom Points">
                            </mat-form-field> -->
                            <mat-form-field>
                                <mat-label>Points/ Value Allocation Type</mat-label>
                                <mat-select formControlName="pointsValueAllocationType">
                                    <mat-option *ngFor="let appType of pointsValueAllocationType" [value]="appType" style="font-size: 14px;">
                                        {{appType}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="total-budget" fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="70%" role="none" *ngIf="isMMUser  && !isLumpSum">
                            <mat-form-field>
                                <mat-label>Total Budget</mat-label>
                                <input matInput number-only placeholder="Total Budget" [readonly] = "data.program && (!isDrafted && !data.program.isNew)|| (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)"  formControlName="totalAmount" role="none" aria-label="Total Budget">
                            </mat-form-field>
                        </div>
                        <div class="currency" fxFlex="14%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="30%" role="none" *ngIf="isMMUser  && !isLumpSum">
                            <mat-form-field>
                                <mat-label>Currency</mat-label>
                                <input type="text" character-only
                                       placeholder="Currency"
                                       aria-label="Currency" maxlength="3"
                                       formControlName="currency"
                                       matInput [(ngModel)]="currencyValue" (input)="currencyChange()"
                                       [matAutocomplete]="auto"
                                       [readonly] = "data.program && (!isDrafted && !data.program.isNew) || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)">
                                <mat-autocomplete #auto="matAutocomplete" class="single-autocomplete" (optionSelected)='currencyOption($event.option.value)'>
                                  <mat-option *ngFor="let curr of filteredOptions | async" [value]="curr">
                                    {{curr}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                        </div>
                        <!-- Program Expiration Date-->
                        <div fxFlex="50%" *ngIf = "userRole === 'program-admin' || userRole === 'product-mgmt-admin'" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Expiration (Optional)</mat-label>
                                <input matInput [min]="minDate" aria-label="Program Expiration Date" 
                                [matDatepicker]="programExpirationDate" formControlName="programExpirationDate"
                                (keypress) = "$event.charCode == 8 || $event.charCode == 46">
                                <mat-datepicker-toggle  tabindex="0" aria-label="Program Expiration Date"  matSuffix [for]="programExpirationDate" matTooltip="Calendar"></mat-datepicker-toggle>
                                <mat-datepicker #programExpirationDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        </div>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Base Contract </mat-label>
                                <mat-select formControlName="contractSeries" multiple #contractSelect
                                    (openedChange)="onDropdownClosed($event)"
                                    [readonly]="data.program && !isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.contractSeries)"
                                    aria-label="Contract Series" role="text">
                                    <mat-option (onSelectionChange)="checkForInActive($event)" *ngFor="let contract of contractSeriesList; let i = index" [value]="contract" #matOption [disabled]="checkForExistBaseContract(contract) && matOption.selected && isActive" >
                                        {{contract}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-chip-list aria-label="contract series" >
                            <div *ngFor="let series of programForm.controls.contractSeries.value; let i = index">
                                <mat-chip  class="contract-chip" [removable]="true"
                                    (removed)="onSeriesRemoved(series)" [disabled]="data?.program?.contractSeries && data?.program?.contractSeries[i] && isActive">
                                    {{ series }}
                                <mat-icon tabindex="0" aria-label="cancel contract series" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-chip-list>
            
                        <div formArrayName="atlasPolicy">
                            <div *ngFor="let policy of programForm.get('atlasPolicy')['controls']; let i = index">
                                <div [formGroupName]='i' (click)="getFilteredPolicies(i)">
                                    <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                                        <div fxFlex="93%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                            <mat-form-field>
                                                <mat-label>Atlas Policy</mat-label>
                                                <mat-select formControlName="atlasPolicyName"
                                                aria-label="Atlas Policy"
                                                (keyup)="getFilteredPolicies(i) "
                                                (keydown.enter)="getFilteredPolicies(i)"
                                                [disabled]="data.program && !isDrafted && !isActive && !data.program?.isNew || data?.program?.atlasPolicy && data?.program?.atlasPolicy[i] && isActive || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.atlasPolicy)">
                                                    <mat-option [value]="policy" *ngFor="let policy of clientPolicies[i] ">
                                                        {{ policy }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="7%" tabindex="0" aria-label="Remove Policy" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                            <button mat-icon-button (click)="removePolicy(i)" (keydown.enter)="removePolicy(i)"
                                                [ngClass]="this.programForm.controls.atlasPolicy.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                [disabled]="this.programForm.controls.atlasPolicy.length === 1 || data?.program?.atlasPolicy && data?.program?.atlasPolicy[i] && isActive ">
                                                <mat-icon  class="delete_outline">delete_outline</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button mat-button class="text-button main-btn" (click)="addAtlasPolicy()">+ Add </button>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">    
                            <div fxLayout = "column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Policy Call Confirm" (change)="setCallDurationValidator($event.checked)" [disabled] = "data.program && !isDrafted && !isActive || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)" formControlName = "policyCallRequired">Policy Call Confirm</mat-slide-toggle>
                                <span class="policyCallRequired">{{programForm.value.policyCallRequired ? 'Policy Call Required' : 'Policy Call Not Required'}}</span>
                            </div>
                            
                            <div fxLayout="column" fxFlex="50%">
                                <mat-form-field>
                                    <mat-label>Policy Call Duration </mat-label>
                                    <mat-select formControlName="policyCallDuration"
                                        [readonly]="data.program && !isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.contractSeries)"
                                        aria-label="Policy call duration Series" role="text">
                                        <mat-option *ngFor="let duration of policyCallDurationList" [value]="duration">
                                            {{duration}} min
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>                
                        </div>
                        <!--Initial contact required-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">
                            <div fxLayout="column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Initial Contact Required"
                                    formControlName="initialContactRequired" (change)="onChange()">Initial Contact Required</mat-slide-toggle>
                                <span class="initialContactRequired">{{programForm.value.initialContactRequired ? 'Required for Portal Access' : 'Not Required for Portal Access'}}</span>
                            </div>
                        
                            <div fxLayout="column" fxFlex="50%" *ngIf="this.programForm.value.initialContactRequired">
                                <mat-form-field>
                                    <mat-label>Initial Contact Required By</mat-label>
                                    <mat-select formControlName="initialContactBy"role="text">
                                        <mat-option *ngFor="let initialContact of initialContactRequired" [value]="initialContact">
                                            {{initialContact}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">                
                            <div fxLayout = "column" fxFlex="50%">
                                <mat-slide-toggle  aria-label="Cartus Insured Shipping" [disabled] = "(data.program && !isDrafted) || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)" formControlName = "isCartusInsured">Cartus Insured Shipping</mat-slide-toggle>
                                <span class="policyCallRequired">{{programForm.value.isCartusInsured ? 'Insured by Cartus' : 'Not Insured by Cartus'}}</span>
                            </div>
                        </div>   
                        <!--DEI-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px" class="margin-dei">
                            <div fxLayout="column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Diversity, Equity & Inclusion"
                                    formControlName="isDEIEnabled">Diversity, Equity & Inclusion</mat-slide-toggle>
                            </div>
                        
                            <div fxLayout="column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Sustainability"
                                    formControlName="isSustainabilityEnabled">Sustainability</mat-slide-toggle>
                            </div>
                        </div>    
                    </form>
                    <form *ngIf="isLumpSumEnabled && (selectedProgramType == 'traditional'  || isLumpSum)" fxLayout="column" class="middle-section" [formGroup]="programForm">
                        <!--Program Name field-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Name</mat-label>
                                <input matInput placeholder="Program Name" [readonly] = "data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false" formControlName="programName" role="text">
                            </mat-form-field>
                        </div>
                        <!-- Program Template -->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Template</mat-label>
                                <mat-select formControlName="templateName"[disabled] = "data.program" >
                                    <mat-option [value]="program.name" *ngFor="let program of programList" style="font-size: 14px;">
                                        {{ program.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- Program Expiration Date-->
                        <div fxFlex="100%" *ngIf = "userRole === 'program-admin' || userRole === 'product-mgmt-admin'" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Program Expiration (Optional)</mat-label>
                                <input matInput [min]="minDate" aria-label="Program Expiration Date" 
                                [matDatepicker]="programExpirationDate" formControlName="programExpirationDate"
                                (keypress) = "$event.charCode == 8 || $event.charCode == 46">
                                <mat-datepicker-toggle  tabindex="0" aria-label="Program Expiration Date"  matSuffix [for]="programExpirationDate" matTooltip="Calendar"></mat-datepicker-toggle>
                                <mat-datepicker #programExpirationDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <mat-form-field>
                                <mat-label>Base Contract </mat-label>
                                <mat-select formControlName="contractSeries" multiple #contractSelect
                                    (openedChange)="onDropdownClosed($event)"
                                    [readonly]="data.program && !isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.contractSeries)"
                                    aria-label="Contract Series" role="text">
                                    <mat-option (onSelectionChange)="checkForInActive($event)" *ngFor="let contract of contractSeriesList; let i = index" [value]="contract" #matOption [disabled]="checkForExistBaseContract(contract) && matOption.selected && isActive" >
                                        {{contract}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-chip-list aria-label="contract series" >
                            <div *ngFor="let series of programForm.controls.contractSeries.value; let i = index">
                                <mat-chip  class="contract-chip" [removable]="true"
                                    (removed)="onSeriesRemoved(series)" [disabled]="data?.program?.contractSeries && data?.program?.contractSeries[i] && isActive">
                                    {{ series }}
                                <mat-icon tabindex="0" aria-label="cancel contract series" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-chip-list>
            
                        <div formArrayName="atlasPolicy">
                            <div *ngFor="let policy of programForm.get('atlasPolicy')['controls']; let i = index">
                                <div [formGroupName]='i' (click)="getFilteredPolicies(i)">
                                    <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                                        <div fxFlex="93%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                            <mat-form-field>
                                                <mat-label>Atlas Policy</mat-label>
                                                <mat-select formControlName="atlasPolicyName"
                                                aria-label="Atlas Policy"
                                                (keyup)="getFilteredPolicies(i) "
                                                (keydown.enter)="getFilteredPolicies(i)"
                                                [disabled]="data.program && !isDrafted && !isActive && !data.program?.isNew || data?.program?.atlasPolicy && data?.program?.atlasPolicy[i] && isActive || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.atlasPolicy)">
                                                    <mat-option [value]="policy" *ngFor="let policy of clientPolicies[i] ">
                                                        {{ policy }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="7%" tabindex="0" aria-label="Remove Policy" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                            <button mat-icon-button (click)="removePolicy(i)" (keydown.enter)="removePolicy(i)"
                                                [ngClass]="this.programForm.controls.atlasPolicy.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                [disabled]="this.programForm.controls.atlasPolicy.length === 1 || data?.program?.atlasPolicy && data?.program?.atlasPolicy[i] && isActive ">
                                                <mat-icon  class="delete_outline">delete_outline</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button mat-button class="text-button main-btn" (click)="addAtlasPolicy()">+ Add </button>
                        <!--Initial contact required-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">
                            <div fxLayout="column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Initial Contact Required"
                                    formControlName="initialContactRequired" (change)="onChange()">Initial Contact Required</mat-slide-toggle>
                                <span class="initialContactRequired">{{programForm.value.initialContactRequired ? 'Required for Portal Access' : 'Not Required for Portal Access'}}</span>
                            </div>
                        
                            <div fxLayout="column" fxFlex="50%" *ngIf="this.programForm.value.initialContactRequired">
                                <mat-form-field>
                                    <mat-label>Initial Contact Required By</mat-label>
                                    <mat-select formControlName="initialContactBy"role="text">
                                        <mat-option *ngFor="let initialContact of initialContactRequired" [value]="initialContact">
                                            {{initialContact}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <!--Initial Audit required-->
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">
                            <div fxLayout="column" fxFlex="50%">
                                <mat-slide-toggle aria-label="Initial Audit Required"
                                    formControlName="initialAuditRequired" checked="true" disabled>Initial Audit Required</mat-slide-toggle>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button type="button" class="mat-button text-button" (click)="onDismiss($event)" color="primary" role="button" aria-label="Cancel and Close Modal">CANCEL</button>
        <button [disabled]="programForm.invalid || (this.programForm.value.initialContactRequired && !this.programForm.get('initialContactBy').value)" class="mat-button contained-button" (click)="submitProgramForm()" role="button" aria-label="Proceed to Next screen in Program Creation">{{data.program? 'Save Changes': 'Next'}}</button>
    </mat-dialog-actions>

</div>