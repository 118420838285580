import { Component, OnInit, Input, EventEmitter, Output, Inject, OnDestroy, ViewChild } from '@angular/core';
import { candidateProfile } from './../../../../../core/models/candidate.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserTypeService, UserType } from './../../../services/user-type.service';
import { CandidateProfileService } from './../../../services/candidate-profile.service';
//import {ConfigurableFieldsService } from './../../../services/configurable-fields.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, TitleCasePipe, ɵNullViewportScroller } from '@angular/common';
import { AppConfigService } from './../../../../../core/services/app-config.service';
import { ComponentPortal, ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UnconfirmBenefitsModalComponent } from '../components/unconfirm-benefits-modal/unconfirm-benefits-modal.component';
import { UnlockSubmittedHighValueGoodsModalComponent } from '../components/unlock-submitted-high-value-goods-modal/unlock-submitted-high-value-goods-modal.component';
import { formatNumber } from '@angular/common';
import { AdminClient } from './../../../../../core/models/admin-client.model';
import { ProgramHvgReportGenerationService } from '../../../services/program-hvg-report-generation.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { formatDate } from '@angular/common';
import { TransfereeBenefitSelectionReportGenService } from '../../../services/transferee-benefit-selection-report-gen.service';
import { LocationsService } from '../../../services/locations.service';
import { AddEditTransfereeComponent } from '../../../components/add-edit-transferee/add-edit-transferee.component';
import { MatChip } from '@angular/material/chips';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { UserPreferencesService } from './../../../../../core/services/user-preferences.service';
import { UserConfig } from './../../../../../core/models/user-config.model';
import { UserPreference } from './../../../../../core/models/user-preference.model';
import { LinkedListData } from '../../../../../core/models/linked-list-data.model';
import { Milestones } from '../../../../../core/models/milestones.model';
import { DateConversionService } from './../../../../../core/services/date-conversion.service';
import { Sentiment } from '../../../../../core/models/sentiment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MilestoneService } from './../../../../../core/services/milestone.service';
import { ProviderService } from './../../../../../core/services/provider.service';
import { SentimentService } from './../../../../../core/services/sentiment.service';
import { Sentiments } from './../../../../../core/models/sentiments.model';
import { ScheduledPolicyCallModalComponent } from '../components/scheduled-policy-call-modal/scheduled-policy-call-modal.component';
import { MovePhaseService } from '../../../../../core/services/move-phase.service';
import { EmployeeMovePhaseComponent } from '../../../../../../app/public/dashboard/components/employee-move-phase/employee-move-phase.component';
import { Providers } from './../../../../../core/models/providers.model';
import { ClientContactsService } from '../../../services/client-contacts.service';
import { CategoryService } from '../../../services/category.service';
import { UpdateDepatureDestinationComponent } from '../update-depature-destination/update-depature-destination.component';
import { WorkDetailPopupComponent } from '../work-detail-popup/work-detail-popup.component';
import { ClientService } from '../../../services/client.service';
import { PointRecalculationModalComponent } from '../components/point-recalculation-modal/point-recalculation-modal.component';
import { HttpParams } from '@angular/common/http';
import { PointsRecalculationHistoryModalComponent } from '../components/points-recalculation-history-modal/points-recalculation-history-modal.component';

import { SendinviteInitialcontactCallModalComponent } from '../components/sendinvite-initialcontact-call-modal/sendinvite-initialcontact-call-modal.component';
import { BookingDetailsPopupComponent } from '../booking-details-popup/booking-details-popup/booking-details-popup.component';
import { Country } from '../../../../../core/models/country.model';
import { map, startWith } from 'rxjs/operators';
import { CompletePolicyCallModalComponent } from '../components/complete-policy-call-modal/complete-policy-call-modal.component';
import { PolicyHistoryComponent } from '../policy-history/policy-history.component';
import { RestoreTransfereeConfirmationModalComponent } from '../components/restore-transferee-confirmation-modal/restore-transferee-confirmation-modal.component';
import { FeatureFlag, FeatureFlagService } from './../../../../../core/services/feature-flag.service';
import { TransfereeAccopanyingMembersModalComponent } from '../components/transferee-accopanying-members-modal/transferee-accopanying-members-modal.component';
import { ShowOverrideHistoryComponent } from '../../../components/show-override-history/show-override-history.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { convertDateToUTC } from '../../../../../shared/utils/convert-date-to-utc';
import { REPAYMENT_AGREEMENT } from '../../../components/additional-document-upload/additional-document-upload.component';
import { ProgramManagementService } from '../../../services/program-management.service';

export const workOrderDelWarningMsg = 'Are you sure you want to cancel the Work Order?';
export interface Benefit {
  reference?: string;
  productSpecificationReference?: any;
  category?: string;
  displayName?: string;
  description?: string;
  points?: any;
  suppress?: boolean;
  productId?: string;
  hvgDetail?: HVGDetail;
  confirmedDate?: string;
  coreUnitsPerSelection?: string;
  tempLiving?: boolean;
  hybridTempLiving?: boolean;
}

export interface HVGDetail {
  allHighValueTaskOptOut?: boolean;
  allHighValueTaskComplete?: boolean;
  highValueItems?: HighValueItem[];
  date?: string;
  status?: string;
  totalReplacementValue?: any;
}

export interface HighValueItem {
  id: string;
  name: string;
  replacementValue: number;
  description: string;
  serialNumber: string;
  dateAdded: string;
}
export interface BenefitsActualsInfo {
  totalsInfo: TotalsActuals;
  benefitsBreakDowns: BenefitsBreakDown[];
}
export interface BenefitsBreakDown {
  hostCurrencyCode: string;
  hostCurrencyAmt: number;
  disbursedCurrencyCode: string;
  disbursedCurrencyAmt: number;
  billingCurrencyCode: string;
  billingCurrencyAmt: number;
  reference: string;
}
export interface TotalsActuals {
  hostCurrencyCode: string;
  hostCurrencyAmt: number;
  actualSpend: number;
  actualRemaining: number;
}

export interface DmsData {
  docType?: string;
  name: string;
  addedBy: string;
  lastName: string;
  date: any;
  docId: number;
  role: boolean;
  isDisabledForDelete:boolean
}

@Component({
  selector: 'app-transferee-detail',
  templateUrl: './transferee-detail.component.html',
  styleUrls: ['./transferee-detail.component.scss']
})
export class TransfereeDetailComponent implements OnInit, OnDestroy{
  public show: boolean;
  public showEmail: boolean;
  public showSN: boolean;
  matExpansionHeaderHeight = '40px';
  isRevealed: boolean;
  consultantinfo: any = {};
  configFields: any = [];
  orderRequestId: string;
  benefits: any = [];
  benefitsGroupBy: any;
  confirmedPoints: any;
  confirmedAmount: any;
  businessUnit: any;
  benefitsList: Array<{ group: string, items: Array<Benefit> }>;
  BenefitCategories;
  movePhaseAndRiskData: any;
  isRecalcPointsBtnDisabled: boolean = false;

  /** user role */
  userRole: UserType;

  /**edit point flag */
  editPoint: boolean;
  /**program points */
  points: number;
  /**program amount */
  amount: number;
  /** total family members relocating */
  totalFamilyMembersRelo: number;
  jobStartEdit: false;
  estimatedArrivalDateEdit : boolean = false; 
  /** Store program array  */
  programValues: any[];

  /** benefits with High Value Goods  */
  benefitsHVG: Benefit[];

  movePhaseData: any;



  @Input() transferee: any;
  @Output() close = new EventEmitter<boolean>();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Input() togglePosition;

  /** To display client details in download report*/
  @Input() clientDetails: AdminClient;

  @ViewChild(MatSort) sort: MatSort

  estimatedMoveStartDate: any;
  estimatedMoveEndDate: any;
  hasFamilyDetails = false;

  initaljobStartDate = new UntypedFormControl();
  initalEstimatedArrivalDate = new UntypedFormControl();
  DefaultjobStartDate = 'NA'; 
  defaultEstimatedArrivalDate = 'NA';
  isInProgress = false;
  editProgramName = false;
  editPolicyCallDetails = false;
  timeZone: string;
  policyCallDate: any;
  policyCallTime: any;
  displayPolicyCallTime: any;
  smart_invite_id: string;
  programName: UntypedFormControl;
  contractAvailable: boolean = false;
  policyCallDuration: number;

  canUnconfirmBenefit = false;
  candidateDetails: any = {};
  workOrderDetails?: any;
  formattedDate;
  candidatePartyId: string;
  moveDetailList: any[] = null;
  countryList: Array<any>;
  countriesList: Array<any>;
  tempCountryList: any;
  currentChipOrderRequestId: any;
  isVisible: boolean = true;
  currOrderRequestId: any;
  isOverrideClicked:boolean =  false;
  paymentEligibilityText = ""
  eligibilityDate =  new UntypedFormControl();
  isPaymentEligibilityOverride = new UntypedFormControl();

  authCreateDate ="NA"
  authSubmitTime ="NA"
  authTimeZone = "NA"

  /** userPreferences variable to store the UserConfig object. */
  userPreferences: UserConfig;
  isTransactionShow: boolean = false;
  isProvider: boolean = false;
  /**to store fileNumber from Candidate API */
  fileNumber: any = null;
  /**to store transaction amount and currency value from employee-transaction component */
  transactionAmount: any = 0;
  transactionCurrency: any;
  /** holds the data for the Linked list component */
  milestonesData: LinkedListData[] = [];
  /** holds the sentiment data for the Linked list component */
  sentimentData: LinkedListData[] = [];
  searchProvider;
  isSearch = false;
  preferredCurr: string = "";
  providerCount = 0;
  /** is ManageMove Client or not */
  isMMUser = this.clientContactsService.isMMUser;
  public moveStatus: any;

  isGOUser = this.clientContactsService.isGOUser;

  accordinData:any =[];
  displayedColumns: string[] = ['orderno', 'supplier', 'agent','modification', 'status', 'action'];

  // docDisplayedColumns: Array<string> = ['name', 'description', 'type', 'by', 'date', 'version'];
  // docdataSource = [{name:'Customer Cash Out Letter',description:'Tortor eget, varius blandit arcu vestibulum et vehicula purus augue nec',type:'PDF',by:'Daniel Goulet (Transferee)',date:'2021-08-23',version:3,exception:true}, {name:'Assignment Letter',description:'Proin ac augue nec neque fringilla dignissim metus nisi quam do',type:'DOCX',by:'Corinne James',date:'2021-07-30',version:1},{name:'Online Consent Document',description:'Dignissim metus nisi quam do proin ac augue nec neque fringilla',type:'PDF',by:'Frederick Peterson',date:'2021-07-28',version:2},{name:'Privacy Policy',description:'Neque fringilla dignissim metus nisi quam do proin ac augue nec blandit',type:'DOCX',by:'Frederick Peterson',date:'2021-07-14',version:1}];
  // docVersions2 = [{date:'2021-07-28',value:2},{date:'2021-07-14',value:1}];
  // docVersions3 = [{date:'2021-08-23',value:2},{date:'2021-07-17',value:1},{date:'2021-06-09',value:1}];

  docDisplayedColumns: Array<string> = ['docType','name', 'addedBy', 'date', 'docId'];
  docdataSource: any = new  MatTableDataSource();
  searchValue: string = '';
  displayDocData: DmsData[] = [];
  showTable: boolean = false;

  /**SSO field */
  ssoIdentifier;
  isSSOIdentifierEdit: boolean = false;
  /**to store ssoedit value */
  ssoEditValue: any = '';
  /** regex validation for sso value*/
  showSSOField: boolean = false;
  ssoRegex: any;
  ssoRegexErrorMessage: string;
  datas: any;
  showSSOApiErr: string;
  contractERR: string;
  /** benefits Actual Info  */
  benefitsActualsInfo:BenefitsActualsInfo;
  /** Form control instance to select currency */
  selectCurrency = new UntypedFormControl(false);
  /** Holds value of actual disbursed spending */
  actualHostCurrencySpend: number = 0;
  /** Holds value of actual disbursed remaining */
  actualHostCurrencyRemaining: number = 0;
  /** Holds value of actual disbursed Currency */
  actualHostCurrencyCode: string;
  /** Holds value of actual billing amount */
  actualBillingSpend: number = 0;
  /** Holds value of actual remaining billing amount*/
  actualBillingRemaining: number = 0;
  /** Holds value of actual billing currency*/
  actualBillingCurrencyCode: string;

  /** initial contact - completed consultant details modal */
  isconsultantDetailsModalOpen: boolean = false;
  cancelledId:any;
  chkForStatus:any;
  mock: any =[];
  orderStatus: any;
  lastUpdatedAt: any;
  lastUpdatedBy: any;
  public isMarkPolicy = true;
  transfereeDetails: any;
  actualExpenseSubscription: Subscription;
  transfereeContextCookie: any;
  authorizationDetails: any;
  programContract: any;
  contractOriginalList: any[];
  contractDetails: UntypedFormControl;
  contractList: any[];
  editContractName: boolean = false;
  editCurrencyName: boolean = false;
  editMoveType: boolean = false;
  moveTypeValues: any;
  geoOrigin: UntypedFormControl;
  billiingCurrency: any;
  currencyList: any[];
  selectedCurrency:any;
  filteredCurrency: any;
  currencies: UntypedFormControl;
  moveType: UntypedFormControl;
  billiingCurrencies: any[];
  manageAuthorizationsCapability: any;
  /* manage transferee capabilities*/
  userCapabilities: number;
  userPolicyCallCapbilities: any;      //  manage ability to mark policy call complete
  userInitialContactCapbilities: any; //  manage ability to mark initial contact complete
   /* manage transferee capabilities*/
  transfereeMovDetailCapabilities: any;
  transfereeLocationCapabilities: any;
  transfereeSSOCapabilities: any;
  bbTransfereePortalCapability: boolean = false;
  contractValues: any;
  currencyValues: any;
  divisionPartyId: any;
  contractSub: Subscription;
  pointsOverrideCapability: string;
  transfereeDetailsCapability: any;
  movePhaseRiskCapability: any;
  milestoneCapability: any;
  familyMemberCapability: string;
  resendInviteCapability: string;
  updateProgramCapability: string;
  hostBillingCapability: string;
  managemovemoveCapability: string;
  transfereeDetailCapability: any;
  additionalDetailCapability: string;
  benefitBuilderCapability: string;
  jobStartCapability: string;
  moveDetailsCapability: string;
  dmsCapability: string;
  unconfirmBenefitsCapability:string;
  editTransfereeContractCapability: string;
  transfereeDetailAdmCapability: any;
  movedetailsCapability: any;
  restoreTransfereeCapability: string;
  transfereeIDInfoCapability: string;
  hasProgramname: boolean = false;
  newProgramDetails: any;
  newContractDetail: any;
  enableAuthorization: boolean = false;

  policyCallCompleteDate: any = '';
  policyCallCompleteTime: any = '';
  displayPolicyCompleteCallTime: any = '';
  isDocumentLibSecExpanded: boolean = false;
  isSpecialInstructionsEnabled: boolean;
  recalcPointsCapability: string;
  recalcPointsHistory: string;
  allowedDoc: string = 'Repayment Agreement';
  lumpSumForm: FormGroup;
  editLumpDetails: boolean = false;
  filteredLumpSumCurrency: Observable<Country[]>;
  isLumpSum: boolean;
  sourcesList: string[] = ['Relocation Accounting', 'Consultant'];
  transfereeDetailDocUpload: string;
  lumpSumAmountCapability: string;
  lumpSumAmountOverrideCapability: string;
  lumpSumAuditCompleteCapability: string;
  overridePaymentEligibilityCapability: string;
  policyAssignedCapability: string = 'write';
  lumpSumAmountRequested : boolean = false;
  isDocLibraryUIExpanded = false ;
  isMoveSwitched :boolean = false;
  isLumpSumTransfereeEnabled: boolean = false;
  repaymentAgreementType:'E-Sign'|'Consultant Collects';
  repayDocId: number;

  lumpSumAmountRequestedDate = new UntypedFormControl();
  repaymentAgreementAcceptedDate =new UntypedFormControl();
  isSSOEnabled: boolean;
  bankingInfoCapability: string;
  policyValues: any[];
  filteredPolicyValues: Observable<any>;
  assignedPolicy =  new UntypedFormControl('');
  policy: string;
  isPolicyEditEnabled : boolean = false;


  constructor(
    private userTypeService: UserTypeService,
    private spinner: NgxSpinnerService,
    private candidateProfileService: CandidateProfileService,
    //private ConfigurableFieldsService: ConfigurableFieldsService,
    private appConfig: AppConfigService,
    private toastr: ToastrService,
    private readonly toaster: MatSnackBar,
    // public dialogRef: MatDialogRef<TransfereeDetailComponent>,
    public dialog: MatDialog,
    public live: LiveAnnouncer,
    private readonly cookieService: CookieService,
    private readonly hvgPdfGenerator: ProgramHvgReportGenerationService,
    private readonly transfereeSelectionPdfGen: TransfereeBenefitSelectionReportGenService,
    private programManagementService: ProgramManagementService,
    private readonly locationService: LocationsService,
    private userPreferenceService: UserPreferencesService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly milestoneSvc: MilestoneService,
    private readonly providerSvc: ProviderService,
    private readonly dateSvc: DateConversionService,
    private readonly sentimentSvc: SentimentService,
    private readonly movePhaseService: MovePhaseService,
    private clientContactsService: ClientContactsService,
    private readonly categoryService: CategoryService,
    private clientService: ClientService,
    private featureFlagService: FeatureFlagService,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.spinner.show();
    this.BenefitCategories = this.categoryService.categorySub.getValue();
    // this.editPoint = true;
    this.userRole = this.userTypeService.userType;
    this.userTypeService.capabilities$.subscribe(ele => {
      if(ele){this.checkCapabilities(ele)};
    });
    this.getFeatureFlag();
    this.getAuthorizationFeatureFlag();
    if (this.transferee) {
      const { isLumpSum = false, authorizationType = null } = this.transferee || {};
      this.isLumpSum = isLumpSum;
      this.orderRequestId = this.transferee.orderRequestId;
      sessionStorage.setItem('car-ses-oid', this.orderRequestId);
      if (authorizationType === 'BenefitsBuilder') {
        this.points = this.transferee.programDetails.points;
        this.amount = this.transferee.programDetails.programBudget ? this.transferee.programDetails.programBudget.totalAmount : 0;
        this.programName = new UntypedFormControl(this.transferee.programDetails.programName);
      } else if(authorizationType === 'Traditional' && isLumpSum) {
        this.programName = new UntypedFormControl(this.transferee.programDetails.programName);
      }
      this.authorizationDetails = this.transferee.authorizationDetails;
      this.newProgramDetails = this.transferee.programDetails;
      this.newContractDetail = this.transferee.contractDetails;
      this.divisionPartyId = this.transferee.divisionPartyId;
      this.contractDetails = new UntypedFormControl(this.transferee.authorizationDetails.contractDetails);
      this.currencies = new UntypedFormControl(this.transferee.authorizationDetails.currency);
      this.moveType = new UntypedFormControl(this.transferee.moveType);
      this.geoOrigin = new UntypedFormControl(this.transferee.contractDetails && this.transferee.contractDetails.hasOwnProperty('geoOrigin') ? this.transferee.contractDetails.geoOrigin : null);
      this.getTransfereeDetail(this.orderRequestId);

      this.isInProgress = this.transferee.moveStatus === 'In Progress'
        && !(this.benefitsList && this.benefitsList.length > 0) ? true : false;

        this.moveStatus = this.transferee.moveStatus;
    }

    if (this.clientService.ssoRegEx.ssoRegex) {
      this.showSSOField = true;
      this.ssoRegex = this.clientService.ssoRegEx.ssoRegex;
      this.ssoRegexErrorMessage = this.clientService.ssoRegEx.ssoRegexErrorMessage;
      this.ssoIdentifier = new UntypedFormControl('', this.ssoRegex ? [Validators.pattern(atob(this.ssoRegex))] : null)
    }
    this.isSSOEnabled = this.clientService.bbRole.includes('sso');
  }
  async getAuthorizationFeatureFlag() {
    this.enableAuthorization = await this.featureFlagService.getAuthorizationFeatureFlag();
  }

  getFeatureFlag() {
    this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
      (res) => {
        const featureFlags: FeatureFlag[] = res.items;
        for(let featureFlag of featureFlags) {
          if (featureFlag.key === 'is_special_instructions_enabled') { this.isSpecialInstructionsEnabled = featureFlag.on };
          if (featureFlag.key === 'lump-sum-transferee') { this.isLumpSumTransfereeEnabled = featureFlag.on };
        }
        
      }
    )
  }

  createLumpSumForm(programDetails) {
    const {amount, currency, cartusCalculate,cartusCalculateSource} = programDetails;
    this.lumpSumForm = this.formBuilder.group({
      amount: [{value: amount, disabled: true}, Validators.required] ,
      currency: [{value: currency, disabled: true}, Validators.required],
      cartusCalculate: [{value: cartusCalculate, disabled: true}, Validators.required],
      source: [{value:cartusCalculateSource, disabled: true}, Validators.required]
    });
    this.setEligibilityData(this.transfereeDetails.candidateDetails);
  }

  get cartusCalculate() {
    if(this.lumpSumForm) {
      return this.lumpSumForm.get('cartusCalculate').value;
    }
  }

  get lumpSumAmount() {
    if(this.lumpSumForm) {
      return this.lumpSumForm.get('amount').value;
    }
  }

  editLumpSumDetails() {
    this.editLumpDetails = !this.editLumpDetails;
    if(this.editLumpDetails) {
      this.lumpSumForm.get('amount').enable();
      this.lumpSumForm.get('currency').enable();
      this.lumpSumForm.get('source').enable();
    } else {
      if (this.lumpSumAmountOverrideCapability === 'write') {
        this.openLumpSumAmountOverrideAlertDialog();
      } else {
        this.saveLumpSumAmountDetails();
      }
      this.lumpSumForm.get('amount').disable();
      this.lumpSumForm.get('currency').disable();
      this.lumpSumForm.get('source').disable();
    }
  }

  /**currency value change update */
  currencyValueChange() {
    this.filteredLumpSumCurrency = this.lumpSumForm.get('currency').valueChanges
      .pipe(
        startWith(''),
        map(value => this.lumpSumfilter(value))
      );
  }

  lumpSumfilter(value) {
    if(value) {
      const filterValue = value.toLowerCase();
      const currencyList = this.currencyList? this.currencyList.filter(option => option.currency.code.toLocaleLowerCase().indexOf(filterValue) === 0): [];
      return currencyList;
    } else {
      return this.currencyList;
    }
  }


  showOverrideHistory() {
    const dialogRef = this.dialog.open(ShowOverrideHistoryComponent, {
      id: 'ShowOverrideHistoryDialog',
      data: this.transferee,
      panelClass: 'dialog-full-width',
      width: '30vw'
    });
  }

  showPaymentOverrideHistory() {
    const dialogRef = this.dialog.open(ShowOverrideHistoryComponent, {
      id: 'ShowOverrideHistoryDialog',
      data: {
        type: 'payment',
        details: this.transferee?.paymentEligibilityInfo
      },
      panelClass: 'dialog-full-width',
      width: '35vw'
    });
  }

/** for any addition/updation of capability here, Please update loginCapabilities.json required for unit test flow */
  checkCapabilities(capabilitiesList: any[]) {
    capabilitiesList.forEach(capability => {
      if (capability && capability.permission === 'allow' && capability.name) {
        if (capability.name === "MP - Transferee Details Administration") {
          this.transfereeDetailCapability = capability.operation;
        } else if (capability.name === 'MP - Move Phase & Risk') {
          this.movePhaseRiskCapability = capability.operation;
        } else if (capability.name === 'MP - Milestone') {
          this.milestoneCapability = capability.operation;
        } else if (capability.name === 'MP - Transferee Initial Contact Complete') {
          this.userInitialContactCapbilities = capability.operation;
        } else if (capability.name === 'MP - Transferee Complete Policy Call') {
          this.userPolicyCallCapbilities = capability.operation;
        } else if (capability.name === 'MP - Move Details') {
          this.transfereeMovDetailCapabilities = capability.operation;
        } else if (capability.name === 'MP - Transferee Location') {
          this.transfereeLocationCapabilities = capability.operation;
        } else if (capability.name === 'MP - SSO') {
          this.transfereeSSOCapabilities = capability.operation;
        } else if (capability.name === "Benefits Builder Transferee Portal") {
          this.bbTransfereePortalCapability = true;
        } else if (capability.name === 'MP - Family Members') {
          this.familyMemberCapability = capability.operation;
        } else if (capability.name === 'MP - Resend Invitation') {
          this.resendInviteCapability = capability.operation;
        } else if (capability.name === 'MP - Update Program') {
          this.updateProgramCapability = capability.operation;
        } else if (capability.name === "MP - Transferee Details") {
          this.transfereeDetailsCapability = capability.operation;
        } else if (capability.name === 'MP - Job Start Date') {
          this.jobStartCapability = capability.operation;
        } else if (capability.name === 'MP - Transferee Move Details') {
          this.moveDetailsCapability = capability.operation;
        } else if (capability.name === 'MP - Benefits Builder Details') {
          this.benefitBuilderCapability = capability.operation;
        } else if (capability.name === 'MP - Additional Details') {
          this.additionalDetailCapability = capability.operation;
        } else if (capability.name === 'MP - Transferee Document Library') {
          this.dmsCapability = capability.operation;
        } else if (capability.name === 'MP - Unconfirm Benefits') {
          this.unconfirmBenefitsCapability = capability.operation;
        } else if (capability.name === "MP - Edit Transferee Contract") {
          this.editTransfereeContractCapability = capability.operation;
        } else if (capability.name === 'MP - Managed Move Details') {
          this.managemovemoveCapability = capability.operation;
        } else if (capability.name === 'MP - Manage Host Billing') {
          this.hostBillingCapability = capability.operation;
        } else if (capability.name === "Points Override") {
          this.pointsOverrideCapability = capability.operation;
        } else if (capability.name === 'Manage Authorizations') {
          this.manageAuthorizationsCapability = capability.operation;
        } else if (capability.name === 'MP - Restore MoveOrder') {
          this.restoreTransfereeCapability = capability.operation;
        } else if (capability.name === 'MP - Transferee ID Information') {
          this.transfereeIDInfoCapability = capability.operation;
        } else if (capability.name === 'MP - Recalculate Points') {
          this.recalcPointsCapability = capability.operation;
        } else if (capability.name === 'MP - Recalculate Points History') {
          this.recalcPointsHistory = capability.operation;
        } else if (capability.name === 'MP - Transferee Detail Document Upload') {
          this.transfereeDetailDocUpload = capability.operation;
        } else if (capability.name === 'MP - Manage Lump Sum Amount') {
          this.lumpSumAmountCapability = capability.operation;
        } else if (capability.name === 'MP - Override Lump Sum Amount') {
          this.lumpSumAmountOverrideCapability = capability.operation;
        } else if (capability.name === 'MP - Mark Lump Sum Audit Complete') {
          this.lumpSumAuditCompleteCapability = capability.operation;
        } else if (capability.name === 'MP - Override Lump Sum Payment Eligibility') {
          this.overridePaymentEligibilityCapability = capability.operation;
        } else if (capability.name === 'MP - Transferee Banking Information') {
          this.bankingInfoCapability = capability.operation;
        }else if (capability.name === 'MP - Policy Assigned') {
          this.policyAssignedCapability = capability.operation;
        }
      }
    });
  }
  transformName(name){
    let sub = name.split(' ');
    return sub[0][0] + sub[1][0];
  }

  // getTransferee Details
getTransfereeDetail(orderRequestId: string, type ?: string) {
    this.deleteTransfereeContext();
    this.defaultEstimatedArrivalDate = 'NA'
    let observablesArray = [];
    sessionStorage.setItem('car-ses-oid', orderRequestId);
    this.isMMUser ? observablesArray.push(this.candidateProfileService.getActualExpenses()) : observablesArray.push(of(null));
    observablesArray.push(this.candidateProfileService.getTransferee(orderRequestId));

    forkJoin(observablesArray).subscribe(([actualsRes, res]: any) => {
      this.benefitsActualsInfo = actualsRes;
      this.transfereeDetails = res;
      this.candidateDetails = res.candidateDetails;
      this.repaymentAgreementType = this.candidateDetails.repaymentAgreementType;
      this.setLumpSumDates();
      this.lumpSumAmountRequested = this.candidateDetails.lumpSumAmountRequested ? this.candidateDetails.lumpSumAmountRequested : false; 
      this.displayDocData = []
      if (res.benifits) {
        res.benifits.forEach(element => {
          if (element.tempLiving) {
            let worktable: any = {};
            worktable.displayName = element.displayName;
            const supplierOrd: any = element.supplierInfo.supplierOrders ? element.supplierInfo.supplierOrders : [];
            const supplierOrdReq = element.supplierInfo.supplierOrderRequests ? element.supplierInfo.supplierOrderRequests : [];
            if (supplierOrdReq.length) {
              supplierOrdReq.forEach(ele => {
                ele['isSupplierOrd'] = true;
              })
            }
           // element.supplierInfo['mergedSupOrdAndOrd'] = supplierOrd.concat(supplierOrdReq);
            element.supplierInfo['mergedSupOrdAndOrd'] = supplierOrdReq.concat(supplierOrd);
            if (element.supplierInfo.mergedSupOrdAndOrd && element.supplierInfo.mergedSupOrdAndOrd.length && element.supplierInfo.mergedSupOrdAndOrd.length > 1) {
              element.supplierInfo.mergedSupOrdAndOrd.sort((order1, order2) => {
                let ordera: any = new Date(order1.createdAtDate),
                  orderb: any = new Date(order2.createdAtDate);
                return orderb - ordera;
              })
            }
            worktable.supplierInfo = element.supplierInfo;
            worktable.hybridTempLiving = element.hybridTempLiving;
            worktable.incrementalCurrencyUnit = element.incrementalCurrencyUnit;
            worktable.rangeIncrementUnit = element.rangeIncrementUnit;
            worktable.reimbursement = (element.incrementalCurrency) ? true : false;
            this.accordinData.push(worktable);
          }
        });
      }

      this.currOrderRequestId = orderRequestId;
      // if(res.candidateDetails.authorizationType === 'BenefitsBuilder') {
      //   Object.assign(res.candidateDetails.programDetails, this.newProgramDetails);
      // }
      res.candidateDetails.contractDetails = this.newContractDetail;
      res.candidateDetails.fileNumber ? this.fileNumber = res.candidateDetails.fileNumber : this.fileNumber = null;
      if (res.candidateDetails && res.candidateDetails.programDetails && res.candidateDetails.programDetails.manualOverride) {
        this.isRecalcPointsBtnDisabled = true;
      }
      if (this.clientService.ssoRegEx.ssoRegex && res.candidateDetails.authorizationType === 'BenefitsBuilder' && this.userRole === 'file-setup-specialist') {
        this.ssoIdentifier.setValue(res.candidateDetails.ssoIdentifier ? res.candidateDetails.ssoIdentifier : '');
        this.setSSOValidator();
      }
      if (this.fileNumber && this.currOrderRequestId) {
        this.getmovePhaseAndRiskData(this.fileNumber, this.currOrderRequestId);
      }
      if (res.candidateDetails && res.candidateDetails.authorizationDate) {
        const authDate = res.candidateDetails.authorizationDate
        this.authCreateDate = authDate.split('T')[0]
        this.authSubmitTime = moment(authDate).format('hh:mm A')
        res.candidateDetails['authorizationDate'] = res.candidateDetails.authorizationDate.split('T')[0];
      }  
      this.authTimeZone = res.candidateDetails && res.candidateDetails.authorizationTimezone ? res.candidateDetails.authorizationTimezone :'NA'
      const { isLumpSum = false, authorizationType = null } = res.candidateDetails || {};
      this.isLumpSum = isLumpSum;
      if (((isLumpSum && authorizationType === 'Traditional') || (!isLumpSum && authorizationType === 'BenefitsBuilder')) && (this.updateProgramCapability === 'write' || this.updateProgramCapability === 'read')) {
        const type = isLumpSum && authorizationType === 'Traditional' ? 'Traditional' : 'Benefits Builder';
        this.getProgram(this.transferee.divisionPartyId, type);
        if(isLumpSum && res.candidateDetails.programDetails) {
          this.createLumpSumForm(res.candidateDetails.programDetails);
          this.currencyValueChange();
        }
      }
      if (this.transferee.authorizationType === 'Traditional' && !this.isLumpSum) {
        this.getBaseContract();
        
      }

      this.assignedPolicy.setValue(res.candidateDetails.assignedPolicy ? res.candidateDetails.assignedPolicy : 'NA');
      this.assignedPolicy.value ? this.policy = this.assignedPolicy.value : null;
      
      if(res.candidateDetails.authorizationType == "Traditional"){
        this.getPolicyData()
      }

      res.candidateDetails.authorizationDetails = this.authorizationDetails;
      res.candidateDetails.divisionPartyId = this.divisionPartyId;
      this.candidatePartyId = res.candidateDetails.candidateId;
      this.moveDetailList === null ? this.getTransfereeMoveDetails(this.candidatePartyId) : null;
      if (this.candidateDetails && this.candidateDetails.ccinitiatorNameDetails) {
        this.candidateDetails.ccinitiatorNameDetails['fullName'] = `${this.candidateDetails.ccinitiatorNameDetails.firstName} ${this.candidateDetails.ccinitiatorNameDetails.lastName}`;
      }
      if (this.candidateDetails && this.candidateDetails.createdByNameDetails) {
        this.candidateDetails.createdByNameDetails['fullName'] = `${this.candidateDetails.createdByNameDetails.firstName} ${this.candidateDetails.createdByNameDetails.lastName}`;
      }
      this.totalFamilyMembersRelo = res.candidateDetails.totalNumberOfRelocatingMembers || 0;

      if (document.location.hostname === 'localhost') {
        this.cookieService.set('transferee-context', res.candidateDetails.candidateId, 1, '/', 'localhost', false, 'Lax');
      } else {
        this.cookieService.set('transferee-context', res.candidateDetails.candidateId, null, null, '.cartus.com', true, 'Lax');
      }
      this.hasFamilyDetails = true;

      this.transferee = res.candidateDetails;
      this.consultantinfo = res.consultantinfo;
      this.configFields = res.candidateDetails.additionalDetails;

      if(this.orderRequestId !== this.currOrderRequestId){
        this.orderRequestId = orderRequestId;
        this.isMoveSwitched = !this.isMoveSwitched;
        if (res.candidateDetails.authorizationType === 'BenefitsBuilder' || (res.candidateDetails.authorizationType === 'Traditional' && isLumpSum)) {
         if(this.programName){
          this.programName.setValue(this.transferee.programDetails.programName);
         }else{
          this.programName = new UntypedFormControl(this.transferee.programDetails.programName);
         }
        }
        this.setContractData();
      }
     

      this.consultantinfo.hasOwnProperty('timeZone') ? this.timeZone = this.consultantinfo.timeZone : null;
      this.consultantinfo.hasOwnProperty('smart_invite_id') ? this.smart_invite_id = this.consultantinfo.smart_invite_id : null;
      if (this.transferee.authorizationType === 'BenefitsBuilder') {
        if(this.candidateDetails.hasOwnProperty('policyCallDetails') && this.candidateDetails.policyCallDetails.hasOwnProperty('policyCallDate')) {
          if(this.candidateDetails.policyCallDetails.hasOwnProperty('policyCallStatus') && this.candidateDetails.policyCallDetails.policyCallStatus === 'completed') {
            this.getCompletedPolicyCallDetails(this.candidateDetails.policyCallDetails.policyCallDate);
          } else {
            this.policyCallDate = this.candidateDetails.policyCallDetails.policyCallDate;
          }
        }  

      }
      if (this.consultantinfo.hasOwnProperty('start')) {
        this.policyCallTime = new Date(this.consultantinfo.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        this.displayPolicyCallTime = moment(this.consultantinfo.start).format('hh:mm A');
        this.policyCallDate = moment(this.consultantinfo.start).format('YYYY-MM-DD');
      }
      if (res.candidateDetails.programDetails && res.candidateDetails.programDetails.hasOwnProperty('policyCallDuration')) {
        this.policyCallDuration = res.candidateDetails.programDetails.policyCallDuration;
      } else {
        this.policyCallDuration = 60;
      }
      if (this.transferee.optionalDetails && this.transferee.optionalDetails.jobStartDate) {
        this.initaljobStartDate = new UntypedFormControl(new Date(this.transferee.optionalDetails.jobStartDate));
        this.setJobStartDate(this.transferee.optionalDetails.jobStartDate);
      }

      if (this.transferee.optionalDetails && this.transferee.optionalDetails.estimatedArrivalDate){
        this.setEstimateArrivalDate(this.transferee.optionalDetails.estimatedArrivalDate)
      }
      if (this.transferee.ssoIdentifier) {
        this.ssoIdentifier = new UntypedFormControl(this.transferee.ssoIdentifier);
        this.ssoEditValue = this.transferee.ssoIdentifier;
      }
      this.confirmedPoints = res.hasOwnProperty('confirmedPoints') ? res.confirmedPoints : this.transferee && this.transferee.programDetails && this.transferee.programDetails.isCashoutSpecialized ? this.transferee && this.transferee.programDetails && this.transferee.programDetails.points : 0;
      //for MMuser ConfirmedAmount
      this.confirmedAmount = res.hasOwnProperty('confirmedAmount') ? res.confirmedAmount : 0;
      res.candidateDetails.hasOwnProperty('isCashedOut') && res.candidateDetails.isCashedOut ? this.confirmedAmount = this.amount : null;
      // tslint:disable-next-line: no-unused-expression
      const benefits = res.hasOwnProperty('benifits') ? res.benifits : [];
      this.setHvgBenefits(benefits);
      if ((this.unconfirmBenefitsCapability === 'write') && Array.isArray(benefits) && benefits.length > 0) {
        this.canUnconfirmBenefit = true;
      }

      this.benefits = benefits;
      if (this.userRole !== 'file-setup-specialist') {
        if (this.transferee.authorizationDetails.hasOwnProperty('contractDetails')) {
          if (this.transferee.authorizationDetails.contractDetails.contractId) {
            this.contractValues = this.transferee.authorizationDetails.contractDetails.baseContract + " " + this.transferee.authorizationDetails.contractDetails.contractId + " " + this.transferee.authorizationDetails.contractDetails.contractName;
          } else {
            this.contractValues = this.transferee.authorizationDetails.contractDetails.baseContract +  " " + this.transferee.authorizationDetails.contractDetails.contractName;
          }
        }
        if (this.transferee.authorizationDetails.hasOwnProperty('currency')) {
          this.currencyValues = this.transferee.authorizationDetails.currency.code + '-' + ' ' + this.transferee.authorizationDetails.currency.description;
        }
      }

      // Merge Actuals Info
      if(this.benefits && this.benefitsActualsInfo && this.benefitsActualsInfo.hasOwnProperty('benefitsBreakDowns') && this.benefitsActualsInfo.benefitsBreakDowns.length > 0) {
          this.benefits = this.mergeActualExpenses(this.benefits);
          this.calculateActualExpenses();
      }
      if(this.benefits.length > 0 && type == 'unconfirm'){
        this.toastr.info('Except for those with active work orders, all benefits have been successfully deleted.', null, {
          timeOut: 4000
        });
      } else if(type == 'unconfirm') {
        this.toastr.info('Benefits has been unconfirmed successfully', null, {
          timeOut: 4000
        });
      }

      this.benefitsList = this.groupBenefits(this.benefits);
      this.currentChipOrderRequestId = orderRequestId;
      if (this.orderRequestId && this.fileNumber) {
        this.milestoneSvc.getMilestonesByFileNumber(this.fileNumber, this.currOrderRequestId).subscribe((milestone: Milestones) => {
          this.createMilestonesData(milestone);
        });
        //sentiments
        this.sentimentSvc.getSentimentsByFileNumber(this.fileNumber, this.currOrderRequestId).subscribe((sentiment: Sentiments) => {
          this.createSentimentData(sentiment.timeLine);
        });

        //providers
        this.providerSvc.getProvidersByFileNumber(this.fileNumber, this.currOrderRequestId,this.preferredCurr)
        .subscribe((providers) => {
          this.providerCount = providers.suppliers.length;
        });
      }
      if(actualsRes && actualsRes.isError) {
        this.toastr.error('An error occured while fetching actuals details.', null, {
          timeOut: 4000
        });
      }
    },(err) => {
        this.toastr.error('Getting error while fetching transferee details.', null, {
          timeOut: 4000
        });
      });
  }

  contractListProcess(res) {
    this.contractOriginalList = JSON.parse(JSON.stringify(res));
    this.contractList = JSON.parse(JSON.stringify(res));
    res.forEach((ele, index) => {
      ele['contractDetails'] = {
        'baseContract': ele.baseContract,
        'contractId': ele.contractId ,
        'contractName': ele.contractDescription,
      }
      ele.contractDetails.contractId === undefined ? delete ele.contractDetails.contractId : ele.contractDetails.contractId;
      this.contractOriginalList[index] = ele;
      this.contractList[index] = { 'contractDetails': ele.contractDetails };
    });
    if (this.transferee && this.transferee.authorizationDetails && this.transferee.authorizationDetails.contractDetails && this.transferee.authorizationDetails.contractDetails.contractName) {
      const contractNameValue = this.contractDetails.value.contractName;
      let data = this.contractOriginalList.find(ele => ele.contractDescription === contractNameValue);
      this.moveTypeValues = ['Client specific Move Type does not apply', ...data.moveTypes];
      this.billiingCurrency = JSON.parse(JSON.stringify(data.billingCurrencies));
      this.locationService.countryList.subscribe(countryList => {
        if (countryList.length > 0) {
          countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
          this.countriesList = countryList;
          const currList = [];
          countryList.forEach((curr) => {
            curr.currency ? currList.push(curr) : null;
          });
          currList.sort((a, b) => a.currency && b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
          this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
        }
        });
      if(this.billiingCurrency && this.countriesList) {
        this.billiingCurrency.forEach((billCur, index) => {
          let currencyValue = this.currencyList.find(cur => cur.currency && cur.currency.code === billCur);
          this.billiingCurrency[index] = currencyValue;
            this.billiingCurrency[index] = { 'currencies': currencyValue.currency };
        })

        this.billingCurrencyValueChange();
      }
    }
  }

  getBaseContract() {
    if (this.updateProgramCapability === 'write' || this.updateProgramCapability === 'read') {
      if (this.transferee.programDetails) {
        const program = this.programValues.filter((item) => item.programName === this.transferee.programDetails.programName);
        program && program.length > 0 && program[0].programId ? this.contractSub = this.candidateProfileService.getContractListBB(program[0].programId).subscribe(res => {
          if (res) {
            if (res.length == 0) {
              const val = {
                baseContract: this.transferee.contractDetails.baseContract,
                billingCurrencies: this.transferee.contractDetails.billingCurrencies,
                contractDescription: this.transferee.contractDetails.contractDescription,
                contractId: this.transferee.contractDetails.contractId,
                moveTypes: this.transferee.contractDetails.moveTypes
              }
              res.push(val);
            } else if(res.length > 0) {
              this.getAllBaseContract(res);
            }

            this.contractListProcess(res);
          }
        }) : null;
      } else if (this.transferee.authorizationType == 'Traditional') {
        let clientId = this.userRole === 'client-contact-initiator' ? sessionStorage.getItem("cc_clientPartyId") : sessionStorage.getItem('clientId');
        this.contractSub = this.candidateProfileService.getContractList(clientId).subscribe(res => {
          if (res) {
            if (res.length == 0) {
              const val = {
                baseContract: this.transferee.contractDetails.baseContract,
                billingCurrencies: this.transferee.contractDetails.billingCurrencies,
                contractDescription: this.transferee.contractDetails.contractDescription,
                contractId: this.transferee.contractDetails.contractId,
                moveTypes: this.transferee.contractDetails.moveTypes
              }
              res.push(val);
            } else if(res.length > 0) {
              this.getAllBaseContract(res);
            }

            this.contractListProcess(res);
          }
        });
      }
    }
  }
  getAllBaseContract(res) {
    if(this.transferee.authorizationDetails && this.transferee.authorizationDetails.contractDetails && this.transferee.authorizationDetails.contractDetails.baseContract) {
      let contractval = res.findIndex(ele => {
        if (ele.baseContract === this.transferee.authorizationDetails.contractDetails.baseContract.toString() &&
          ele.contractId === this.transferee.authorizationDetails.contractDetails.contractId &&
          ele.contractDescription === this.transferee.authorizationDetails.contractDetails.contractName
        ) {
          return ele;
         }
      })
        if (contractval == -1) {
          this.hasProgramname = true;
        } else  {
          this.hasProgramname = false;
        }
        if (this.hasProgramname) {
          const obj = {
            baseContract: this.transferee.contractDetails.baseContract,
            billingCurrencies: this.transferee.contractDetails.billingCurrencies,
            contractDescription: this.transferee.contractDetails.contractDescription,
            contractId: this.transferee.contractDetails.contractId,
            moveTypes: this.transferee.contractDetails.moveTypes
        }
          res.unshift(obj);
        }
      }
  }

  getContractBaseDetails(event) {
    if (event.value) {
      let data = this.contractOriginalList.find(ele => ele.contractDescription === event.value.contractName);
      data.geoOrigin? this.geoOrigin.setValue(data.geoOrigin): this.geoOrigin.setValue('');
      this.moveTypeValues = ['Client specific Move Type does not apply', ...data.moveTypes]
      this.billiingCurrency = JSON.parse(JSON.stringify(data.billingCurrencies));
      this.billiingCurrency.forEach((billCur, index) => {
        let currencyValue = this.currencyList.find(cur => cur.currency && cur.currency.code === billCur);
        this.billiingCurrency[index] = { 'currencies': currencyValue.currency };
      });
      this.billingCurrencyValueChange();
      this.currencies.setValue('');
     this.transferee.authorizationType == 'Traditional' ? this.moveType.setValue('') :null;
    }
  }
  billingCurrencyValueChange() {
    this.billiingCurrency.forEach((ele,index) => {
      if (ele.currencies.code === this.transferee?.authorizationDetails?.currency?.code) {
        this.billiingCurrency.splice(index, 1);
        this.billiingCurrency.unshift({
          'currencies': {
            code:this.transferee.authorizationDetails.currency.code,
            description: this.transferee.authorizationDetails.currency.description
          }
        })
      }

    })

    this.filteredCurrency = this.billiingCurrency;
  }

  displayCurrencyFn(curr:any,curr1:any): string {
    return curr && curr.code && curr1 != ""  ? curr1.code + '-' + curr1.description: '';
  }

  displaymoveFn(curr:any,curr1:any) {
    return curr && curr1!=''? curr === curr1 : '';
  }

 /** setup the key dates data for the linked list component */
 createMilestonesData(milestones: Milestones): void {
  if (milestones && milestones.data) {
    milestones.data.forEach(element => {
      let ownerName = '';

        if (element.milestoneDesc === 'Authorized') {
          ownerName = '(by ' + milestones.initiatorFirstName + ' ' + milestones.initiatorLastName + ' )';
        } else {
          ownerName = '';
        }
        this.milestonesData.push({
          value: this.dateSvc.convertToYYYYMMDD(element.milestoneDate),
          valueClass: 'active',
          columns: [{
            label: element.milestoneDesc,
            subLabel: ownerName
          }]
        } as LinkedListData);
      });
    }
  }
     /** setup the sentiment data for the linked list component */
  createSentimentData(data: Sentiment[]): void {
    if (data) {
      data.sort((a, b) =>
        this.dateSvc.convertToEpoch(a.sentimentCompletedDate) -
        this.dateSvc.convertToEpoch(b.sentimentCompletedDate)
      );
      data.reverse().forEach(element => {
        this.sentimentData.push({
          value: this.dateSvc.convertToYYYYMMDD(element.sentimentCompletedDate),
          valueClass: 'active',
          columns: [
            {
              label: element.sentimentName
            },
            {
              label: element.sentimentLevel,
              type: 'star'
            }
          ]
        } as LinkedListData);
      });
    }
  }
  openSearch() {
    this.isSearch = true;
  }
  closeSearch() {
    this.isSearch = false;
  }
  clearTextSearch(){
    this.searchProvider='';
    this.searchValue = '';
    this.docdataSource = new MatTableDataSource(this.displayDocData);
  }

  // transferee move details
  getTransfereeMoveDetails(candidateId) {
    // this.spinner.show();
    let clientId;
    if (this.userRole === 'client-contact-initiator') {
      clientId = sessionStorage.getItem("cc_clientPartyId");
    } else {
      clientId = this.clientDetails && this.clientDetails.clientId;
    }
    this.candidateProfileService.getMoveDetails(candidateId, clientId).subscribe((res) => {
      if(res) {
        this.moveDetailList = res;
        /**to get country code to display in transferee move details chip */
        this.locationService.countryList.subscribe(countryListData => {
          if(countryListData.length > 0 && this.moveDetailList.length > 0) {
            this.moveDetailList.forEach(data => {
              if (data.departureAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.departureAddr.country)
                data.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
              if (data.destinationAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.destinationAddr.country)
                data.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
            });
          }
        });
      }
    }, (err) => {
      this.toastr.error('Error Fetching Move Details.', null, {
        timeOut: 4000
      });
    });
  }

  /**Function to open the mat Dialog on click of add */
  openDialog(isTraditional) {
    const transfereeData = {
      nameDetails: this.transferee.nameDetails,
      emailDetailsList: this.transferee.emailDetailsList,
      phoneDetailsList: this.transferee.phoneDetailsList,
      authorizationType: isTraditional ? 'Traditional' : 'BenefitsBuilder'
    };
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
        isTraditional: isTraditional,
        associateTransferee: true,
        candidate: transfereeData,
        candidatePartyId: this.candidatePartyId
      },
      panelClass: 'dialog-full-width',
      id: 'addEditDialog'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) { this.getTransfereeMoveDetails(this.candidatePartyId); }
    });
  }
  openDialoge() {
    const dialogRef = this.dialog.open(UpdateDepatureDestinationComponent, {
      width: '52vw',
      data: {
        departureAddr: this.transferee.departureAddr,
        destinationAddr: this.transferee.destinationAddr,
        currOrderRequestId: this.currOrderRequestId
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.transferee.departureAddr = data.departureAddr;
        this.transferee.destinationAddr = data.destinationAddr;
        this.moveDetailList[0].departureAddr = data.departureAddr;
        this.moveDetailList[0].destinationAddr = data.destinationAddr;
        this.getTransfereeMoveDetails(this.candidatePartyId);
      }
    });

  }

  onChipClick(chipRef: MatChip, candidate: any) {
    this.isTransactionShow = false;
    this.isProvider = false;
    let previousChipSelection = this.currentChipOrderRequestId;
    if (candidate.moveStatus === 'In Progress' || candidate.moveStatus === 'Benefits Confirmed') {
      this.isVisible = true;
      if (this.currOrderRequestId !== candidate.orderRequestId) {
        this.milestonesData = [];
        this.sentimentData = [];
        this.isDocumentLibSecExpanded = false;
        this.isDocLibraryUIExpanded = false;
        this.accordinData = [];
        this.getTransfereeDetail(candidate.orderRequestId);
      }
    } else {
      this.currentChipOrderRequestId = candidate.orderRequestId;
      this.isVisible = false;
      const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
        id: 'EditDialog',
        data: {
          candidate: candidate,
          editTransferee: true,
        },
        panelClass: 'dialog-full-width'
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.getTransfereeMoveDetails(this.candidatePartyId);
        }
        this.currentChipOrderRequestId = previousChipSelection;
        this.isVisible = true;
      });
    }
  }

  setHvgBenefits(benefits: Benefit[]) {
    this.benefitsHVG = benefits.filter(ele => ele.hasOwnProperty('hvgDetail'));
    if(this.benefitsHVG.length > 0) {
      this.benefitsHVG.forEach(ele => {
        if (ele.hvgDetail.hasOwnProperty('allHighValueTaskOptOut') || ele.hvgDetail.hasOwnProperty('allHighValueTaskComplete')) {
          this.setStatusOfHVGBenefit(ele);
        }
        if (ele.hvgDetail.hasOwnProperty('highValueItems')) {
          this.setTotalReplacementValue(ele);
        }
      });
    }
  };

  setStatusOfHVGBenefit(benefit: Benefit) {
    status = (!benefit.hvgDetail.allHighValueTaskComplete && !benefit.hvgDetail.allHighValueTaskOptOut) ? `In Progress` :
      benefit.hvgDetail.allHighValueTaskComplete ? `Submitted` : `Opted Out`;
    benefit.hvgDetail['status'] = status;
  }

  setTotalReplacementValue(benefit: Benefit) {
    if (benefit.hvgDetail.hasOwnProperty('highValueItems')) {
      const value = benefit.hvgDetail.highValueItems.reduce((a, { replacementValue }) => a + replacementValue, 0);
      benefit.hvgDetail['totalReplacementValue'] = value ? formatNumber(Math.ceil(value), 'en-US') : 0;
    } else {
      benefit.hvgDetail['totalReplacementValue'] = 0;
    }
  }

  formatReplacementValue(value) {
    return `${formatNumber(Math.ceil(value), 'en-US')}`
  }

  openDownloadToastr(downloadFor?: string) {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toaster.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    downloadFor === 'benefitBuilderDetail' ? toaster.onAction().subscribe(_ => this.downloadPdf()) : toaster.onAction().subscribe(_ => this.downloadHvgPdf());
  };

  downloadHvgPdf() {
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    this.spinner.show();
    if (this.benefitsHVG.length) {
      pdfMake.createPdf(this.hvgPdfGenerator.generateDocumentDefinition(this.benefitsHVG, this.clientDetails, this.transferee, this.orderRequestId)).download(`${this.formattedDate}_${this.transferee.nameDetails.firstName}`);
      this.spinner.hide();
    } else {
      this.toastr.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
  };

  /**
   * download benefit builder details -- transferee benefits selection as pdf
   */
  downloadPdf() {
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy', 'en-US');
    this.spinner.show();
    if (this.benefitsList && this.benefitsList.length) {
      pdfMake.createPdf(this.transfereeSelectionPdfGen.generateDocumentDefinition(this.benefitsList, this.clientDetails, this.transferee, this.confirmedPoints, this.orderRequestId)).download(`${this.formattedDate}_${this.transferee.nameDetails.firstName}`);
      this.spinner.hide();
    } else {
      this.toastr.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
  };

  // getProgram(division) {
  //   this.candidateProfileService.getProgram(division).subscribe(res => {
  //     this.programValues = res.cc_divisionPrograms;
  //     this.programValues = this.programValues && this.programValues.length ? this.programValues.filter(e => e.contractId.includes(this.transferee.authorizationDetails.contractDetails.baseContract)) : [];
  //   });
  // }

  getProgram(division, type) {
    this.candidateProfileService.getProgram(division, type).subscribe(res => {
      this.programValues = res.cc_divisionPrograms;
      this.programValues = this.programValues && this.programValues.length ? this.programValues.filter(e => {
        if(e.contractId && e.hasOwnProperty('contractId')) {
        return e.contractId.includes(this.transferee.authorizationDetails.contractDetails.baseContract)
        }
      }) : [];
      this.getprogramDetail();
      this.getBaseContract();
    });
  }
  
  getprogramDetail() {
    let valuesProgram = this.programValues.findIndex(e => e.programName === this.transferee.programDetails.programName);
    if (valuesProgram == -1) {
      this.hasProgramname = true;
    } else  {
      this.hasProgramname = false;
    }
    if (this.hasProgramname) {
      const obj = {
        contractId: this.transferee.programDetails.contractId,
        coreOfferings:this.transferee.programDetails.coreOfferings,
        points: this.transferee.programDetails.points,
        programId: this.transferee.programDetails.programId,
        programName:this.transferee.programDetails.programName
      }
      this.programValues.unshift(obj);
    }
  }

  toggle() {
    this.show = !this.show;
  }

  toggleEmail(transferee) {
    const moveStatus = this.moveDetailList && this.moveDetailList[0].moveStatus;
    if ((this.userRole === 'consultant' || this.userRole === 'file-setup-specialist' || this.userRole === 'client-contact-initiator' || this.userRole === 'team-client-service-manager' ) && moveStatus !== 'In Progress' && moveStatus !== 'Benefits Confirmed' && moveStatus !== 'Deactivated' ) {
    const dialogRef = this.dialog.open(TWarningDialogComponent, {
        width: '550px',
        data: {
          isShowpPopup: true,
          transfereeDetails: transferee,
          candidateDetails: this.candidateDetails,
          orderRequestId: this.orderRequestId,
          moveStatus: this.moveDetailList && this.moveDetailList[0].moveStatus ? this.moveDetailList && this.moveDetailList[0].moveStatus : null,
          moveStatusDate: this.moveDetailList && this.moveDetailList[0].moveStatusDate ? this.moveDetailList && this.moveDetailList[0].moveStatusDate : null,
          type: this.moveDetailList && this.moveDetailList[0].type,
          authorizationType: this.moveDetailList && this.moveDetailList[0].authorizationType,
          ssoIdentifier: this.moveDetailList && this.moveDetailList[0].ssoIdentifier,
          resendInviteCapability: this.resendInviteCapability
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.data === 'send'){
          this.getTransfereeMoveDetails(this.candidatePartyId);
        }
      });
    } else {
      this.showEmail = !this.showEmail;
    }
  }

  toggleSN() {
    this.showSN = !this.showSN;
  }

  reveal() {
    this.isRevealed = !this.isRevealed;
  }

  closeTransferee() {
    this.totalFamilyMembersRelo = 0;
    this.close.emit(true);
    this.cookieService.delete('transferee-context','/');
    this.deleteTransfereeContext();
  }

  launchBB() {
    this.spinner.show();
    const userContext = JSON.parse(sessionStorage.getItem('UserContext'));
    const name = `${userContext.firstName || 'NA'} ${userContext.lastName || 'NA'}`;
    this.candidateProfileService.getSharedSessionCode(this.orderRequestId, name).subscribe(data => {
      if (data) {
        this.spinner.hide();
        const urlToOpen = this.appConfig.configData.bbUIUrl + '#/shared/' + data.session;
        window.open(urlToOpen, '_blank');
      } else {
        this.spinner.hide();
        this.toastr.error('Error generating shared session');
      }
    });
  }

  /** Mark Initial contact call status as complete by consultant only */
  makeInitialContactComplete() {
    this.candidateProfileService.makeInitialContactCallComplete(this.orderRequestId).subscribe((res) => {
      // tslint:disable-next-line: no-shadowed-variable
      if (res) {
        if (res.code && res.code == 'INITIAL_CALL_STATUS_UPDATE_SUCCESS') {
          const dialogRef = this.dialog.open(SendinviteInitialcontactCallModalComponent, {
            data: {
              transfereeName: this.transferee.nameDetails ? this.transferee.nameDetails.firstName + ' ' + this.transferee.nameDetails.lastName : ''
            },
            panelClass: 'makeItMiddle',
            autoFocus: false,
            restoreFocus: false,
          });
          dialogRef.afterClosed().subscribe(result => {
            this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
              this.transferee = res.candidateDetails;
              this.consultantinfo = res.consultantinfo;
              const benefits = res.benifits;
              res.confirmedPoints ? this.confirmedPoints = res.confirmedPoints : null;
              this.benefitsList = this.groupBenefits(benefits);
              this.spinner.hide();
            });
            if (result && result.action && result.action === 'sendInvite') {
              this.sendInitialCallInvite();
            }
          });
        } else if (res.message) {
          this.toastr.info(res.message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        this.spinner.hide();
      }
    });

  }

  sendInitialCallInvite() {
    let payload = { "orderRequestId": this.orderRequestId };
    this.candidateProfileService.sendInvitationInitialCallComplete(payload).subscribe((res) => {
      if (res['message']) {
        let message = 'Invitation Sent';
        this.toastr.info(message, null, {
          closeButton: true,
          enableHtml: true,
          disableTimeOut: false
        });
        this.getTransfereeMoveDetails(this.candidatePartyId);
        this.spinner.hide();
      } else {
        this.toastr.error('Error occured while sending invite');
      }
    });
  }
  /**
   * Invoked on click of initial contact completed consultant name
   */
  openConsultantDetailsModal() {
    this.isconsultantDetailsModalOpen = true;
  }
  /**
   * Invoked on click of close in consultant details pop up
   */
  closeConsultantDetails() {
    this.isconsultantDetailsModalOpen = false;
  }
  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    const benefitsByGroup = this.BenefitCategories.map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }
  compareContractData(data1: any, data2: any) {
    return (`${data1.baseContract} ${data1.contractId} ${data1.contractName}` === `${data2.baseContract} ${data2.contractId} ${data2.contractName}`);
  }
  editPointValidation() {
    // tslint:disable-next-line: triple-equals
    let message: string;
    let payload: object;
    if (this.points) {
      this.editPoint = !this.editPoint;
      // const program = this.programValues.find( prog => prog.programName = this.addCandidateForm.value.programDetails.programName);
      if (!this.editPoint && this.transferee.programDetails.points !== +this.points && +this.points < +this.transferee.programDetails.points) {
        if (+this.points < +this.confirmedPoints) {
          message = 'Total Budget must be equal to or greater than Spent Amount';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        } else if ( +this.points >= +this.confirmedPoints ) {
          payload = {
            points: +this.points
          };
          this.candidateProfileService.updatePoints(this.orderRequestId, payload).subscribe(res => {
            this.transferee.programDetails.points = this.points
          }
          );
        } else {
          this.points = this.transferee.programDetails.points;
        }
      } else if (!this.editPoint && this.transferee.programDetails.points !== +this.points && +this.points > +this.transferee.programDetails.points) {
        if (+this.transferee.programDetails.points * 0.25 + +this.transferee.programDetails.points <= +this.points) {
          message = 'You have entered points exceeding 25% of Program standard';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        payload = {
          points: +this.points
        };
        if (this.isGOUser) {
          payload = {
            points: +this.points,
            manualOverride: true
          }
        }
        this.candidateProfileService.updatePoints(this.orderRequestId, payload).subscribe(res => {
          this.transferee.programDetails.points = this.points
          if (this.isGOUser) {
            this.isRecalcPointsBtnDisabled = true;
          }
        }
        );
      }
      else {
        this.points = this.transferee.programDetails.points;
      }
    } else if (this.amount) {
      this.amount = typeof (this.amount) !== 'number' ? parseInt(this.amount) : this.amount;
      this.editPoint = !this.editPoint;
      let params: HttpParams;
      params = new HttpParams({
        fromObject: {
          'managedMove': 'true'
        }
      });
      if (!this.editPoint && this.transferee.programDetails.programBudget.totalAmount !== +this.amount && +this.amount > +this.transferee.programDetails.programBudget.totalAmount) {
        if (+this.transferee.programDetails.programBudget.totalAmount * 0.25 + +this.transferee.programDetails.programBudget.totalAmount <= +this.amount) {
          message = 'You have entered amount exceeding 25% of Program standard';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        let payload: object = { amount: this.amount, currency: this.transferee.programDetails.programBudget.currency }
        if (this.isGOUser) {
          payload = { amount: this.amount, currency: this.transferee.programDetails.programBudget.currency, manualOverride: true }
        }
        this.candidateProfileService.updateAmount(this.orderRequestId, payload, params).subscribe(res => {
          this.transferee.programDetails.programBudget.totalAmount = this.amount
          if (this.isGOUser) {
            this.isRecalcPointsBtnDisabled = true;
          }
        }
        );
      }
      else if (!this.editPoint && this.transferee.programDetails.programBudget.totalAmount !== +this.amount && +this.amount < +this.transferee.programDetails.programBudget.totalAmount) {
        let payload: object = { amount: this.amount, currency: this.transferee.programDetails.programBudget.currency }
        if (this.isGOUser) {
          payload = { amount: this.amount, currency: this.transferee.programDetails.programBudget.currency, manualOverride: true }
        }
        this.candidateProfileService.updateAmount(this.orderRequestId, payload, params).subscribe(res => {
          this.transferee.programDetails.programBudget.totalAmount = this.amount
          if (this.isGOUser) {
            this.isRecalcPointsBtnDisabled = true;
          }
        }
        );
      }
    } else {
      this.amount = this.transferee.programDetails.programBudget.totalAmount;
    }
  }
  updateTotalRelocatingMembers(event) {
    this.transferee.totalNumberOfRelocatingMembers = this.totalFamilyMembersRelo = event + 1;
  }

  editcontractNameDetails() {
    this.currencies.markAllAsTouched();
    this.editContractName = !this.editContractName;
    this.editCurrencyName = !this.editCurrencyName;
    this.editMoveType = !this.editMoveType;
    this.transferee.authorizationDetails.contractDetails.editContractName = this.editContractName ;
    this.transferee.authorizationDetails.currency.editCurrencyName = this.editCurrencyName;
    let orignalContractValue = `${this.transferee.authorizationDetails.contractDetails.baseContract}${this.transferee.authorizationDetails.contractDetails.contractId}${this.transferee.authorizationDetails.contractDetails.contractName}`;
    let contractValue = `${this.contractDetails.value.baseContract}${this.contractDetails.value.contractId}${this.contractDetails.value.contractName}`;
    let orignalCurrencyValue = `${this.transferee.authorizationDetails.currency.code}${this.transferee.authorizationDetails.currency.description}`;
    let currencyValue = `${this.currencies.value.code}${this.currencies.value.description}`;
    let orignalMoveValue = this.transferee.moveType ? this.transferee.moveType :null;
    let moveValue = this.moveType && this.moveType.value ? this.moveType.value :null;
    let values = {
      contract: {
        baseContract: this.contractDetails.value.baseContract.toString(),
        contractName: this.contractDetails.value.contractName
      },
      currency: {
        code: this.currencies.value.code,
        description: this.currencies.value.description
      }
    }
    if(this.contractDetails.value.contractId) {
      values.contract['contractId'] = this.contractDetails.value.contractId
    }
    if (this.moveType.value || this.moveType.value == '') {
      values['moveType'] = this.moveType.value;
    }
    if (this.currencies.valid && (orignalContractValue != contractValue || orignalCurrencyValue != currencyValue || orignalMoveValue != moveValue)) {
      this.candidateProfileService.updateContractDetails(this.orderRequestId,
        values
      ).subscribe((res) => {
        if (res) {
          this.transferee.authorizationDetails.currency.code = this.currencies.value.code;
          this.transferee.authorizationDetails.currency.description = this.currencies.value.description;
          this.transferee.authorizationDetails.contractDetails.contractName = this.contractDetails.value.contractName;
          this.transferee.authorizationDetails.contractDetails.baseContract = parseInt(this.contractDetails.value.baseContract);
          if (this.transferee.authorizationDetails.contractDetails.contractId && this.contractDetails.value.contractId) {
            this.transferee.authorizationDetails.contractDetails.contractId = this.contractDetails.value.contractId
          } else if (this.transferee.authorizationDetails.contractDetails.contractId && !this.contractDetails.value.contractId) {
            delete this.transferee.authorizationDetails.contractDetails.contractId;
          } else if (!this.transferee.authorizationDetails.contractDetails.contractId && this.contractDetails.value.contractId) {
            this.transferee.authorizationDetails.contractDetails.contractId = this.contractDetails.value.contractId;
          }
          this.transferee.moveType = this.moveType.value
          this.editContractName = false;
          this.editCurrencyName = false;
          this.editMoveType = false;
          this.transferee.authorizationDetails.contractDetails.editContractName = false;
          this.transferee.authorizationDetails.currency.editCurrencyName = false;
          //this.getProgram(this.transferee.divisionPartyId);
        } else {
          this.contractDetails.setValue(this.transferee.authorizationDetails.contractDetails);
          this.currencies.setValue(this.transferee.authorizationDetails.currency);
          this.moveType.setValue(this.transferee.moveType);
          this.editContractName = false;
          this.editCurrencyName = false;
          this.editMoveType = false;
          this.transferee.authorizationDetails.contractDetails.editContractName = false;
          this.transferee.authorizationDetails.currency.editCurrencyName = false;
          this.toastr.error('Error updating Base Contracts');
        }
      })

    }
  }
  editProgramDetails() {

    this.editProgramName = true;
    this.transferee.programDetails.editProgram = true;
    if (this.transferee.programDetails.editProgram && this.transferee.programDetails.programName !== this.programName.value) {
      this.programValues.filter(e => {
        if (e.programName === this.programName.value) {
          this.programContract = e;
        }
      });
      let values = this.programContract.contractId.findIndex(ele => ele === this.transferee.authorizationDetails.contractDetails.baseContract)
      if (values > -1) {
        this.contractAvailable = true;
      } else {
        this.contractAvailable = false;
      }

      if (this.contractAvailable) {
        this.candidateProfileService.updateProgramDetails(this.orderRequestId, {
          programName: this.programName.value,
          clientId: sessionStorage.getItem('clientId')
        }).subscribe((res) => {
          if (res.code && res.code === 'PROGRAM_NAME_UPDATE_SUCCESS') {
            this.transferee.programDetails.programName = this.programName.value;
            this.benefitsList = this.groupBenefits([]);
            this.confirmedPoints = 0;
            this.editProgramName = false;
            this.transferee.programDetails.editProgram = false;
          } else {
            this.programName.setValue(this.transferee.programDetails.programName);
            this.toastr.error('Error updating program name');
          }
        });
      } else {
              this.programName.setValue(this.transferee.programDetails.programName);
              this.editProgramName = false;
              this.transferee.programDetails.editProgram = false;
              this.contractERR = 'The Base Contract assigned to the transferee does not matches with the new program';
      }
    }
  }

  updatePoints(e) {
    const program: any = this.programValues.filter((item) => item.programName === this.programName.value);
    this.transferee.programDetails.points = program[0].points;
    this.points = program[0].points;
  }

  setJobStartDate(value) {
    this.DefaultjobStartDate = moment(value.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  jobStartDateChange(val, e) {
    const existingJobStartDate = this.DefaultjobStartDate;
    this.DefaultjobStartDate = moment(e.target.value).format('MM/DD/YYYY');
    this.candidateProfileService.updateJobStartDate(this.orderRequestId, { jobStartDate: `${moment(e.target.value).format().split('T')[0]}T00:00:00.000Z` }).subscribe(
      (res) => {
        if (res.code && res.code === 'UPDATE_SUCCESS') {
          this.setJobStartDate(e.target.value);
        } else {
          this.DefaultjobStartDate = existingJobStartDate;
          this.toastr.error('Error updating Job start Date');
        }
      });
  }

  estimatedArrivalDateChange(e){
    const existingEstimatedArrivaltDate = this.defaultEstimatedArrivalDate;
    this.defaultEstimatedArrivalDate = moment(e.target.value).format('MM/DD/YYYY');
    this.candidateProfileService.updateEstimatedArrivalDate(this.orderRequestId, { estimatedArrivalDate: `${moment(e.target.value).format().split('T')[0]}T00:00:00.000Z` }).subscribe(
      (res) => {
        if (!res.code || res.code !== 'UPDATE_SUCCESS') {
          this.defaultEstimatedArrivalDate = existingEstimatedArrivaltDate;
          this.toastr.error('Error updating estimated arrival Date');
        }
    });
  }

  setEstimateArrivalDate(value) {
    this.defaultEstimatedArrivalDate = moment(value.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  /**
   * Invoked on click of dismiss/close
   */
  unconfirmBenefitDialog() {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(TWarningDialogComponent, {
      id: 'dialog2',
      width: 'auto',
      data: {
        message: 'Are you sure you want to Unconfirm all the benefits?'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unconfirmBenefits();
      }
    });

  }


  /**
   * To unconfirmed selected benefit
   * @param benefit benefit to be unconfirmed
   */
  unconfirmSelectedBenefit(benefit: Benefit) {
    const dialogRef = this.dialog.open(UnconfirmBenefitsModalComponent, {
      id: 'dialog-unconfirm',
      width: 'auto',
      data: benefit,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.action && result.action === 'delete') {
        this.unconfirmBenefitById(benefit.productId);
      }
    });
  }

  openHistory() {
    this.spinner.show();
    this.candidateProfileService.getPointsRecalculationHistory(this.orderRequestId).subscribe((res) => {
      this.spinner.hide();
      let pointsData = res;
      const dialogRef = this.dialog.open(PointsRecalculationHistoryModalComponent, {
        id: 'dialog-unconfirm',
        width: '60vw',
        data: pointsData
      });
    })
  }
  openWarningOrAddModal() {
    this.spinner.show();
    this.candidateProfileService.getListofPointRecalculation(this.orderRequestId).subscribe((res) => {
      this.datas = res;
      if (this.datas.code) {
        this.toastr.error(this.datas.message, null, {
          closeButton: false,
          enableHtml: true,
          disableTimeOut: false
        });
      } else {

        //const message = 'There was NO data to show';

        const dialogRef = this.dialog.open(PointRecalculationModalComponent, {
          id: 'dialog-unconfirm',
          width: '50vw',
          data: { pointsValue: this.datas, orderID: this.orderRequestId }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data) {
            this.transferee.programDetails.points = data.pointsValue.totalPoints;
            this.getTransfereeMoveDetails(this.candidatePartyId);
          }
        });
      }
    })



  }
  /**
   * To unconfirm benefit by id
   * @param productId benefit product Id
   */
  unconfirmBenefitById(productId: string) {
    this.spinner.show();
    this.candidateProfileService.unconfirmSelectedBenefit(this.orderRequestId, productId).subscribe(res => {
      if (res) {
        this.benefits = [];
        this.benefitsList.map(benefitGroup => {
          benefitGroup.items = benefitGroup.items.filter(benefit => !res.unconfirmedBenefits.includes(benefit.reference));
          return benefitGroup;
        });
        // Update Points.
        if (!this.confirmedPoints > res.updatedConfirmedPoints) {
          if (this.transferee.moveStatus === 'Benefits Confirmed') {
            this.transferee.moveStatus = 'In Progress';
          }
        }
        // Update amount.
        if (!this.confirmedAmount > res.updatedConfirmedAmount) {
          if (this.transferee.moveStatus === 'Benefits Confirmed') {
            this.transferee.moveStatus = 'In Progress';
          }
        }
        // benefitsHVG list updating after unconfirming benefit
        this.benefitsHVG = [...this.benefitsHVG.filter((ele) => {
          return ele.productId !== productId
        })];
        this.isInProgress = this.transferee.moveStatus === 'In Progress'
          && !(this.benefitsList && this.benefitsList.length > 0) ? true : false;
        this.confirmedPoints = res.updatedConfirmedPoints;
        this.confirmedAmount = res.updatedConfirmedAmount;
        if(this.isMMUser) {
          this.getTransfereeDetail(this.orderRequestId);
        } else {
          this.spinner.hide();
        }
        this.toastr.info('Benefit has been unconfirmed successfully', null, {
          timeOut: 4000
        });
      } else {
        this.spinner.hide();
        this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
          timeOut: 4000
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
        timeOut: 4000
      });
    });
  }

  unconfirmBenefits() {
    this.spinner.show();
    this.candidateProfileService.unconfirmBenefits(this.orderRequestId).subscribe(res => {
      if (res) {
        this.getTransfereeDetail(this.orderRequestId, 'unconfirm');
        this.benefits = [];
        //this.benefitsList = this.groupBenefits([]);
        this.setHvgBenefits(this.benefits);
        //this.spinner.hide();
        this.canUnconfirmBenefit = false;
        /*this.toastr.info('Benefits has been unconfirmed successfully', null, {
          timeOut: 4000
        });*/

        // Update Points.
        this.confirmedPoints = 0;
        //update amount
        this.confirmedAmount = 0;
        // this.isInProgress = true;
      } else {
        this.spinner.hide();
        this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
          timeOut: 4000
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
        timeOut: 4000
      });
    });
  }

  unlockSubmittedHighValueGoodsPopup(item: any) {
    if (this.userRole === 'consultant') {
      let dialogRef = this.dialog.open(UnlockSubmittedHighValueGoodsModalComponent, {
        data: {
          benefit: item
        },
        panelClass: 'DataviewSelectionModal',
        autoFocus: false,
        restoreFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.code === 'HVG_UNLOCK_UPDATE_SUCCESS') {
          const resHVGBenifit = this.benefitsHVG.find(benefit => benefit.productId === item.productId);
          resHVGBenifit.hvgDetail.hasOwnProperty('allHighValueTaskOptOut') ? resHVGBenifit.hvgDetail.allHighValueTaskOptOut = false : null;
          resHVGBenifit.hvgDetail.hasOwnProperty('allHighValueTaskComplete') ? resHVGBenifit.hvgDetail.allHighValueTaskComplete = false : null;
          !resHVGBenifit.hvgDetail.hasOwnProperty('highValueItems') ? resHVGBenifit.hvgDetail['highValueItems'] = [] : null;
          this.setTotalReplacementValue(resHVGBenifit);
          this.setHvgBenefits(this.benefits);
        }
      });
    }
  }
  openSchedulePolicyCallModal(isSchedule:boolean = false) {
    const dialogRef = this.dialog.open(ScheduledPolicyCallModalComponent, {
      data: {
        timeZone: this.timeZone,
        policyCallDate: this.policyCallDate,
        policyCallTime: this.policyCallTime,
        orderId: this.orderRequestId,
        partyid: this.candidateDetails.candidateId,
        smart_invite_id: this.smart_invite_id,
        candidateDetails: this.candidateDetails,
        consultantinfo: this.consultantinfo,
        policyCallDuration :this.policyCallDuration,
        isSchedule: isSchedule
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.policyCallDate = moment(result.start).format('YYYY-MM-DD');
        this.policyCallTime = new Date(result.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        this.displayPolicyCallTime = moment(result.start).format('hh:mm A');
        this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
          this.transferee = res.candidateDetails;
          this.candidateDetails = res.candidateDetails;
          this.consultantinfo = res.consultantinfo;
          this.consultantinfo.hasOwnProperty('timeZone') ? this.timeZone = this.consultantinfo.timeZone : null;
          this.consultantinfo.hasOwnProperty('smart_invite_id') ? this.smart_invite_id = this.consultantinfo.smart_invite_id : null;
          const benefits = res.benifits;
          res.confirmedPoints ? this.confirmedPoints = res.confirmedPoints : null;
          this.benefitsList = this.groupBenefits(benefits);
          this.spinner.hide();
        });
      }
    });
  }
  
  openCompletePolicyCallModal() {
    const dialogRef = this.dialog.open(CompletePolicyCallModalComponent, {
      data: {
        policyCallCompleteDate: this.policyCallCompleteDate,
        policyCallCompleteTime: this.policyCallCompleteTime,
        orderId: this.orderRequestId,
        partyid: this.candidateDetails.candidateId,
        candidateDetails: this.candidateDetails,
        consultantinfo: this.consultantinfo
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // let selectedDate = new Date(result.start).toISOString().split('Z')[0];
        this.getCompletedPolicyCallDetails(result.start);
        this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
          this.transferee = res.candidateDetails;
          this.consultantinfo = res.consultantinfo;
          let benefits;
          benefits = res.benifits;
          if (res.candidateDetails && res.candidateDetails.programDetails && res.candidateDetails.programDetails.manualOverride) {
            this.isRecalcPointsBtnDisabled = true;
          }
          res.confirmedPoints ? this.confirmedPoints = res.confirmedPoints : null;
          if(this.benefitsActualsInfo && this.benefitsActualsInfo.hasOwnProperty('benefitsBreakDowns') &&
            this.benefitsActualsInfo.benefitsBreakDowns.length > 0) {
              benefits = this.mergeActualExpenses(benefits);
            } else {
              benefits = res.benifits;
            }
          this.benefitsList = this.groupBenefits(benefits);
          this.spinner.hide();
        });
      }
    });
  }

  /**Transaction */
  viewTransaction() {
    this.isVisible = false;
    this.isTransactionShow = true;
  }
  viewProviders() {
    this.isVisible = false;
    this.isProvider = true;
  }

  leftArrowClick() {
    this.isVisible = true;
    this.isTransactionShow = false;
    this.isProvider = false;

  }

  getTransactionAmt(transactionAmt) {
    this.transactionAmount = transactionAmt;
  }

  getTransactionCurrency(transactionCur) {
    this.transactionCurrency = transactionCur;
  }

  displayTransactionAmtValue(value) {
    return `${formatNumber(Math.ceil(value), 'en-US')}`;
  }

  /* get Move Phase and Move Risk Data */
  getmovePhaseAndRiskData(filenumber, orderRequestId: string) {
    this.movePhaseService.getSelectedMovePhaseDetails(filenumber, orderRequestId)
      .subscribe(
        data => {
          if (data) {
            this.movePhaseAndRiskData = data;
          }
        })
  }

  workDetailPoup(modificationClicked,id, index,checkStatus, woi?:any, supplierOrderRequest?:any,refernce?:any,modification?:any) {
    let benefitData;
    let suppRefernce;
    let suppData;
    benefitData = this.accordinData[index];
    if(supplierOrderRequest != 'undefined') {
      this.orderStatus = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd.find(order => order.supplierRequestId === id || order.fromRequest === id).status;
    }
    if(supplierOrderRequest != 'undefined'&&!supplierOrderRequest && refernce != null ){
      suppRefernce = refernce ;
    }
    suppData = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd.find(orderId => orderId.supplierRequestId == id || orderId.fromRequest === id).modifications;
    this.candidateProfileService.getWorkorderDetails(id,supplierOrderRequest)
      .subscribe(
        data => {
          if (data) {
            this.candidateProfileService.getCurrencies().subscribe(res => {
              let curencieRes = res.values
              if (res.values.length > 0) {
                this.workOrderDetails = data;
                this.spinner.hide();
                let dataObj = {
                  details: this.workOrderDetails,
                    orderID: id,
                    offering: benefitData.displayName,
                    curencieRes: curencieRes,
                    clientDetails: this.clientDetails,
                    transfereeDetails: this.transferee,
                    orderStatusDate: data.orderStatusDate,
                    checkForStatus:checkStatus,
                    checkHybridTempliving:benefitData.hybridTempLiving,
                    reimbursement: benefitData.reimbursement,
                    supplierData:suppData,
                    rangeIncrementUnit: benefitData.rangeIncrementUnit,
                    incrementalCurrencyUnit: benefitData.incrementalCurrencyUnit,
                    chckForModification : !supplierOrderRequest,
                    modificationClicked : modificationClicked
                }
                checkStatus =='addOnCancelled' ? dataObj['orderStatus']= 'Open' : dataObj['orderStatus']= this.orderStatus  ;
                suppRefernce? dataObj['supplierRef']=suppRefernce : null;
                modification? dataObj['modification']=modification : null;
                const dialogRef = this.dialog.open(WorkDetailPopupComponent, {
                  width: '60vw',
                  data: dataObj,
                  disableClose: true
                })
                dialogRef.afterClosed().subscribe(data => {
                  if ( data.data && (data.data.status == 'status' || data.data.status== 'null' || data.data =='null' || data.data == 'status' )) {
                    if(this.accordinData[index].supplierInfo.supplierOrderRequests) {
                      this.accordinData[index].supplierInfo.supplierOrderRequests[woi].status =  data.data.suppOrderStatus;
                    }
                     this.accordinData = [];
                    this.getTransfereeDetail(this.orderRequestId);
                  }
                });
              }
            });
          }
        }
      )
  }


  bookingDetailPopup(id, index, woi, reference?:any){
    let dispName;
    dispName = this.accordinData[index].displayName;
    this.candidateProfileService.getBookingDetails(id).subscribe(data=>{

      const dialogRef = this.dialog.open(BookingDetailsPopupComponent, {
        width: '60vw',
        data: {
              supID: reference,
              bookingDetail:data,
              id:id,
              clientDetails: this.clientDetails,
              transfereeDetails: this.transferee,
              offering: dispName,
              statusDate: data.statusDate
        },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(data => {
        if (data.data.status == 'status') {
           this.accordinData[index].supplierInfo.supplierOrders[woi].status =  data.data.suppOrderStatus;
          this.accordinData = [];
          this.getTransfereeDetail(this.orderRequestId);
        }
      });
    })
  }


  add(i,benefit){
    let id;
    const index = benefit.supplierInfo.mergedSupOrdAndOrd.findIndex(ele =>ele.status =='cancelled');
    if(index>=0){
       id = benefit.supplierInfo.mergedSupOrdAndOrd.find(ele =>ele.status =='cancelled').fromRequest || benefit.supplierInfo.mergedSupOrdAndOrd.find(ele =>ele.status =='cancelled').supplierRequestId;
    } else{
      id = benefit.supplierInfo.supplierOrders.find(ele =>ele.status !='cancelled').fromRequest;
    }


     this.workDetailPoup(false,id,i,'addOnCancelled',0,true)
  }

  /**set ssoIdentifier */
  setSSOIdentifier() {
    this.setSSOValidator();
    !this.isSSOIdentifierEdit ? this.ssoIdentifier.markAsTouched() : null;
    if (!this.ssoIdentifier.invalid) {
      this.isSSOIdentifierEdit = !this.isSSOIdentifierEdit;
    }
    if (!this.isSSOIdentifierEdit && !this.ssoIdentifier.hasError('pattern')) {
      this.isSSOIdentifierEdit = !this.isSSOIdentifierEdit;
      this.candidateProfileService.updateSSOValue(this.orderRequestId, this.ssoIdentifier.value).subscribe(
        response => {
          const ssoErr = (typeof (response) == 'string' && response.includes("SSO")) ? true : false;
          if (ssoErr) {
            this.showSSOApiErr = response;
            this.ssoIdentifier.setErrors({ 'incorrect': true });
            this.isSSOIdentifierEdit = true;
          }
          else if (!ssoErr && response) {
            this.ssoEditValue = this.ssoIdentifier.value;
            this.isSSOIdentifierEdit = !this.isSSOIdentifierEdit;
            // this.transferee.ssoIdentifier = this.ssoIdentifier.value;
          } else {
            this.ssoIdentifier.setValue(this.ssoEditValue);
            this.isSSOIdentifierEdit = !this.isSSOIdentifierEdit;
          }
        })
    }
  }

  setSSOValidator() {
    this.ssoIdentifier.setValidators(this.ssoRegex ? [Validators.pattern(atob(this.ssoRegex))] : null);
  }


  /**to remove api error --> showSSOApiErr */
  ssoChange(value) {
    if (this.showSSOApiErr) {
      this.showSSOApiErr = null;
    }
  }







  mergeActualExpenses (benefits) {
    const benefitWithActulalBreakdown = [];
     for (let benefit of benefits) {
       if(this.benefitsActualsInfo) {
        for(let benefitBreakDown of this.benefitsActualsInfo.benefitsBreakDowns) {
          if (benefit.reference === benefitBreakDown.reference) {
             benefitBreakDown.hasOwnProperty('hostCurrencyAmt') ? benefit.hostCurrencyAmt = benefitBreakDown.hostCurrencyAmt : benefit.hostCurrencyAmt = 0;
             benefitBreakDown.hasOwnProperty('hostCurrencyCode') ? benefit.hostCurrencyCode = benefitBreakDown.hostCurrencyCode: benefit.hostCurrencyCode = null;
             benefitBreakDown.hasOwnProperty('billingCurrencyCode') ?  benefit.billingCurrencyCode = benefitBreakDown.billingCurrencyCode: benefit.billingCurrencyCode = null;
             benefitBreakDown.hasOwnProperty('billingCurrencyAmt') ?  benefit.billingCurrencyAmt = benefitBreakDown.billingCurrencyAmt: benefit.billingCurrencyAmt = 0;

             this.actualHostCurrencySpend += this.roundToTwoDecimal(benefitBreakDown.hostCurrencyAmt);
          }
        }
        benefitWithActulalBreakdown.push(benefit);
       }
     }

     return benefitWithActulalBreakdown;
  }

  isDeleteEnable(benefit){
    let disableDelete = true
    benefit.supplierInfo && benefit.supplierInfo.mergedSupOrdAndOrd ? benefit.supplierInfo.mergedSupOrdAndOrd.forEach(element => {
      if(element.status !== 'cancelled') {
        disableDelete = false;
        return false;
      }
    }) : null;
    return disableDelete;
  }
  calculateActualExpenses() {
    this.actualHostCurrencyRemaining = this.roundToTwoDecimal(this.benefitsActualsInfo.totalsInfo.hostCurrencyAmt) - this.actualHostCurrencySpend;
    this.actualHostCurrencyCode = this.benefitsActualsInfo.totalsInfo.hostCurrencyCode;
  }

  deleteWorkorder(id, index, woi,supplierOrderRequest) {
    const dialogRef = this.dialog.open(DWarningDialogComponent, {
      id: 'dialog3',
      width: 'auto',
      autoFocus: false,
      data: {
        message: workOrderDelWarningMsg
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 'delete') {
        dialogRef.close();
        this.accordinData = [];
        this.candidateProfileService.deleteWorkOrder(id, supplierOrderRequest).subscribe(data => {
          this.getTransfereeDetail(this.orderRequestId);
        });
      }
    });

  }



  checkForDisable(index) {
    const firstIndex = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].status ==='cancelled';
    const firstIndexInProgress = ['in-progress','paused','pending', 'open'].includes(this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].status);
    const modifactionstatusCheck = this.checkForModificationCancelStatus(index);
    if(firstIndex ) return false; // first index not cancell status
    // if(!firstIndexInProgress && modifactionstatusCheck) return false; // first index not cancell status but modification contains reject status
    if(firstIndexInProgress){
      if(modifactionstatusCheck){
        return false;
      }
      return true;
    }
    return true;

  }

  roundToTwoDecimal(num) {
    let result = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(result) / 100 * Math.sign(num);
  }

  checkForModificationCancelStatus(index) {
    if (this.accordinData[index].supplierInfo.mergedSupOrdAndOrd && this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0]) {
      if (this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].modifications && this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].modifications.length) {
          const rejectModIndex = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].modifications.findIndex(mod => mod.status === "Rejected");
          if (rejectModIndex > -1) {
            return true;
          }
        }
    }
    return false;
  }


  deleteTransfereeContext() {
    const name = 'transferee-context';
    const path ="/";
    let domanin: string;
    document.location.hostname === 'localhost' ? domanin = 'localhost' : domanin = '.cartus.com';
    this.cookieService.delete(name,path,domanin);
  }

  checkForModificationStatus(element) {
    let flag = false;
    if(element.modification > 0 && element.modifications.length) {
      element.modifications.forEach(mod => {
        if(!mod.hasOwnProperty('status') || (mod.hasOwnProperty('status') && (mod.status !== "Accepted" && mod.status !== "Rejected") )) {
          flag = true;
        }
      });
    }
    return flag;
  }

    


  ngOnDestroy(): void {
    this.deleteTransfereeContext();
    this.contractSub ? this.contractSub.unsubscribe() : null;
  }

 

 

  applySearch(searchValue: any) {
     this.docdataSource.filter = searchValue.trim().toLowerCase();
  }

  getCompletedPolicyCallDetails(policyCallDate: any) {
    this.policyCallCompleteDate = moment(policyCallDate).format('YYYY-MM-DD');
    this.policyCallCompleteTime = moment(policyCallDate).format("HH:mm")
    this.displayPolicyCompleteCallTime = moment(policyCallDate).format('hh:mm A');
  }
  
  showPolicyHistory(status) {
    const dialogRef = this.dialog.open(PolicyHistoryComponent, {
      panelClass: 'makeItMiddle',
      width: "30vw",
      data: {
        currOrderRequestId: this.currOrderRequestId,
        status: status
      }
    });

  }
  restoreTransferee() {
    const dialogRef = this.dialog.open(RestoreTransfereeConfirmationModalComponent, {
      data: {},
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
      width: '30%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action && result.action === 'restore') {
        this.candidateProfileService.activateTransferee(this.orderRequestId).subscribe((response) => {
          if(response && response.code === 'ACTIVATE_SUCCESS') {
            this.toastr.info('Transferee activated successfully', null, {
              timeOut: 4000
            });
            this.closeTransferee();
          }
        });
      }
    });
  }

  documentLibSecExpanded() {
    if(!this.isDocumentLibSecExpanded && (this.dmsCapability === 'write' || this.dmsCapability === 'read')) {
      this.isDocumentLibSecExpanded = true;
    }
  }

  openTransfereeInfoModal() {
    const dialogRef = this.dialog.open(TransfereeAccopanyingMembersModalComponent, {
      data: {
        transfereeDetails: this.transferee,
        orderRequestId: this.orderRequestId
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  markAuditCallComplete() {
    this.candidateProfileService.markAuditCallComplete(this.orderRequestId).subscribe((res) => {
      if (res) {
        if (res.code && res.code == 'AUDIT_STATUS_UPDATE_SUCCESS') {
          if(!this.transferee.programDetails?.initialContactRequired){
            this.sendInviteAfterAuditComplete();   
          }else{
            this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
              this.transferee = res.candidateDetails;
              this.consultantinfo = res.consultantinfo;
              this.moveStatus = this.transferee.moveStatus;
              this.spinner.hide();
            });   
          }
        } else if (res.message) {
          this.toastr.info(res.message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
      }
    });

  }

  sendInviteAfterAuditComplete(){
      const dialogRef = this.dialog.open(SendinviteInitialcontactCallModalComponent, {
        data: {
          transfereeName: this.transferee.nameDetails ? this.transferee.nameDetails.firstName + ' ' + this.transferee.nameDetails.lastName : '',
          displayHeaderLable : 'Audit Complete'
        },
        panelClass: 'makeItMiddle',
        autoFocus: false,
        restoreFocus: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
          this.transferee = res.candidateDetails;
          this.consultantinfo = res.consultantinfo;
          this.spinner.hide();
        });
        if (result && result.action && result.action === 'sendInvite') {
          this.sendInitialCallInvite();
        }
      });
    
  }

  isOverrideChecked() {
    this.isOverrideClicked = ! this.isOverrideClicked;
    if(!this.isOverrideClicked){
      this.eligibilityDate.setValue(convertDateToUTC(this.transferee.paymentEligibilityInfo.defaultPaymentEligibilityDate));
      this.eligibilityDateChange();
    }else{
      this.eligibilityDate.setValue(convertDateToUTC(this.transferee.paymentEligibilityInfo.paymentEligibilityDate));
    }
  }

  eligibilityDateChange(){
    const reqPayload = {
      paymentEligibilityDate: `${moment(this.eligibilityDate.value).format('YYYY-MM-DD')}T00:00:00.000Z`,
      isOverRide: this.isOverrideClicked
    };
    this.candidateProfileService.changeEligibilityDate(this.orderRequestId,reqPayload).subscribe((res) => {
      if (res) {
        if (res.code && res.code == 'UPDATE_SUCCESS') {
            this.candidateProfileService.getTransferee(this.orderRequestId).subscribe((res) => {
              this.transferee = res.candidateDetails;
              this.consultantinfo = res.consultantinfo;
              this.setEligibilityData(this.transferee)
              this.spinner.hide();
            });   
        } else if (res.message) {
          this.toastr.info(res.message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
      }
    });

  }

  setEligibilityData(data){
    if(data.paymentEligibilityInfo?.hasOwnProperty("paymentEligibilityDateOverRide")){
      if(data.paymentEligibilityInfo?.paymentEligibilityDateOverRide){
        this.eligibilityDate.setValue(convertDateToUTC(data.paymentEligibilityInfo.paymentEligibilityDate))
      }else if(!data.paymentEligibilityInfo?.paymentEligibilityDateOverRide && data.paymentEligibilityInfo.defaultPaymentEligibilityDate){
        this.eligibilityDate.setValue(convertDateToUTC(data.paymentEligibilityInfo.defaultPaymentEligibilityDate));
      }
      if(data.paymentEligibilityInfo?.timeSelection === "As Of"){
        this.paymentEligibilityText = "Payment will be reimbursed " + data.paymentEligibilityInfo?.timeSelection + " " + data.paymentEligibilityInfo?.event
      }else{
        this.paymentEligibilityText = "Payment will be reimbursed " + data.paymentEligibilityInfo?.noOfDays + " days "+ data.paymentEligibilityInfo?.timeSelection + " " + data.paymentEligibilityInfo?.event
      }
      this.isOverrideClicked = data.paymentEligibilityInfo.paymentEligibilityDateOverRide;
      this.isPaymentEligibilityOverride.setValue(data.paymentEligibilityInfo.paymentEligibilityDateOverRide);
    }
  }

 
  saveLumpSumAmountDetails(canMarkAuditComplete:boolean = false){
    const reqPayload = {
      amount: Number(this.lumpSumForm.get('amount').value),
      currency: this.lumpSumForm.get('currency').value,
      cartusCalculateSource: this.lumpSumForm.get('source').value  
    }

    if(!this.transferee.programDetails.cartusCalculate){
      delete reqPayload.cartusCalculateSource
    }

    if(this.transferee.programDetails.amount !== Number(this.lumpSumForm.get('amount').value) ||
      this.transferee.programDetails.currency!==this.lumpSumForm.get('currency').value || 
      (this.transferee.programDetails.cartusCalculateSource && this.transferee.programDetails.cartusCalculateSource !==  this.lumpSumForm.get('source').value)){
        this.candidateProfileService.updateLumpSumAmountData(this.orderRequestId,reqPayload).subscribe((res) => {
          if (res) {
            if (res.code && res.code == 'UPDATE_SUCCESS') {
                  this.transferee.programDetails.amount = Number(this.lumpSumForm.get('amount').value);
                  this.transferee.programDetails.currency =this.lumpSumForm.get('currency').value;
                  this.transferee.programDetails.cartusCalculateSource ? this.transferee.programDetails.cartusCalculateSource = this.lumpSumForm.get('source').value:1;
                  this.spinner.hide();
                  if(canMarkAuditComplete) {
                    this.markAuditCallComplete();
                  }
                  
            } else if (res.message) {
              this.toastr.info(res.message, null, {
                closeButton: true,
                enableHtml: true,
                disableTimeOut: false
              });
              this.lumpSumForm.controls['amount'].setValue(this.transferee.programDetails.amount)
              this.lumpSumForm.controls['currency'].setValue(this.transferee.programDetails.currency)
              this.lumpSumForm.controls['cartusCalculateSource'].setValue(this.transferee.programDetails.cartusCalculateSource?this.transferee.programDetails.cartusCalculateSource:null)
            }
          }
        });
    }
    
  }

  isPaymentEligibilityDisabled(){
    
    if( this.overridePaymentEligibilityCapability === 'write' &&  !this.lumpSumAmountRequested && ['In Progress', 'Invitation Sent'].includes(this.moveStatus)){
      return false
    }

    return true ;

  }

  
  isEditLumpSumAmountEnabled() {
    if (this.lumpSumAmountCapability === 'write' && this.moveStatus === 'Audit Pending') {
        return false;
    }

    if (this.lumpSumAmountOverrideCapability === 'write' && this.moveStatus !== 'Audit Pending' && !this.lumpSumAmountRequested) {
        return false;
    }

    return true;
  }

  openLumpSumAmountOverrideAlertDialog() {
    const originalAmount = `${this.transferee.programDetails.amount} ${this.transferee.programDetails.currency || ''}`;
    const newAmount = `${this.lumpSumForm.get('amount').value} ${this.lumpSumForm.get('currency').value}`;
    const displayMessage = `LumpSum amount is overridden from ${originalAmount} to ${newAmount}. Do you want to proceed?`;

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: "30vw",
      data: {
        displayMessage: displayMessage,
        showConfirmBtn: true,
      },
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.action === 'confirm') {
        this.saveLumpSumAmountDetails(true);
      }
      if (result?.action === 'dismiss') {
        this.lumpSumForm.controls['amount'].setValue(this.transferee.programDetails.amount)
        this.lumpSumForm.controls['currency'].setValue(this.transferee.programDetails.currency)
      }
    });
  }
  
  toggleDocLibraySection() {
    this.isDocLibraryUIExpanded = !this.isDocLibraryUIExpanded;
    return this.isDocLibraryUIExpanded;
  }

  setContractData(){
    let currentOrderContractIndex = this.moveDetailList.findIndex(x=>x.orderRequestId===this.orderRequestId);
    if(currentOrderContractIndex > -1){
      let contractDetailsData = this.moveDetailList[currentOrderContractIndex].authorizationDetails.contractDetails;
      this.contractDetails.setValue(contractDetailsData)
      this.currencies.setValue(this.moveDetailList[currentOrderContractIndex].authorizationDetails.currency)
      this.geoOrigin.setValue(this.moveDetailList[currentOrderContractIndex].contractDetails && this.moveDetailList[currentOrderContractIndex].contractDetails.hasOwnProperty('geoOrigin') ? this.moveDetailList[currentOrderContractIndex].contractDetails.geoOrigin : null)
      this.moveType.setValue(this.moveDetailList[currentOrderContractIndex].moveType);
    }

  }

  isMoveStatusValid(status: string): boolean {
    const validStatuses = [
      'Invitation Sent',
      'In Progress',
      'Initial Contact Pending',
      'Initial Contact Complete',
      'Benefits Confirmed',
      'Payment Requested',
      'Complete'
    ];
    return validStatuses.includes(status);
  }

  getRepayId(event) {
    this.repayDocId = event;
  }

  setLumpSumDates(){
    if(this.candidateDetails.repaymentAgreementAccepted && this.candidateDetails.repaymentAgreementAcceptedDate){
      this.repaymentAgreementAcceptedDate.setValue(this.candidateDetails.repaymentAgreementAcceptedDate)
    }else{
      this.repaymentAgreementAcceptedDate.setValue('NA')
    }

    if(this.candidateDetails.lumpSumAmountRequested && this.candidateDetails.lumpSumAmountRequestedDate){
      this.lumpSumAmountRequestedDate.setValue(this.candidateDetails.lumpSumAmountRequestedDate)
    }else{
      this.lumpSumAmountRequestedDate.setValue('NA')
    }

  }

  getPolicyData(){
    let clientId;
    if (this.userRole === 'client-contact-initiator') {
      clientId = sessionStorage.getItem("cc_clientPartyId");
    } else {
      clientId = this.clientDetails && this.clientDetails.clientId;
    }

    this.programManagementService.getClientPolicies(clientId).subscribe( res => {
      this.policyValues = res.policies;
      this.policyValueChange();
    })
  }

  policyValueChange() {
    this.filteredPolicyValues = this.assignedPolicy.valueChanges
      .pipe(
        startWith(''),
        map(value => this.policyfilter(value))
      );
  }

  private policyfilter(value): any[] {
    if (value) {
    const filterValue = value.toLowerCase();
    const policyList = this.policyValues.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    return policyList;
  } else {
      return this.policyValues;
    }
  }

  get isPolicyFieldReadOnly(): boolean {
    return (
      (this.policyAssignedCapability && this.policyAssignedCapability === 'read') || this.transferee.authorizationType !== "Traditional" || this.isLumpSum ||
      ![
        'Invitation Sent',
        'Initial Contact Pending',
        'Initial Contact Complete',
        'In Progress',
        'Audit Pending',
        'Audit Complete',
      ].includes(this.transferee?.moveStatus) 
    );
  }  

  editAssignedPolicy(){
    this.isPolicyEditEnabled = !this.isPolicyEditEnabled;
    if(this.isPolicyEditEnabled){
      this.assignedPolicy.enable()
    }else{
      this.assignedPolicy.setValue(this.transferee.assignedPolicy)
      this.assignedPolicy.disable()
    }
  }


  policySelection(){
    this.spinner.show()
    this.candidateProfileService.updateAssingedPolicy(this.orderRequestId,this.assignedPolicy.value).subscribe((res) => {
      if (res) {
        if (res.code && res.code == 'UPDATE_SUCCESS') {
          this.isPolicyEditEnabled = false;
          this.transferee.assignedPolicy = this.assignedPolicy.value;
        } else if (res.message) {
          this.toastr.info(res.message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        this.spinner.hide()
      }
    });
  }
  
}




/**
 * Component to display warning dialog box on dismiss
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'warning-dialog',
  styleUrls: ['./transferee-detail.component.scss'],
  template: `
  <style>
      button:focus  { text-decoration: underline; }
      div:focus { outline: none !important }
      button.text-button.rest-Btn {
        margin-right: 20px!important;
    }

  .send-invite{
    background-color:#2065F8;
    font-family: "Avenir-Light", sans-serif;
    color: #ffffff;
    font-size: 1em;
    border-radius: 4px;
    padding: 0.4em;
    border: none;
    min-width: 6.25em;
    line-height: 1.5em;
    }
  .email-forms{
    display:flex;
    justify-content: space-between;
  }
  .delete-icon{
    margin-top: 7px;
    cursor: pointer;
  }
  .email-header{
    display: flex;
    justify-content: space-between;
    }
    .email-label{
      position: fixed;
    }
    .close-button{
      cursor: pointer;
    }
    .send-button{
      margin-left: 24px;
    }
    .usage-type{
      margin-left:24px;
    }
  </style>
  <div mat-dialog-content tabindex = "0" *ngIf="showMail; else message">
 <div class="email-header">
 <div>
   <img src="../../../../../../assets/images/baseline-email-24px.svg" alt="email" />
   <span class="email-label">Email Address</span>
   </div>
   <div class="close-button">
   <mat-icon class="close-icon" tabindex = 0 mat-dialog-close>close</mat-icon>
 </div>
 </div>
 <div fxLayout="row">
 <form [formGroup]="emailForm" fxFlex="75%">
 <div formArrayName="transfreeEmail" *ngFor="let email of emailForm.get('transfreeEmail').controls; let i = index">
 <div [formGroupName]="i" fxLayout="row">
 <mat-form-field fxFlex="65">
 <input type="email" matInput formControlName="emailAddress" placeholder="Email Address" readonly>
 </mat-form-field>
 <mat-form-field fxFlex="30" class="usage-type">
 <input type="email" matInput formControlName="usageType" #type [value]="type.value | titlecase" placeholder="Type" readonly>
  </mat-form-field>
 </div>
 </div>
 </form>
 <div class="send-button">
 <span *ngIf="isBusinessMail; else personalMail">
 <button mat-stroked-button class="send-invite" *ngIf="(resendInviteCapability === 'read' || resendInviteCapability == 'write') && (data?.transfereeDetails?.initialCallDetails?.initialCallStatus==='completed' || data?.moveStatus === 'Invitation Sent' || data?.moveStatus === 'Audit Complete')"
  [disabled]="resendInviteCapability === 'read'" (click)="sendResendInvite()">Send Invite</button>
 </span>
 <ng-template #personalMail>
<button mat-stroked-button class="send-invite" *ngIf="(resendInviteCapability === 'read' || resendInviteCapability == 'write') && (data?.transfereeDetails?.initialCallDetails?.initialCallStatus==='completed' || data?.moveStatus === 'Invitation Sent' || data?.moveStatus === 'Audit Complete')"
  [disabled]="resendInviteCapability === 'read'" (click)="sendResendInvite()">Send Invite</button>
 </ng-template>
 </div>
 </div>
  </div>
  <ng-template #message>
  <div class="warning-popup" mat-dialog-content tabindex = "0"><span aria-hidden = 'true'>{{ data.message }}</span></div>
  <div>
   <button class="edit-ok" mat-button tabindex = 0 (click)='onNoClick()'>Yes</button>
   <button class="edit-cancel" mat-button tabindex = 0 mat-dialog-close>Cancel</button>
 </div>
  </ng-template>`
  //  <style>button:focus  {    text-decoration: underline;}
  //         div:focus { outline: none !important}
  //         .warning-popup{padding: 10px 30px !important;}
  //   </style>
})
export class TWarningDialogComponent {

  constructor(
    public dialog: MatDialogRef<TWarningDialogComponent>,
    public live: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: UntypedFormBuilder,
    private candidateProfileService: CandidateProfileService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }
  public emailForm: UntypedFormGroup;
  public transfreeEmail: UntypedFormArray;
  public showMail = false;
  public isPersonalMail = false;
  public isBusinessMail = false;
  public resendInviteCapability: string;
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.live.announce(this.data.message);
    this.emailForm = this.formBuilder.group({
      transfreeEmail: this.formBuilder.array([])
    });
    //removed programdetails from condition as its not there for Traditional transferee
    if (this.data && this.data.transfereeDetails && this.data.transfereeDetails.emailDetailsList && this.data.isShowpPopup) {
      this.showMail = true;
      this.isBusinessMail = this.data && this.data.transfereeDetails && this.data.transfereeDetails.emailDetailsList && this.data.transfereeDetails.emailDetailsList.some(item => item.usageType === 'business')
      this.data.transfereeDetails.emailDetailsList.map(element => {
        (<UntypedFormArray>this.emailForm.get('transfreeEmail')).push(this.createForm(element));
      });

      this.resendInviteCapability = this.data.resendInviteCapability;
    }
  }

  onNoClick(): void {
    this.dialog.close({
      action: 'dismiss'
    });
  }

  public sendResendInvite() {
    let emailList = [];
    var requestBody;
    this.data && this.data.transfereeDetails && this.data.transfereeDetails.emailDetailsList && this.data.transfereeDetails.emailDetailsList.forEach(element => {
      element.primary = true;
      if ((this.isBusinessMail && element.usageType === 'business')) {
        emailList.push(element);
      }  else {
        let businessEmail = emailList.some(item => item.usageType === 'business');
        if(!businessEmail){
          emailList.push(element);
        }
      }
    });
    if (this.data.moveStatus === 'Invitation Sent') {
      requestBody = {
        orderRequestId: this.data.orderRequestId,
        emailDetailsList: emailList,
        type: this.data.type,
        authorizationType: this.data.authorizationType,
      }
      if (this.data.ssoIdentifier) {
        requestBody["ssoIdentifier"] = this.data.ssoIdentifier;
      }
      this.candidateProfileService.resendSendInvitation(requestBody).subscribe((res) => {
        if (res['message']) {
          let message = 'Invitation Sent';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
          this.dialog.close({
            action: 'dismiss',
            data: 'Resend'
          });
          this.spinner.hide();
        } else {
          this.dialog.close({
            action: 'dismiss',
            data: 'Resend'
          });
          this.toastr.error('Error occured while sending invite');
        }
      });
    } else if (this.data.moveStatus === 'Invitation Not Sent' || 'Initial Contact Complete') {
      let payload = { "orderRequestId": this.data.orderRequestId };
      this.candidateProfileService.sendInvitationInitialCallComplete(payload).subscribe((res) => {
        if (res['message']) {
          let message = 'Invitation Sent';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
          this.dialog.close({
            action: 'dismiss',
            data: 'send'
          });
          this.spinner.hide();
        } else {
          this.dialog.close({
            action: 'dismiss',
            data: 'send'
          });
          this.toastr.error('Error occured while sending invite');
        }
      });
    }
  }

  public createForm(data?) {
    return this.formBuilder.group({
      emailAddress: [data && data.emailAddress, [Validators.email, Validators.required]],
      usageType: [data && data.usageType]
    });
  }
}

/**
 * Component to display warning dialog box on delete workorder
 */
 @Component({
  selector: 'app-warning-dialog',
  template: `<div mat-dialog-content>{{ data.message }}<div>
    <div class="button-wrapper">
     <button mat-button (click)='onDelete()' class="delete-card">Yes</button>
     <button mat-button [mat-dialog-close]='true' cdkFocusInitial class="cancel">No</button>
   </div>`,
})
export class DWarningDialogComponent {
  constructor(
    public dialog: MatDialogRef < DWarningDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onDelete(): void {
    this.dialog.close({
      action: 'delete'
    });
  }
}



